import React from "react";
import PropTypes from "prop-types";
import Icon from "@mdi/react";
import styled from "styled-components";
import { mdiArrowRight } from "@mdi/js";
import { Color } from "constants/colors";

const CityNameText = styled.span`
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
`;

export default function SimpleTripRoute({ departure, arrival }) {
  return (
    <div style={{ display: "flex" }}>
      <CityNameText>
        {departure.substring(0, departure.length - 4)}
      </CityNameText>
      <div style={{ textAlign: "center", margin: "0px 5px" }}>
        <Icon
          path={mdiArrowRight}
          size={0.8}
          color={Color.ANTD_LIGHT_GREY_BORDER}
        />
      </div>
      <CityNameText>{arrival.substring(0, arrival.length - 4)}</CityNameText>
    </div>
  );
}

SimpleTripRoute.propTypes = {
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired
};
