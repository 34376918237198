import React, { Component } from "react";
import PropTypes from "prop-types";
import { MaskedInput as AntdMaskedInput } from "antd-mask-input";

class MaskedInput extends Component {
  maskedInputRef = React.createRef();
  handleValueChange = () => {
    const value = this.maskedInputRef.mask.getRawValue().trim();
    this.triggerChange(value);
  };

  triggerChange = changedValue => {
    const { onChange } = this.props;
    onChange(changedValue);
  };

  render() {
    const {
      value,
      placeholder,
      size,
      prefix,
      mask,
      inputMode,
      disabled,
      onKeyDown
    } = this.props;

    return (
      <>
        <AntdMaskedInput
          {...this.props}
          disabled={disabled}
          onBlur={() => {
            if (this.maskedInputRef) {
              const currentValue = this.maskedInputRef.mask.getRawValue();
              if (currentValue.trim().length === 0) {
                this.maskedInputRef.input.value = "";
              }
            }
          }}
          ref={maskedInput => {
            if (maskedInput) {
              // maskedInput.mask.pattern.isRevealingMask = true;
              maskedInput.input.onkeydown = onKeyDown;
              // maskedInput.input.onkeydown = (event) => {
              //   console.log(event.key);
              // };
              this.maskedInputRef = maskedInput;
            }
          }}
          mask={mask}
          placeholderChar={" "}
          prefix={prefix}
          placeholder={placeholder}
          inputMode={inputMode}
          type={"text"}
          size={size}
          value={value}
          onChange={this.handleValueChange}
        />
      </>
    );
  }
}

MaskedInput.defaultProps = {
  disabled: false,
  placeholder: "",
  size: "default",
  inputMode: "text",
  prefix: null,
  onChange: () => {},
  onKeyDown: () => {}
};

MaskedInput.propTypes = {
  mask: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "default", "large"]),
  prefix: PropTypes.object,
  inputMode: PropTypes.oneOf(["text", "numeric"]),
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func
};

export default MaskedInput;
