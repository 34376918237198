import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Roles } from "constants/roles";

export const withRoles = Component => {
  class HOC extends React.Component {
    render() {
      const { roles } = this.props;
      const isUser = roles.includes(Roles.USER);
      const isSocialLogin =
        roles.includes(Roles.OAUTH2_GOOGLE) ||
        roles.includes(Roles.OAUTH2_FACEBOOK);
      const verifiedEmail = roles.includes(Roles.VERIFIED_EMAIL);
      const verifiedPhone = roles.includes(Roles.VERIFIED_PHONE);

      return (
        <Component
          {...this.props}
          roles={{
            isUser,
            isSocialLogin,
            verifiedEmail,
            verifiedPhone
          }}
        />
      );
    }
  }

  HOC.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  const mapStateToProps = state => ({
    roles: state.userInfoReducer.roles
  });

  return connect(mapStateToProps)(HOC);
};
