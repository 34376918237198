import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import lodash from "lodash";
import { getParamsFromPrevLocation } from "util/routeUtils";
import { Helmet } from "react-helmet";
import { ResponseStatus } from "constants/responseStatus";
import {
  verifyPhone,
  resendVerifyPhone,
  getPublicVariable
} from "queries/queries";
import { Button, Result } from "antd";
import Loading from "components/loading";
import { setToken } from "services/auth";
import MaskedInput from "components/maskedInput";
import { InternalUrl } from "constants/urls";
import StringMask from "string-mask";
import { withRoles } from "../../hocs/withRoles";
import style from "./styles.module.css";
import {
  backendDateTimeStringToMoment,
  currentTimeMoment,
  momentToBackendDateTimeString,
  timeDiff
} from "../../util/formatUtils";
import CountdownText from "../../components/countdownText";
import { getCookie, storeCookie } from "../../util/cookieUtils";
import { CookieKey } from "../../constants/cookieKey";
import classNames from "classnames/bind";
import { OpenVariable } from "../../constants/openVariable";
import RequestStatus, { Status } from "../../model/RequestStatus";
import { PhoneVerificationMethod } from "../../constants/phoneVerificationMethod";

const PHONE_VERIFIED_MESSAGE =
  "Obrigado por verificar seu email e seu celular. Agora você já pode reservar viagens pelo Vai de Van. Boas viagens.";

class VerifyPhone extends Component {
  resendSmsButtonEnabledInterval;
  input1Ref = React.createRef();
  input2Ref = React.createRef();
  input3Ref = React.createRef();
  input4Ref = React.createRef();
  input5Ref = React.createRef();
  input6Ref = React.createRef();
  verifyButtonRef = React.createRef();

  state = {
    initialDataRequestStatus: new RequestStatus(Status.NOT_STARTED),
    responseStatus: ResponseStatus.SUCCESS,
    responseErrorMessage: "",
    fromEmailVerificationPage: false,
    isPhoneVerificationDone: false,
    disableResendSmsButton: false,
    isWhatsAppEnabled: false,
    nextResendCodeAvailableAt: momentToBackendDateTimeString(
      currentTimeMoment()
    ),
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    number5: "",
    number6: ""
  };

  componentDidMount() {
    const {
      roles: { verifiedPhone }
    } = this.props;
    const prevPageState = getParamsFromPrevLocation(this.props);
    const fromEmailVerificationPage = lodash.get(
      prevPageState,
      "fromEmailVerification",
      false
    );

    const storedNextResendCodeAvailableAt = getCookie(
      CookieKey.NEXT_RESEND_SMS_AVAILABLE_DATE_KEY
    );
    if (storedNextResendCodeAvailableAt) {
      this.setState({
        nextResendCodeAvailableAt: storedNextResendCodeAvailableAt
      });
    }

    this.resendSmsButtonEnabledInterval = setInterval(
      this.recalculateSmsEnabledButton,
      100
    );

    this.setState({
      fromEmailVerificationPage: fromEmailVerificationPage,
      isPhoneVerificationDone: verifiedPhone
    });

    this.fetchInitialData();
  }

  componentWillUnmount() {
    clearInterval(this.resendSmsButtonEnabledInterval);
  }

  fetchInitialData = () => {
    this.setState({
      initialDataRequestStatus: new RequestStatus(Status.LOADING)
    });

    getPublicVariable(OpenVariable.WHATSAPP_API_ENABLED)
      .then(response => {
        this.setState({
          isWhatsAppEnabled: response.value
        });
      })
      .catch(error => {
        this.setState({
          isWhatsAppEnabled: false
        });
      })
      .finally(() => {
        this.setState({
          initialDataRequestStatus: new RequestStatus(Status.SUCCESS)
        });

        this.focusFirstInput();
      });
  };

  recalculateSmsEnabledButton = () => {
    const { nextResendCodeAvailableAt } = this.state;

    const disableResendSmsButton =
      timeDiff(
        currentTimeMoment(),
        backendDateTimeStringToMoment(nextResendCodeAvailableAt)
      ) > 0;

    this.setState({
      disableResendSmsButton
    });
  };

  onVerifyPhoneClicked = () => {
    const { rememberMe } = this.props;

    const phoneVerificationCode = this.getTypedCode();

    this.setState({
      responseStatus: ResponseStatus.LOADING
    });

    verifyPhone(phoneVerificationCode)
      .then(token => {
        setToken(token, rememberMe);
        this.setState({
          responseStatus: ResponseStatus.SUCCESS,
          isPhoneVerificationDone: true
        });
      })
      .catch(error => {
        this.setState({
          responseStatus: ResponseStatus.ERROR,
          responseErrorMessage: error.message,
          phoneVerificationCode: ""
        });
      });
  };

  onResendVerificationCodeClicked = method => {
    const nextResendSmsAvailableDate = momentToBackendDateTimeString(
      currentTimeMoment().add(45, "seconds")
    );
    this.setState({
      nextResendCodeAvailableAt: nextResendSmsAvailableDate
    });

    storeCookie(
      CookieKey.NEXT_RESEND_SMS_AVAILABLE_DATE_KEY,
      nextResendSmsAvailableDate,
      { maxAge: 60000 }
    );

    resendVerifyPhone(method)
      .then(() => {
        //TODO
      })
      .catch(() => {
        //TODO
      });
  };

  getTypedCode = () => {
    const { number1, number2, number3, number4, number5, number6 } = this.state;
    return `${number1}${number2}${number3}${number4}${number5}${number6}`;
  };

  clearTypedCode = () => {
    this.setState({
      number1: "",
      number2: "",
      number3: "",
      number4: "",
      number5: "",
      number6: ""
    });
  };

  focusFirstInput = () => {
    setTimeout(() => {
      if (
        typeof this.input1Ref?.current?.maskedInputRef?.input?.focus ===
        "function"
      ) {
        this.input1Ref.current.maskedInputRef.input.focus();
      }
    }, 100);
  };

  renderCodeInputs = () => {
    const { number1, number2, number3, number4, number5, number6 } = this.state;

    return (
      <div style={{ display: "flex" }}>
        <MaskedInput
          ref={this.input1Ref}
          onFocus={() => {
            this.input1Ref.current.maskedInputRef.input.select();
          }}
          mask="1"
          inputMode="numeric"
          size="large"
          value={number1}
          onChange={value => {
            this.setState({
              number1: value
            });
            if (value) {
              this.input2Ref.current.maskedInputRef.input.focus();
            }
          }}
        />
        <MaskedInput
          ref={this.input2Ref}
          onFocus={() => {
            this.input2Ref.current.maskedInputRef.input.select();
          }}
          mask="1"
          inputMode="numeric"
          size="large"
          value={number2}
          onKeyDown={event => {
            if (event.key === "Backspace") {
              this.setState({
                number1: ""
              });
              this.input1Ref.current.maskedInputRef.input.focus();
            }
          }}
          onChange={value => {
            this.setState({
              number2: value
            });
            if (value) {
              this.input3Ref.current.maskedInputRef.input.focus();
            } else {
              this.input1Ref.current.maskedInputRef.input.focus();
            }
          }}
        />
        <MaskedInput
          ref={this.input3Ref}
          onFocus={() => {
            this.input3Ref.current.maskedInputRef.input.select();
          }}
          mask="1"
          inputMode="numeric"
          size="large"
          value={number3}
          onKeyDown={event => {
            if (event.key === "Backspace") {
              this.setState({
                number2: ""
              });
              this.input2Ref.current.maskedInputRef.input.focus();
            }
          }}
          onChange={value => {
            this.setState({
              number3: value
            });
            if (value) {
              this.input4Ref.current.maskedInputRef.input.focus();
            } else {
              this.input2Ref.current.maskedInputRef.input.focus();
            }
          }}
        />
        <MaskedInput
          ref={this.input4Ref}
          onFocus={() => {
            this.input4Ref.current.maskedInputRef.input.select();
          }}
          mask="1"
          inputMode="numeric"
          size="large"
          value={number4}
          onKeyDown={event => {
            if (event.key === "Backspace") {
              this.setState({
                number3: ""
              });
              this.input3Ref.current.maskedInputRef.input.focus();
            }
          }}
          onChange={value => {
            this.setState({
              number4: value
            });
            if (value) {
              this.input5Ref.current.maskedInputRef.input.focus();
            } else {
              this.input3Ref.current.maskedInputRef.input.focus();
            }
          }}
        />
        <MaskedInput
          ref={this.input5Ref}
          onFocus={() => {
            this.input5Ref.current.maskedInputRef.input.select();
          }}
          mask="1"
          inputMode="numeric"
          size="large"
          value={number5}
          onKeyDown={event => {
            if (event.key === "Backspace") {
              this.setState({
                number4: ""
              });
              this.input4Ref.current.maskedInputRef.input.focus();
            }
          }}
          onChange={value => {
            this.setState({
              number5: value
            });
            if (value) {
              this.input6Ref.current.maskedInputRef.input.focus();
            } else {
              this.input4Ref.current.maskedInputRef.input.focus();
            }
          }}
        />
        <MaskedInput
          ref={this.input6Ref}
          onFocus={() => {
            this.input6Ref.current.maskedInputRef.input.select();
          }}
          mask="1"
          inputMode="numeric"
          size="large"
          value={number6}
          onKeyDown={event => {
            if (event.key === "Backspace") {
              this.setState({
                number5: ""
              });
              this.input5Ref.current.maskedInputRef.input.focus();
            }
          }}
          onChange={value => {
            this.setState({
              number6: value
            });
            if (!value) {
              this.input5Ref.current.maskedInputRef.input.focus();
            }
          }}
        />
      </div>
    );
  };

  renderResendButtons = () => {
    const {
      disableResendSmsButton,
      nextResendCodeAvailableAt,
      isWhatsAppEnabled
    } = this.state;

    if (disableResendSmsButton) {
      return (
        <Button
          className={classNames("antd-customize", style.resendSmsButton)}
          type="link"
          disabled
        >
          <CountdownText dateTimeString={nextResendCodeAvailableAt} />
        </Button>
      );
    } else {
      return (
        <>
          <Button
            className={classNames("antd-customize", style.resendSmsButton)}
            type="link"
            disabled={disableResendSmsButton}
            onClick={() =>
              this.onResendVerificationCodeClicked(PhoneVerificationMethod.SMS)
            }
          >
            Reenviar via SMS
          </Button>
          {isWhatsAppEnabled && (
            <Button
              className={classNames("antd-customize", style.resendSmsButton)}
              type="link"
              disabled={disableResendSmsButton}
              onClick={() =>
                this.onResendVerificationCodeClicked(
                  PhoneVerificationMethod.WHATSAPP
                )
              }
            >
              Reenviar via WhatsApp
            </Button>
          )}
        </>
      );
    }
  };

  render() {
    const {
      initialDataRequestStatus,
      responseStatus,
      responseErrorMessage,
      fromEmailVerificationPage,
      isPhoneVerificationDone,
      nextResendCodeAvailableAt,
      disableResendSmsButton,
      isWhatsAppEnabled
    } = this.state;
    const { history, phoneNumber } = this.props;
    const maskedPhoneNumber = StringMask.apply(phoneNumber, "(00) 0 0000-0000");

    return (
      <>
        <Helmet>
          <title>Verificar celular | Vai de Van</title>
        </Helmet>
        {(() => {
          if (initialDataRequestStatus.status === Status.LOADING) {
            return (
              <Loading loadingMessage={initialDataRequestStatus.message} />
            );
          }

          if (responseStatus === ResponseStatus.LOADING) {
            return <Loading loadingMessage="Verificando celular..." />;
          }

          if (responseStatus === ResponseStatus.ERROR) {
            return (
              <Result
                status="warning"
                title="Erro ao verificar celular"
                subTitle={responseErrorMessage}
                extra={[
                  <Button
                    type={disableResendSmsButton ? "primary" : "default"}
                    key="back"
                    onClick={() => {
                      this.setState({
                        responseStatus: ResponseStatus.SUCCESS
                      });
                      this.clearTypedCode();
                      this.focusFirstInput();
                    }}
                  >
                    Voltar
                  </Button>,
                  <Button
                    type="primary"
                    key="resendCode"
                    className={style.resendCodeButton}
                    disabled={disableResendSmsButton}
                    onClick={() => {
                      this.onResendVerificationCodeClicked(
                        isWhatsAppEnabled
                          ? PhoneVerificationMethod.WHATSAPP
                          : PhoneVerificationMethod.SMS
                      );
                      this.setState({
                        responseStatus: ResponseStatus.SUCCESS
                      });
                      this.clearTypedCode();
                      this.focusFirstInput();
                    }}
                  >
                    {disableResendSmsButton ? (
                      <CountdownText
                        dateTimeString={nextResendCodeAvailableAt}
                      />
                    ) : (
                      <>
                        Reenviar código via{" "}
                        {isWhatsAppEnabled ? "WhatsApp" : "SMS"}
                      </>
                    )}
                  </Button>
                ]}
              />
            );
          }

          const phoneVerificationCode = this.getTypedCode();
          const disableVerifyButton = phoneVerificationCode.length < 6;

          if (isPhoneVerificationDone) {
            return (
              <Result
                className={style.resultWrapper}
                status="success"
                title="Celular verificado!"
                subTitle={
                  <div>
                    <p>{PHONE_VERIFIED_MESSAGE}</p>
                  </div>
                }
                extra={[
                  <Button
                    key="home"
                    onClick={() => {
                      history.push(InternalUrl.HOME);
                    }}
                  >
                    Pesquisar viagens
                  </Button>
                ]}
              />
            );
          } else {
            const { isWhatsAppEnabled } = this.state;
            const codeSender = isWhatsAppEnabled ? "WhatsApp" : "SMS";
            const verifyPhoneMessage = fromEmailVerificationPage
              ? `Agora só falta verificar seu celular. Basta digitar, no campo abaixo, o código que enviamos via ${codeSender} para o número`
              : `Para verificar seu celular, digite no campo abaixo o código que enviamos via ${codeSender} para o número`;
            return (
              <div className={style.content}>
                {fromEmailVerificationPage && (
                  <div className={style.resultWrapper}>
                    <Result
                      // className={style.resultWrapper}
                      status="success"
                      title="Email verificado!"
                    />
                  </div>
                )}
                <p
                  style={{
                    marginBottom: 0
                  }}
                >
                  {`${verifyPhoneMessage} `}
                  <span style={{ display: "inline-block" }}>
                    <strong>{maskedPhoneNumber}</strong>.
                  </span>
                </p>

                <div className={`${style.section} ${style.buttons}`}>
                  <div className={style.centerTextInputWrapper}>
                    {this.renderCodeInputs()}
                  </div>
                  <Button
                    ref={this.verifyButtonRef}
                    type="primary"
                    disabled={disableVerifyButton}
                    onClick={this.onVerifyPhoneClicked}
                  >
                    Verificar
                  </Button>
                </div>
                <div className={style.section}>
                  {
                    <div>
                      <p>Não recebeu um código?</p>
                      {this.renderResendButtons()}
                    </div>
                  }
                </div>
              </div>
            );
          }
        })()}
      </>
    );
  }
}

VerifyPhone.propTypes = {
  history: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  roles: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    phoneNumber: state.userInfoReducer.phoneNumber,
    rememberMe: state.userInfoReducer.rememberMe
  };
};

export default lodash.flowRight(
  withRoles,
  connect(mapStateToProps)
)(VerifyPhone);
