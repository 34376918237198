import moment from "moment";

const MIN_PASSWORD_LENGTH = 8;
const DEFAULT_INVALID_BIRTHDAY_MESSAGE = "Por favor, digite uma data válida.";

const passwordHasMinLength = password => {
  return password.length >= MIN_PASSWORD_LENGTH;
};

const passwordHas1Number = password => {
  return /(\d)+/.test(password);
};

const passwordHas1Uppercase = password => {
  return /([A-Z])+/.test(password);
};

/**
 * Validates password. Throws Error if password is not valid.
 * @param password to be validated.
 */
export const validatePassword = password => {
  if (
    !passwordHasMinLength(password) ||
    !passwordHas1Number(password) ||
    !passwordHas1Uppercase(password)
  ) {
    throw new Error(
      `Uma senha válida precisa ter pelo menos ${MIN_PASSWORD_LENGTH} caracteres, 1 número e 1 letra maiúscula.`
    );
  }
};

export const validateBirthday = dateMoment => {
  if (!dateMoment.isValid()) {
    throw new Error(DEFAULT_INVALID_BIRTHDAY_MESSAGE);
  }

  const today = moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY");
  if (!dateMoment.isBefore(today.clone().subtract(18, "years"))) {
    throw new Error("Você precisa ter pelo menos 18 anos.");
  }
  if (!dateMoment.isAfter(today.clone().subtract(120, "years"))) {
    throw new Error(DEFAULT_INVALID_BIRTHDAY_MESSAGE);
  }
};
