import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import HighlightText from "../../highlightText/HighlightText";
import style from "./styles.module.css";
import { numberToPrice } from "../../../util/formatUtils";

const ChildrenAndPetsInfoModal = ({
  visible,
  callback,
  childrenPrice,
  petsPrice
}) => {
  return (
    <Modal
      title={
        <span className={style.modalHeader}>
          Reservas para crianças e animais
        </span>
      }
      visible={visible}
      centered
      onCancel={() => {
        callback();
      }}
      footer={null}
      className="modal"
    >
      <div className={style.modalBody}>
        <div>
          <h2 className={style.title}>Crianças</h2>
          <span>
            <b>Abaixo de 3 anos:</b> Viajam no colo e não pagam.
          </span>
          <br />
          <span>
            <b>De 3 a 6 anos:</b> Pagam R${numberToPrice(childrenPrice)} e
            ocupam um assento.
          </span>
          <br />
          <span>
            <b>A partir de 7 anos:</b> Pagam o valor integral da passagem e
            ocupam um assento.
          </span>
          <br />
          <h2 className={style.title}>Animais</h2>
          <span>
            <b>Tamanho e valor:</b> Animais precisam ser levados no colo em uma
            caixa de transporte de tamanho 1 ou 2 e pagam apenas R$
            {numberToPrice(petsPrice)} adicionais.
          </span>
          <br />
          <span>
            <b>Assento adicional:</b> Caso o passageiro deseje mais conforto,
            poderá reservar um assento adicional para colocar a caixa de
            transporte. No entanto, esse assento extra tem o custo adicional do
            valor integral de uma passagem. Para isso, basta reservar mais um
            assento.
          </span>
          <br />
          <br />
          <span>
            <b>Em resumo:</b> Ao reservar para{" "}
            <HighlightText>crianças de 2 a 6 anos</HighlightText> e{" "}
            <HighlightText>
              animais levados no colo em uma caixa de transporte
            </HighlightText>
            , escolha aqui um assento adicional para cada um e no meio de
            pagamento escolha{" "}
            <HighlightText>pagamento presencial</HighlightText> para pagar o
            valor correto no embarque.
          </span>
        </div>
      </div>
    </Modal>
  );
};

ChildrenAndPetsInfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  childrenPrice: PropTypes.number.isRequired,
  petsPrice: PropTypes.number.isRequired
};

export default ChildrenAndPetsInfoModal;
