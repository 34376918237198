import { shape, string, number } from "prop-types";

export const requestStatusType = shape({
  status: string.isRequired,
  message: string
});

export const cityType = shape({
  value: number.isRequired,
  text: string.isRequired
});

export const tripCityType = shape({
  id: number.isRequired,
  name: string.isRequired
});

export const tripType = shape({
  id: number.isRequired,
  companyLogo: string,
  departureTime: string.isRequired,
  status: string.isRequired,
  price: number.isRequired,
  companyId: number.isRequired,
  reservedSeats: number.isRequired,
  minimumSeatsToDepart: number.isRequired,
  probability: string.isRequired,
  departureCity: tripCityType.isRequired,
  arrivalCity: tripCityType.isRequired
});
