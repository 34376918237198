import React from "react";
import store from "redux/store";

//Use this HOC when a component is not a descendent child on main App Component.
//For example on LoginModal that is a component created by a method, not a rendered component.
export const withStore = Component => {
  class HOC extends React.Component {
    state = {
      storeState: store.getState()
    };

    constructor(props) {
      super(props);

      store.subscribe(() => {
        // When store state is updated, we update local component state
        // that forces the decorated component to rerender since its props are updated.

        this.setState({
          storeState: store.getState()
        });
      });
    }

    render() {
      return (
        <Component
          {...this.props}
          storeState={this.state.storeState} //to access current redux store state
          dispatch={store.dispatch} //to dispatch redux actions
        />
      );
    }
  }

  return HOC;
};
