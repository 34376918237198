import React, { Component } from "react";
import PropTypes from "prop-types";
import "moment/locale/pt-br";
import { Col, Icon, Progress, Row, Timeline, Typography } from "antd";
import { numberToPrice } from "../../util/formatUtils";
import "./styles.css";
import showModal from "../../util/modalHandler";
import ProfileModal from "../modals/companyProfile/ProfileModal";

const TRIP_DEPARTURE_DATE_FORMAT = "DD/MM";
const TRIP_DEPARTURE_TIME_FORMAT = "HH:mm";

const probabilities = {
  MODERATE: {
    percent: 20,
    label: "Baixa chance de confirmação"
  },
  GOOD: {
    percent: 40,
    label: "Boa chance de confirmação"
  },
  HIGH: {
    percent: 60,
    label: "Alta chance de confirmação"
  },
  VERY_HIGH: {
    percent: 80,
    label: "Chance muito alta de confirmação"
  },
  ASSURED: {
    percent: 100,
    label: "Confirmada"
  }
};

class TripCard extends Component {
  openCompanyProfile(companyId) {
    showModal(ProfileModal, { companyId: companyId });
  }

  render() {
    const {
      departure,
      arrival,
      price,
      departureDate,
      onReservationClick,
      probability
    } = this.props;
    const priceString = "R$ " + numberToPrice(price);
    const departureDateString = departureDate.format(
      TRIP_DEPARTURE_DATE_FORMAT
    );
    const departureTimeString = departureDate.format(
      TRIP_DEPARTURE_TIME_FORMAT
    );

    return (
      <>
        <div className={"card"} onClick={onReservationClick}>
          <Row>
            <Col span={24}>
              <div
                style={{
                  padding: "20px 15px",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div>
                  <Icon type="calendar" style={{ fontSize: "18px" }} />
                  <span className={"departure-info"}>
                    {departureDateString}
                  </span>
                  <Icon
                    type="clock-circle"
                    style={{ fontSize: "18px", marginLeft: "10px" }}
                  />
                  <span className={"departure-info"}>
                    {departureTimeString}
                  </span>
                </div>
                <div style={{ display: "inline", textAlign: "end" }}>
                  <span className={"price"}>{priceString}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Timeline style={{ paddingLeft: "15px" }}>
                <Timeline.Item>
                  <Typography.Text className={"timeline-font"}>
                    {departure.substring(0, departure.length - 4)}
                  </Typography.Text>
                </Timeline.Item>
                <Timeline.Item style={{ padding: 0 }}>
                  <Typography.Text className={"timeline-font"}>
                    {arrival.substring(0, arrival.length - 4)}
                  </Typography.Text>
                </Timeline.Item>
              </Timeline>
            </Col>
            <Col span={24}>
              <div
                style={{
                  padding: "0 15px 25px 15px"
                }}
              >
                <Progress
                  width={50}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068"
                  }}
                  percent={probabilities[probability].percent}
                />
                <div style={{ textAlign: "center" }}>
                  {probabilities[probability].label}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

TripCard.propTypes = {
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  departureDate: PropTypes.object.isRequired,
  onReservationClick: PropTypes.func.isRequired,
  probability: PropTypes.string.isRequired
};

export default TripCard;
