import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, withRouter } from "react-router-dom";
import { InternalUrl } from "../../constants/urls";
import Home from "../../pages/home";
import Login from "../../pages/login";
import Signup from "../../pages/signup";
import ForgotPassword from "../../pages/forgotPassword";
import ResetPassword from "../../pages/resetPassword";
import TripDetails from "../../pages/tripDetails";
import Reservation from "../../pages/reservation";
import UpcomingTrips from "../../pages/upcomingTrips";
import Chats from "../../pages/chats";
import Chat from "../../pages/chat";
import VerifyEmail from "../../pages/verifyEmail";
import VerifyPhone from "../../pages/verifyPhone";
import Profile from "../../pages/profile";
import ChangePassword from "../../pages/changePassword";
import TermsAndConditions from "../../pages/termsAndConditions";
import PrivacyPolicy from "../../pages/privacyPolicy";
import { withRoles } from "../../hocs/withRoles";
import CustomRoute from "../customRoute/CustomRoute";
import RedirectTo from "../../pages/redirectTo";
import lodash from "lodash";
import Payment from "../../pages/paymentProfile";
import TripCalendar from "../../pages/tripCalendar";

export const generateRoutesPropsArray = (
  isUser = false,
  verifiedEmail = false,
  verifiedPhone = false
) => {
  return Object.freeze([
    {
      exact: true,
      path: InternalUrl.HOME,
      component: Home
    },
    { path: InternalUrl.LOGIN, component: Login },
    { path: InternalUrl.SIGNUP, component: Signup },
    { path: InternalUrl.FORGOT_PASSWORD, component: ForgotPassword },
    { path: InternalUrl.RESET_PASSWORD, component: ResetPassword },
    { exact: true, path: InternalUrl.TRIP_CALENDAR, component: TripCalendar },
    { exact: true, path: InternalUrl.TRIP_DETAILS, component: TripDetails },
    { path: InternalUrl.RESERVATION, component: Reservation },
    {
      exact: true,
      path: InternalUrl.MY_TRIPS_UPCOMING,
      component: UpcomingTrips,
      isAllowed: isUser
    },
    {
      exact: true,
      path: InternalUrl.CHATS,
      component: Chats,
      isAllowed: isUser
    },
    { path: InternalUrl.CHAT, component: Chat, isAllowed: isUser },
    { path: InternalUrl.VERIFY_EMAIL, component: VerifyEmail },
    {
      path: InternalUrl.VERIFY_PHONE,
      component: VerifyPhone,
      isAllowed: isUser
    },
    { path: InternalUrl.PROFILE, component: Profile, isAllowed: isUser },
    { path: InternalUrl.PAYMENT, component: Payment, isAllowed: isUser },
    {
      path: InternalUrl.CHANGE_PASSWORD,
      component: ChangePassword,
      isAllowed: isUser
    },
    { path: InternalUrl.TERMS_AND_CONDITIONS, component: TermsAndConditions },
    { path: InternalUrl.PRIVACY_POLICY, component: PrivacyPolicy },
    { path: InternalUrl.REDIRECT_TO, component: RedirectTo }
  ]);
};

class Routes extends Component {
  render() {
    const {
      roles: { isUser, verifiedEmail, verifiedPhone }
    } = this.props;

    const routesProps = generateRoutesPropsArray(
      isUser,
      verifiedEmail,
      verifiedPhone
    );

    return (
      <Switch>
        {routesProps.map((routeProps, index) => {
          return <CustomRoute key={index} {...routeProps} />;
        })}
      </Switch>
    );
  }
}

Routes.propTypes = {
  roles: PropTypes.object.isRequired
};

export default lodash.flowRight(withRouter, withRoles)(Routes);
