import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  Rate,
  Select,
  Form,
  Button,
  message,
  Checkbox
} from "antd";
import { getFeedbackCategories, sendFeedback } from "queries/queries";
import "./styles.css";

const { TextArea } = Input;
const { Option } = Select;

const FEEDBACK_CHAR_LIMIT = 1000;

const FormFields = Object.freeze({
  CATEGORY: "category",
  RATE: "rate",
  VALUE: "value",
  ANONYMOUS: "anonymous"
});

const feedbackDescriptions = Object.freeze([
  "Péssimo",
  "Ruim",
  "Aceitável",
  "Bom",
  "Ótimo"
]);

const formItemLayout = {
  wrapperCol: { span: 25 }
};

class FeedbackModal extends Component {
  state = {
    loading: false,
    isLoadingCategories: true,
    categories: []
  };

  componentDidMount() {
    getFeedbackCategories()
      .then(response => {
        this.setState({
          categories: response
        });
      })
      .catch(error => {
        //TODO should give feedback to user if fail loading categories?
      })
      .finally(() => {
        this.setState({
          isLoadingCategories: false
        });
      });
  }

  handleSubmit = e => {
    const { callback, form } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const feedback = {
          category: values[FormFields.CATEGORY],
          value: values[FormFields.VALUE],
          rate: values[FormFields.RATE],
          anonymous: values[FormFields.ANONYMOUS]
        };

        this.setState({
          loading: true
        });

        sendFeedback(feedback)
          .then(response => {
            message.success("Sua avaliação foi recebida com êxito!");

            this.setState({
              loading: false
            });

            callback();
          })
          .catch(error => {
            this.setState({
              loading: false
            });

            Modal.error({
              title: "Não foi possível enviar a sua avaliação",
              content: error.message,
              centered: true
            });
          });
      }
    });
  };

  render() {
    const { loading, isLoadingCategories, categories } = this.state;
    const {
      visible,
      callback,
      form,
      roles: { isUser }
    } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const feedbackRate = getFieldValue(FormFields.RATE);

    return (
      <Modal
        title={
          <span className="feedback-form-header">Queremos ouvir você!</span>
        }
        visible={visible}
        centered
        onCancel={() => {
          callback();
        }}
        footer={null}
      >
        <div className="feedback-container">
          <div className="feedback-form-text">
            Com a sua participação, vamos construir juntos a <b>Vai de Van</b>{" "}
            do amanhã.
          </div>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="feedback-form"
          >
            <Form.Item>
              {getFieldDecorator(FormFields.CATEGORY, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, escolha uma categoria."
                  }
                ]
              })(
                <Select
                  placeholder="Selecione uma categoria"
                  loading={isLoadingCategories}
                  size="large"
                >
                  {categories.map(category => (
                    <Option key={category.id} value={category.id}>
                      {category.description}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.VALUE, {
                initialValue: "",
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value.length === 0) {
                        callback("Por favor, descreva a sua avaliação.");
                      } else if (value.length > FEEDBACK_CHAR_LIMIT) {
                        callback("Digite no máximo 1000 caracteres.");
                      }

                      callback();
                    }
                  }
                ]
              })(
                <TextArea
                  style={{ fontSize: "16px" }}
                  placeholder="Descreva a sua avaliação..."
                  rows={4}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.RATE, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, escolha uma nota."
                  }
                ]
              })(<Rate allowClear={false} />)}
              {feedbackRate ? (
                <span className="ant-rate-text">
                  {feedbackDescriptions[feedbackRate - 1]}
                </span>
              ) : (
                ""
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.ANONYMOUS, {
                valuePropName: "checked",
                initialValue: true,
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value && !isUser) {
                        callback(
                          "Só é possível se identificar se você estiver logado"
                        );
                      }

                      callback();
                    }
                  }
                ]
              })(<Checkbox>Enviar feedback sem me identificar</Checkbox>)}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={"login-form-button"}
                loading={loading}
                size="large"
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  form: PropTypes.PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  roles: PropTypes.object.isRequired
};

export default Form.create({ name: "feedback" })(FeedbackModal);
