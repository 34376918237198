import * as ConfigsType from "../actionTypes/configs";
import { getPublicVariable } from "../../queries/queries";
import { OpenVariable } from "../../constants/openVariable";

export const resetNotificationIntervalToDefault = () => ({
  type: ConfigsType.RESET_NOTIFICATION_INTERVAL_TO_DEFAULT
});

export const setNotificationInterval = intervalInMillis => ({
  type: ConfigsType.SET_NOTIFICATION_INTERVAL,
  payload: intervalInMillis
});

export const getOAuthLoginEnabledStatus = () => (dispatch, getState) => {
  dispatch({
    type: ConfigsType.GET_GOOGLE_LOGIN_ENABLED,
    payload: getPublicVariable(OpenVariable.OAUTH_LOGIN_ENABLED)
  });
};

export const getRouteSearchStyleEnabledStatus = () => (dispatch, getState) => {
  dispatch({
    type: ConfigsType.GET_ROUTE_SEARCH_STYLE_ENABLED,
    payload: getPublicVariable(OpenVariable.ROUTE_SEARCH_STYLE_ENABLED)
  });
};
