import * as ThemeType from "../actionTypes/theme";

const initialState = {
  blackLogoUrl: undefined,
  whiteLogoUrl: undefined,
  logoTooltip: undefined,
  coverText: undefined
};

const theme = (state = initialState, { type, payload }) => {
  switch (type) {
    case ThemeType.SET_THEME: {
      const { blackLogoUrl, whiteLogoUrl, logoTooltip, coverText } = payload;
      return {
        ...state,
        blackLogoUrl,
        whiteLogoUrl,
        logoTooltip,
        coverText
      };
    }
    default:
      return state;
  }
};

export default theme;
