import React from "react";

const PrivacyPolicyContent = () => (
  <>
    <p>
      A Vai de Van é desenvolvida por Fourlabs ("nós", "nosso" ou "nós").
      Estamos comprometidos em proteger a privacidade dos nossos usuários
      ("você" ou "seu"). Esta Política de Privacidade descreve como coletamos,
      usamos, divulgamos e protegemos suas informações ao usar nosso aplicativo.
    </p>
    <h3>Esta Política de Privacidade está dividida em 8 seções:</h3>
    <ol type="1">
      <li>
        <strong>Informações que Coletamos</strong>
      </li>
      <li>
        <strong>Como Usamos Suas Informações</strong>
      </li>
      <li>
        <strong>Compartilhamento de Informações</strong>
      </li>
      <li>
        <strong>Retenção das informações</strong>
      </li>
      <li>
        <strong>Segurança das Informações</strong>
      </li>
      <li>
        <strong>Seus Direitos</strong>
      </li>
      <li>
        <strong>Privacidade de Crianças</strong>
      </li>
      <li>
        <strong>Alterações a Esta Política de Privacidade</strong>
      </li>
    </ol>
    <p>
      Esta Política de Privacidade não abrange o processamento de informações
      pessoais realizado por outras entidades, indivíduos, websites ou
      terceiros, incluindo possíveis parceiros da Vai de Van. Para entender como
      essas entidades tratam suas informações pessoais, recomendamos que você
      consulte as respectivas políticas de privacidade. A Vai de Van não assume
      responsabilidade pelo tratamento de informações pessoais por essas
      entidades. Esta Política de Privacidade foi revisada pela última vez em 23
      de julho de 2024.
    </p>
    <h3>1. Informações que Coletamos</h3>
    <h4>1.1 Informações Pessoais</h4>
    <p>
      Podemos coletar informações pessoais que você nos fornece diretamente,
      como:
    </p>
    <ul>
      <li>
        Dados de cadastro, como nome, número de documento de identificação
        pessoal, data de nascimento, endereço de e-mail e número de telefone.
      </li>
      <li>
        Dados financeiros e de pagamento, como dados do cartão de crédito e
        informações relacionadas aos Pix efetuados na plataforma.
      </li>
    </ul>
    <h4>1.2 Informações Coletadas Automaticamente</h4>
    <p>
      Quando você usa nosso aplicativo, podemos coletar ou gerar automaticamente
      certas informações, como:
    </p>
    <ul>
      <li>
        Informações dos dispositivos que você usa para acessar as nossas
        plataformas, como endereço IP, tipo de dispositivo, sistema operacional,
        e informações relacionadas.
      </li>
      <li>
        Dados que podemos gerar automaticamente sobre você e como você utiliza
        os nossos serviços, como suas buscas sobre origem e destino.
      </li>
    </ul>
    <h3>2. Como Usamos Suas Informações</h3>
    <p>Usamos as informações coletadas para:</p>
    <ul>
      <li>Viabilizar as nossas operações</li>
      <li>Fornecer e melhorar nosso aplicativo</li>
      <li>Personalizar sua experiência</li>
      <li>Enviar notificações e atualizações</li>
      <li>Responder a solicitações de suporte ao cliente</li>
    </ul>
    <h3>3. Compartilhamento de Informações</h3>
    <p>
      Não vendemos, trocamos ou alugamos suas informações pessoais para
      terceiros, mas podemos compartilhar suas informações com:
    </p>
    <ul>
      <li>
        Empresas parceiras e/ou prestadores de serviços terceirizados que nos
        ajudam a operar o aplicativo
      </li>
      <li>Autoridades legais, conforme exigido por lei</li>
      <li>
        Em caso de fusão, aquisição ou venda de ativos, suas informações podem
        ser transferidas para o novo proprietário
      </li>
    </ul>
    <h3>4. Retenção das informações</h3>
    <p>
      As suas informações pessoais fornecidas por você durante o cadastro, as
      informações que geramos automaticamente sobre você, e as informações de
      pagamento poderão ser armazenadas em nossos servidores por tempo
      indeterminado, exceto as informações de cartões de crédito, que não são
      armazenadas nos nossos servidores, apenas são transmitidas aos nossos
      serviços de processamento de pagamentos.
    </p>
    <h3>5. Segurança das Informações</h3>
    <p>
      Tomamos medidas razoáveis e seguimos boas práticas de segurança da
      informação para proteger suas informações contra acesso não autorizado,
      alteração, divulgação ou destruição. No entanto, nenhuma transmissão de
      dados pela Internet ou armazenamento eletrônico é 100% segura, portanto,
      não podemos garantir segurança absoluta.
    </p>
    <h3>6. Seus Direitos</h3>
    <p>Você tem o direito de:</p>
    <ul>
      <li>Acessar suas informações pessoais que temos</li>
      <li>Solicitar a correção de informações incorretas ou incompletas</li>
      <li>Solicitar a exclusão de suas informações pessoais</li>
    </ul>
    <h3>7. Privacidade de Crianças</h3>
    <p>
      Nosso aplicativo não é destinado a crianças e pessoas menores de idade.
      Não coletamos intencionalmente informações pessoais de crianças e pessoas
      menores de idade, exceto aquelas fornecidas explicitamente pelos pais ou
      responsáveis durante a criação de reservas, a fim apenas de identificar os
      passageiros para um determinado trajeto.
    </p>
    <h3>8. Alterações a Esta Política de Privacidade</h3>
    <p>
      Podemos atualizar esta Política de Privacidade periodicamente.
      Notificaremos você sobre quaisquer alterações, publicando a nova política
      em nosso aplicativo. Aconselhamos você a revisar esta política
      regularmente para se manter informado sobre como protegemos suas
      informações.
    </p>
    <h3>Contato</h3>
    <p>
      Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em
      contato conosco:
    </p>
    <p>
      <strong>contatovaidevan@gmail.com</strong>
    </p>
  </>
);

export default PrivacyPolicyContent;
