import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Helmet } from "react-helmet";
import { Form, Icon, Input, Button, Typography, message, Result } from "antd";
import { changePassword } from "queries/queries";
import { setToken } from "services/auth";
import RequestStatus, { Status } from "model/RequestStatus";
import Loading from "../../components/loading";
import { InternalUrl } from "../../constants/urls";
import { validatePassword } from "../../util/validationUtils";
import "./styles.less";

const { Title } = Typography;

const INPUT_ICON_COLOR = "rgba(0,0,0,.25)";

const FormFields = Object.freeze({
  CURRENT_PASSWORD: "curentPassword",
  NEW_PASSWORD: "newPassword"
});

class ChangePassword extends Component {
  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED)
  };

  handleSubmit = e => {
    const { form, rememberMe } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const oldPassword = values[FormFields.CURRENT_PASSWORD];
        const newPassword = values[FormFields.NEW_PASSWORD];

        this.setState({
          requestStatus: new RequestStatus(Status.LOADING, "Trocando senha")
        });

        changePassword(oldPassword, newPassword)
          .then(token => {
            setToken(token, rememberMe);
            this.setState({
              requestStatus: new RequestStatus(Status.SUCCESS)
            });
            message.success("Sua senha foi trocada!");
          })
          .catch(error => {
            this.setState({
              requestStatus: new RequestStatus(Status.ERROR, error.message)
            });
          });
      }
    });
  };

  render() {
    const { requestStatus } = this.state;
    const { history, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const isButtonDisabled =
      lodash.isEmpty(getFieldValue(FormFields.CURRENT_PASSWORD)) ||
      lodash.isEmpty(getFieldValue(FormFields.NEW_PASSWORD));

    return (
      <>
        <Helmet>
          <title>Perfil | Vai de Van</title>
        </Helmet>
        <div
          className={"main-container"}
          style={{
            marginTop: "20px"
          }}
        >
          <div style={{ width: "300px" }}>
            <div className="header">
              <Title level={4}>Trocar senha</Title>
            </div>
            {(() => {
              if (requestStatus.status === Status.LOADING) {
                return <Loading loadingMessage={requestStatus.message} />;
              }

              if (requestStatus.status === Status.SUCCESS) {
                return (
                  <Result
                    className={"result-wrapper"}
                    status="success"
                    title="Sua senha foi trocada"
                    extra={[
                      <Button
                        key="continue"
                        type="primary"
                        size="large"
                        onClick={() => {
                          history.push(InternalUrl.HOME);
                        }}
                      >
                        Voltar ao Vai de Van
                      </Button>
                    ]}
                  />
                );
              }

              if (requestStatus.status === Status.ERROR) {
                return (
                  <Result
                    status="warning"
                    title="Erro ao trocar senha"
                    subTitle={requestStatus.message}
                    extra={
                      <Button
                        key="retry"
                        type="primary"
                        size="large"
                        onClick={() => {
                          this.setState({
                            requestStatus: new RequestStatus(Status.NOT_STARTED)
                          });
                        }}
                      >
                        Entendi
                      </Button>
                    }
                  />
                );
              }

              return (
                <Form onSubmit={this.handleSubmit} className={"login-form"}>
                  <Form.Item>
                    {getFieldDecorator(FormFields.CURRENT_PASSWORD, {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Por favor, digite a sua senha atual."
                        }
                      ]
                    })(
                      <Input.Password
                        size="large"
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: INPUT_ICON_COLOR }}
                          />
                        }
                        placeholder="Senha atual"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(FormFields.NEW_PASSWORD, {
                      initialValue: "",
                      rules: [
                        {
                          validator: (rule, value, callback) => {
                            if (!value || value.length === 0) {
                              callback("Por favor, digite uma nova senha.");
                            } else {
                              try {
                                validatePassword(value);
                              } catch (e) {
                                callback(e.message);
                              }
                            }

                            callback();
                          }
                        }
                      ]
                    })(
                      <Input.Password
                        size="large"
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: INPUT_ICON_COLOR }}
                          />
                        }
                        placeholder="Nova senha"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <div className={"buttons"}>
                      <Button
                        size="large"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={isButtonDisabled}
                      >
                        Salvar
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              );
            })()}
          </div>
        </div>
      </>
    );
  }
}

ChangePassword.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  rememberMe: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    rememberMe: state.userInfoReducer.rememberMe
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default lodash.flow([
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: "signup" })
])(ChangePassword);
