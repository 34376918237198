import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Badge, Typography, Skeleton, Avatar, Icon, Pagination } from "antd";
import { getChats } from "queries/queries";
import { InternalUrl } from "constants/urls";
import RequestStatus, { Status } from "../../model/RequestStatus";
import RequestError from "components/requestError";
import SimpleCard from "components/cards/SimpleCard";
import SimpleTripRoute from "components/tripRoute/SimpleTripRoute";
import { BACKEND_DATETIME_FORMAT } from "constants/backend";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import "./styles.css";

const { Title } = Typography;

const PAGE_SIZE = 5;

class Chats extends Component {
  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    page: 0,
    totalElements: 0,
    chats: []
  };

  componentDidMount() {
    this.goToPage(0);
  }

  goToPage = pageNumber => {
    this.setState({
      requestStatus: new RequestStatus(Status.LOADING),
      page: pageNumber,
      chats: []
    });

    getChats(pageNumber, PAGE_SIZE)
      .then(response => {
        this.setState({
          requestStatus: new RequestStatus(Status.SUCCESS),
          totalElements: response.totalElements,
          chats: response.chats
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  goToChat = chatCode => {
    const { history } = this.props;

    history.push(InternalUrl.CHAT.replace(":chatCode", chatCode));
  };

  calculateRelativeTimeFromPast = dateTime => {
    return moment(dateTime, BACKEND_DATETIME_FORMAT).fromNow();
  };

  renderChats = () => {
    const { userName } = this.props;
    const { page, chats, requestStatus } = this.state;

    if (requestStatus.status === Status.ERROR) {
      return (
        <RequestError
          errorTitle={"Não foi possível carregar as suas conversas"}
          errorMessage={requestStatus.message}
          onRetry={() => this.goToPage(page)}
        />
      );
    }

    if (requestStatus.status === Status.LOADING) {
      const skeletons = [];
      for (let i = 0; i < PAGE_SIZE; i++) {
        skeletons.push(
          <Skeleton avatar active paragraph={{ rows: 1 }} key={i} />
        );
      }
      return <div>{skeletons}</div>;
    }

    if (chats.length < 1) {
      return <div>Você não tem nenhuma conversa</div>;
    }

    return chats.map(chat => {
      const {
        code,
        departure,
        arrival,
        logo,
        lastMessage,
        sentAt,
        unread,
        sentBy: messageSender
      } = chat;

      const senderIsMe = messageSender === userName;

      return (
        <SimpleCard key={code} clickable>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => this.goToChat(code)}
          >
            <div style={{ width: "fit-content" }}>
              <Avatar size={48} src={logo} icon={logo ? "loading" : "user"} />
            </div>
            <div
              style={{
                flexGrow: 1,
                alignItems: "center",
                alignContent: "space-between",
                margin: "auto 10px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflowX: "hidden"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <SimpleTripRoute departure={departure} arrival={arrival} />
                {unread && !senderIsMe ? (
                  <div>
                    {isMobile ? (
                      <div style={{ margin: "0 7px 8px 0px" }}>
                        <Badge
                          count={
                            <Icon type="message" style={{ color: "#f5222d" }} />
                          }
                        />
                      </div>
                    ) : (
                      <div style={{ marginBottom: "10px" }}>
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px"
                          }}
                        >
                          Nova mensagem
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    alignItems: "center",
                    alignContent: "space-between",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflowX: "hidden"
                  }}
                >
                  <span
                    style={{
                      flexGrow: 1,
                      fontWeight: unread && !senderIsMe ? "bold" : "normal"
                    }}
                  >
                    {lastMessage}
                  </span>
                </div>
                <span
                  style={{
                    marginLeft: "8px",
                    float: "right",
                    color: "blue",
                    fontSize: "12px"
                  }}
                >
                  {this.calculateRelativeTimeFromPast(sentAt)}
                </span>
              </div>
            </div>
            {/*<Icon type="right" />*/}
          </div>
        </SimpleCard>
      );
    });
  };

  render() {
    const { page, totalElements } = this.state;
    return (
      <>
        <Helmet>
          <title>Chats | Vai de Van</title>
        </Helmet>
        <div className="main-container">
          <div className="max-width-container chats-container">
            <Title level={4}>Chats</Title>
            {this.renderChats()}
            <Pagination
              style={{ display: "flex", justifyContent: "center" }}
              size="small"
              current={page + 1}
              pageSize={PAGE_SIZE}
              total={totalElements}
              onChange={pageNumber => this.goToPage(pageNumber - 1)}
            />
          </div>
        </div>
      </>
    );
  }
}

Chats.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  userName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    userName: state.userInfoReducer.userName
  };
};
export default connect(mapStateToProps)(Chats);
