import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATETIME_FORMAT
} from "../constants/backend";

export function numberToPrice(price) {
  if (price === undefined || price === null) {
    return "";
  }

  return price
    .toFixed(2)
    .toString()
    .replace(".", ",");
}

export function currentTimeMoment() {
  return moment(new Date());
}

/**
 * Returns the Duration of the difference between times.
 * If start is a date that comes before end, Duration object will have a positive duration
 * @param endMoment
 * @param startMoment
 */
export function timeDiff(startMoment, endMoment) {
  return moment.duration(endMoment.diff(startMoment));
}

export function backendDateTimeStringToMoment(backendDateTimeString) {
  return moment(backendDateTimeString, BACKEND_DATETIME_FORMAT);
}

export function momentToBackendDateTimeString(aMoment) {
  return aMoment.format(BACKEND_DATETIME_FORMAT);
}

export function backendDateStringToMoment(backendDateString) {
  return backendDateString
    ? moment(backendDateString, BACKEND_DATE_FORMAT)
    : backendDateString;
}

export function momentToBackendDateString(aMoment) {
  return aMoment ? aMoment.format(BACKEND_DATE_FORMAT) : aMoment;
}

export function backendDateTimeToStringRelativeTime(dateTime) {
  if (dateTime) {
    return moment(dateTime, BACKEND_DATETIME_FORMAT).fromNow();
  } else {
    console.error(
      `Error when trying to convert dateTime ${dateTime} to relative time String`
    );
    return null;
  }
}

export function stringToBoolean(stringBoolean) {
  return stringBoolean === true || stringBoolean === "true";
}

export function numberToMinimumDigits(number) {
  if (number === null || number === undefined) return number;

  return number.toLocaleString("pt-BR", {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
}
