import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Alert, Button, Skeleton, Typography } from "antd";
import { getUpcomingTripsWithReservations } from "queries/queries";
import { backendDateTimeStringToMoment } from "util/formatUtils";
import SimpleTripRoute from "components/tripRoute/SimpleTripRoute";
import SimpleCard from "components/cards/SimpleCard";
import RequestError from "components/requestError";
import { InternalUrl } from "constants/urls";
import RequestStatus, { Status } from "../../model/RequestStatus";
import { TripStatus } from "../../constants/TripStatus";
import "./styles.less";

const { Title } = Typography;

const TRIP_DEPARTURE_DATE_FORMAT = "dddd, DD/MM/YYYY, [às] HH:mm";

const statuses = {
  REQUESTED: {
    label: "Em andamento",
    type: "warning"
  },
  EXPIRED: {
    label: "Expirada",
    type: "warning"
  },
  READY_FOR_BOARDING_POINT: {
    label: "Informar ponto de embarque",
    type: "warning"
  },
  CANCELLED: {
    label: "Cancelada",
    type: "error"
  },
  PAYMENT_FAILED: {
    label: "Falha no pagamento",
    type: "error"
  },
  READY_FOR_PAYMENT: {
    label: "Aguardando pagamento",
    type: "info"
  },
  PENDING_PAYMENT_CONFIRMATION: {
    label: "Pagamento em análise",
    type: "info"
  },
  CONFIRMED: {
    label: "Confirmada",
    type: "success"
  },
  CANCELLED_TRIP: {
    label: "Viagem cancelada",
    type: "error"
  }
};

class UpcomingTrips extends Component {
  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    upcomingTrips: []
  };

  componentDidMount() {
    this.fetchUpcomingTrips();
  }

  fetchUpcomingTrips = () => {
    this.setState({
      requestStatus: new RequestStatus(Status.LOADING, "Carregando viagens")
    });

    getUpcomingTripsWithReservations()
      .then(tripList => {
        this.setState({
          requestStatus: new RequestStatus(Status.SUCCESS),
          upcomingTrips: tripList
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  onViewDetailsClick = reservationCode => {
    const { history } = this.props;

    history.push(
      InternalUrl.RESERVATION.replace(":reservationCode", reservationCode)
    );
  };

  renderUpcomingTripsList = () => {
    const { upcomingTrips, requestStatus } = this.state;
    const { history } = this.props;

    if (requestStatus.status === Status.ERROR) {
      return (
        <RequestError
          errorTitle={"Não foi possível carregar as suas próximas viagens"}
          errorMessage={requestStatus.message}
          onRetry={this.fetchUpcomingTrips}
        />
      );
    }

    if (requestStatus.status === Status.LOADING) {
      return (
        <div>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      );
    }

    if (requestStatus.status === Status.SUCCESS) {
      if (upcomingTrips.length === 0) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h3>Você não tem nenhuma reserva de viagem para acontecer.</h3>
            <Button
              type="primary"
              onClick={() => {
                history.push(InternalUrl.HOME);
              }}
            >
              Procurar viagens
            </Button>
          </div>
        );
      }

      return upcomingTrips.map(trip => {
        const {
          id,
          departure,
          arrival,
          departureTime,
          code,
          reservationStatus,
          status
        } = trip;

        const departureDate = backendDateTimeStringToMoment(
          departureTime
        ).format(TRIP_DEPARTURE_DATE_FORMAT);

        const reservationStatusObject =
          status === TripStatus.CANCELLED
            ? statuses.CANCELLED_TRIP
            : statuses[reservationStatus];

        return (
          <SimpleCard key={id}>
            <div className="reservation-status-container">
              <div className="trip-route">
                <SimpleTripRoute departure={departure} arrival={arrival} />
                <span>Saída {departureDate}</span>
              </div>
              {reservationStatusObject && (
                <div className="reservation-status-label">
                  <Alert
                    message={reservationStatusObject.label}
                    type={reservationStatusObject.type}
                  />
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                type={"link"}
                onClick={() => this.onViewDetailsClick(code)}
              >
                Ver reserva
              </Button>
            </div>
          </SimpleCard>
        );
      });
    }

    return null;
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Viagens agendadas | Vai de Van</title>
        </Helmet>
        <div className="main-container">
          <div className="max-width-container">
            <Title level={4}>Viagens agendadas</Title>
            {this.renderUpcomingTripsList()}
          </div>
        </div>
      </>
    );
  }
}

UpcomingTrips.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default UpcomingTrips;
