import React from "react";
import { Typography } from "antd";
import PropTypes from "prop-types";
const { Title, Paragraph } = Typography;

function HomepageDynamicContent({ content }) {
  if (!content) {
    return null;
  }

  const { title, subtitle, textColor, backgroundColor, sections } = content;

  return (
    <>
      <div
        className={"dynamic-content-main-container"}
        style={{ backgroundColor: backgroundColor, marginBottom: "30px" }}
      >
        <Title
          style={{
            textAlign: "center",
            marginTop: "40px",
            paddingTop: "30px",
            color: textColor
          }}
        >
          {title}
        </Title>
        <Title
          level={3}
          style={{
            textAlign: "center",
            fontSize: "16px",
            padding: "0 15px 0 15px",
            color: textColor
          }}
        >
          {subtitle}
        </Title>
        <div className={"dynamic-content-sections-container"}>
          {sections &&
            sections.map(({ imageUrl, text }, index) => {
              return (
                <div key={index} className={"dynamic-content-section"}>
                  <img
                    src={imageUrl}
                    alt=""
                    className={"dynamic-content-icon"}
                  />
                  <Paragraph style={{ fontSize: "18px", color: textColor }}>
                    {text}
                  </Paragraph>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

HomepageDynamicContent.defaultProps = {
  content: null
};

HomepageDynamicContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    )
  })
};

export default HomepageDynamicContent;
