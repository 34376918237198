import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Result,
  Typography,
  Icon,
  Checkbox,
  message,
  Modal
} from "antd";
import lodash from "lodash";
import {
  addPassengers,
  cancelReservation,
  getSavedPassengers,
  forgetPassenger
} from "queries/queries";
import {
  getParamsFromPrevLocation,
  removeSubRoutesFromCurrentUrl
} from "util/routeUtils";
import Loading from "components/loading";
import { numberToPrice } from "util/formatUtils";
import RequestStatus, { Status } from "model/RequestStatus";
import SubRoutes from "../subRoutes";
import classNames from "classnames/bind";
import { InternalUrl } from "../../../../constants/urls";
import { PRIMARY_COLOR } from "../../../../constants/colorDefs";
import "./styles.css";
import SimpleCard from "../../../../components/cards/SimpleCard";
import showModal, { closeModal } from "../../../../util/modalHandler";
import CreateNewPassengerModal from "../../../../components/modals/createNewPassenger/CreateNewPassengerModal";
import StringMask from "string-mask";
import HighlightText from "../../../../components/highlightText/HighlightText";
import { Color } from "../../../../constants/colors";

const { Title } = Typography;

const CurrentAction = Object.freeze({
  ADD_PASSENGERS: "ADD_PASSENGERS",
  CANCEL_RESERVATION: "CANCEL_RESERVATION"
});

class Passengers extends Component {
  newPassengerModalRef = undefined;

  state = {
    favoritePassengersRequestStatus: new RequestStatus(Status.NOT_STARTED),
    reservationRequestStatus: new RequestStatus(Status.NOT_STARTED),
    currentAction: CurrentAction.ADD_PASSENGERS,
    favoritePassengers: [],
    selectedPaxIds: [],
    tripDetails: undefined,
    reservation: undefined
  };

  constructor(props) {
    super(props);

    const { tripDetails, reservation } = getParamsFromPrevLocation(props);

    this.state = {
      ...this.state,
      tripDetails,
      reservation
    };
  }

  componentDidMount() {
    this.fetchFavoritePassengers();
  }

  componentWillUnmount() {
    closeModal(this.newPassengerModalRef);
  }

  fetchFavoritePassengers = () => {
    this.setState({
      favoritePassengersRequestStatus: new RequestStatus(
        Status.LOADING,
        "Carregando passageiros favoritos"
      )
    });

    getSavedPassengers()
      .then(response => {
        this.setState({
          favoritePassengersRequestStatus: new RequestStatus(Status.SUCCESS),
          favoritePassengers: response
        });
      })
      .catch(() => {
        message.error(
          "Não foi possível carregar seus passageiros favoritos. Por favor, tente de novo ou preencha os dados manualmente"
        );
        this.setState({
          favoritePassengersRequestStatus: new RequestStatus(
            Status.ERROR,
            "Erro ao carregar passageiros favoritos"
          )
        });
      });
  };

  onForgetPassengerClick = pax => {
    const { favoritePassengers, selectedPaxIds } = this.state;

    Modal.confirm({
      title: "Deseja realmente excluir esse passageiro?",
      content: (
        <div>
          <span>
            <strong>{pax.name}</strong>
          </span>
          <br />
          <span>CPF: {StringMask.apply(pax.cpf, "000.000.000-00")}</span>
          <br />
          <span>Cel: {StringMask.apply(pax.phone, "(00) 0 0000-0000")}</span>
          <br />
        </div>
      ),
      centered: true,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => {
        if (pax.remember) {
          forgetPassenger(pax.id)
            .then(() => {
              this.setState({
                favoritePassengers: favoritePassengers.filter(
                  p => p.id !== pax.id
                ),
                selectedPaxIds: selectedPaxIds.filter(id => id !== pax.id)
              });
            })
            .catch(error => {
              message.error(
                `Não foi possível excluir esse passageiro: ${error.message}`
              );
            });
        } else {
          this.setState({
            favoritePassengers: favoritePassengers.filter(p => p.id !== pax.id),
            selectedPaxIds: selectedPaxIds.filter(id => id !== pax.id)
          });
        }
      }
    });
  };

  onCancelReservationClick = () => {
    const {
      reservation: { code: reservationCode },
      tripDetails
    } = this.state;
    const { history } = this.props;

    Modal.confirm({
      title: "Cancelar reserva",
      content: "Deseja realmente cancelar essa reserva?",
      centered: true,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => {
        this.setState({
          reservationRequestStatus: new RequestStatus(
            Status.LOADING,
            "Cancelando reserva"
          ),
          currentAction: CurrentAction.CANCEL_RESERVATION
        });

        cancelReservation(reservationCode)
          .then(reservation => {
            message.success("Sua reserva foi cancelada!");
            const nextLocation = removeSubRoutesFromCurrentUrl(
              this.props,
              InternalUrl.RESERVATION
            ).concat(SubRoutes.CANCELLED);
            history.replace(nextLocation, {
              reservation,
              tripDetails
            });
          })
          .catch(error => {
            this.setState({
              reservationRequestStatus: new RequestStatus(
                Status.ERROR,
                error.message
              )
            });
          });
      }
    });
  };

  togglePaxSelected = id => {
    const { selectedPaxIds } = this.state;

    const isPaxSelected = selectedPaxIds.includes(id);
    let newSelectedPaxIds;

    if (isPaxSelected) {
      newSelectedPaxIds = [...selectedPaxIds].filter(
        selectedId => selectedId !== id
      );
    } else {
      newSelectedPaxIds = [...selectedPaxIds];
      newSelectedPaxIds.push(id);
    }

    this.setState({
      selectedPaxIds: newSelectedPaxIds
    });
  };

  onAddNewPassengerClicked = () => {
    const {
      reservation: { numberOfSeats },
      selectedPaxIds,
      favoritePassengers
    } = this.state;
    const areAllNecessaryPaxSelected = selectedPaxIds.length === numberOfSeats;

    if (!areAllNecessaryPaxSelected) {
      this.newPassengerModalRef = showModal(
        CreateNewPassengerModal,
        { favoritePassengers },
        passenger => {
          if (passenger) {
            const newFavoritePassengers = [...favoritePassengers];
            //remove pax with same cpf, if it exists
            const [removedPax] = lodash.remove(
              newFavoritePassengers,
              pax => pax.cpf === passenger.cpf
            );
            newFavoritePassengers.push(passenger);

            const newSelectedPaxIds = [...selectedPaxIds];
            //remove id of the removed pax from selected ids, if it exists
            if (removedPax?.id >= 0) {
              lodash.remove(newSelectedPaxIds, id => id === removedPax.id);
            }
            newSelectedPaxIds.push(passenger.id);

            this.setState({
              favoritePassengers: newFavoritePassengers,
              selectedPaxIds: newSelectedPaxIds
            });
          }
        }
      );
    }
  };

  onNextStepClicked = () => {
    const {
      favoritePassengers,
      selectedPaxIds,
      reservation: { code: reservationCode },
      tripDetails
    } = this.state;
    const { history } = this.props;

    const favoritePassengersCopy = [...favoritePassengers];

    const selectedPassengers = favoritePassengersCopy.filter(p =>
      selectedPaxIds.includes(p.id)
    );

    this.setState({
      reservationRequestStatus: new RequestStatus(
        Status.LOADING,
        "Adicionando passageiros"
      ),
      currentAction: CurrentAction.ADD_PASSENGERS
    });

    addPassengers(reservationCode, selectedPassengers)
      .then(reservation => {
        this.setState({
          reservationRequestStatus: new RequestStatus(Status.SUCCESS)
        });

        const nextLocation = InternalUrl.RESERVATION.replace(
          ":reservationCode",
          reservationCode
        ).concat(SubRoutes.BOARDING_POINT);
        history.replace(nextLocation, {
          tripDetails,
          reservation
        });
      })
      .catch(error => {
        this.setState({
          reservationRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          )
        });
      });
  };

  renderFavoritePassengers = () => {
    const {
      reservation: { numberOfSeats },
      favoritePassengers,
      selectedPaxIds
    } = this.state;

    return (
      <div>
        {favoritePassengers.length === 0 && (
          <div
            style={{ maxWidth: "380px", margin: "auto", textAlign: "justify" }}
          >
            <h3 style={{ color: "#bbbbbb" }}>
              Você não tem nenhum passageiro salvo. Clique no botão{" "}
              <span
                className={"clickable"}
                style={{ whiteSpace: "nowrap" }}
                onClick={this.onAddNewPassengerClicked}
              >
                <HighlightText>+ Adicionar novo</HighlightText>
              </span>{" "}
              logo abaixo para adicionar um novo passageiro.
            </h3>
          </div>
        )}
        {favoritePassengers.map(pax => {
          const isPaxSelected = selectedPaxIds.includes(pax.id);
          const disableCard =
            !isPaxSelected && selectedPaxIds.length === numberOfSeats;

          return (
            <SimpleCard
              key={pax.id}
              hoverable
              clickable
              selected={isPaxSelected}
              disabled={disableCard}
              onClick={() => this.togglePaxSelected(pax.id)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "40px"
                  }}
                >
                  <Checkbox checked={isPaxSelected} />
                </div>
                <div
                  className={classNames(
                    "selectable-passenger-card",
                    disableCard ? "disabled" : ""
                  )}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                  }}
                >
                  <p>
                    <strong>{pax.name}</strong>
                  </p>
                  <p>CPF: {StringMask.apply(pax.cpf, "000.000.000-00")}</p>
                  <p>Cel: {StringMask.apply(pax.phone, "(00) 0 0000-0000")}</p>
                </div>
                <div>
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      this.onForgetPassengerClick(pax);
                    }}
                    className="clickable clear-notification-icon"
                    style={{
                      padding: "16px",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Icon
                      type="delete"
                      style={{ color: disableCard ? "#d7d8d4" : "" }}
                    />
                  </div>
                </div>
              </div>
            </SimpleCard>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      reservationRequestStatus,
      currentAction,
      reservation: { numberOfSeats, price },
      selectedPaxIds
    } = this.state;
    const areAllNecessaryPaxSelected = selectedPaxIds.length === numberOfSeats;

    if (reservationRequestStatus.status === Status.NOT_STARTED) {
      return (
        <>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              overflowY: "auto"
            }}
          >
            {/*TODO exibir mais detalhes da viagem*/}
            <div style={{}}>
              <h2>
                Selecione{" "}
                {numberOfSeats > 1 ? "os passageiros" : "o passageiro"}
                {": "}
                <span style={{ whiteSpace: "nowrap" }}>
                  {selectedPaxIds.length} de {numberOfSeats}
                </span>
              </h2>
              {this.renderFavoritePassengers()}
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF", //TODO find a way to use the variable defined on body tag on the main App.css
              borderTop: `1px solid ${Color.ANTD_LIGHT_GREY_BORDER}`
            }}
          >
            <div
              onClick={this.onAddNewPassengerClicked}
              className="clickable add-new-passenger"
              style={{
                backgroundColor: "white",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                color: areAllNecessaryPaxSelected ? "#d7d8d4" : "",
                pointerEvents: areAllNecessaryPaxSelected ? "none" : "auto"
              }}
            >
              <Icon type="plus" />{" "}
              <h3
                style={{
                  marginBottom: 0,
                  marginLeft: "10px",
                  color: areAllNecessaryPaxSelected ? "#d7d8d4" : ""
                }}
              >
                Adicionar novo
              </h3>
            </div>
            <Title
              level={4}
              style={{ marginBottom: "0px", textAlign: "right" }}
            >
              Total:{" "}
              <span style={{ color: PRIMARY_COLOR }}>
                R$ {numberToPrice(price)}
              </span>
            </Title>
            <div
              className="steps-action"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "8px"
              }}
            >
              <Button
                type="danger"
                ghost
                onClick={this.onCancelReservationClick}
              >
                Cancelar reserva
              </Button>
              <Button
                type="primary"
                disabled={!areAllNecessaryPaxSelected}
                onClick={() => this.onNextStepClicked()}
              >
                Próximo
              </Button>
            </div>
          </div>
        </>
      );
    }

    if (reservationRequestStatus.status === Status.LOADING) {
      return <Loading loadingMessage={reservationRequestStatus.message} />;
    }

    if (reservationRequestStatus.status === Status.ERROR) {
      let title;
      if (currentAction === CurrentAction.ADD_PASSENGERS) {
        title = "Erro ao adicionar passageiros";
      } else if (currentAction === CurrentAction.CANCEL_RESERVATION) {
        title = "Erro ao cancelar reserva";
      }

      return (
        <Result
          status="warning"
          title={title}
          subTitle={reservationRequestStatus.message}
          extra={[
            <Button
              type="primary"
              key="tryAgain"
              onClick={() => {
                this.setState({
                  reservationRequestStatus: new RequestStatus(
                    Status.NOT_STARTED
                  )
                });
              }}
            >
              Entendi
            </Button>
          ]}
        />
      );
    }

    return null;
  }
}

Passengers.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired
};

export default Passengers;
