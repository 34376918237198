export function clearObject(object) {
  let propNames = Object.getOwnPropertyNames(object);
  for (let i = 0; i < propNames.length; i++) {
    let propName = propNames[i];
    if (
      object[propName] === undefined ||
      object[propName] === null ||
      object[propName] === ""
    ) {
      delete object[propName];
    }
  }
  return object;
}
