import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const RequestError = ({ errorTitle, errorMessage, onRetry }) => {
  return (
    <>
      <p>{errorTitle}</p>
      {errorMessage && <p>{errorMessage}</p>}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <Button onClick={onRetry}>Tentar de novo</Button>
      </div>
    </>
  );
};

RequestError.defaultProps = {
  errorMessage: null
};

RequestError.propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  onRetry: PropTypes.func.isRequired
};

export default RequestError;
