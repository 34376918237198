import React from "react";
import { Link } from "react-router-dom";
import { Col, Icon, Row, Typography } from "antd";
import lodash from "lodash";
import { InternalUrl } from "constants/urls";
import showModal from "../../util/modalHandler";
import { withRoles } from "../../hocs/withRoles";
import FeedbackModal from "../modals/feedback/FeedbackModal";

import "./styles.css";
import PropTypes from "prop-types";

const app_store = require("assets/img/appstore.png");
const fourlabs = require("assets/img/fourlabs.png");
const { Title } = Typography;

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className={"main-container"}>
          <Row
            style={{
              width: "80%"
            }}
          >
            <Col lg={8} sm={24}>
              <div className={"footer-column"}>
                <Title
                  style={{
                    textAlign: "left",
                    color: "white"
                  }}
                >
                  Fale conosco
                </Title>
                <ol className={"reset-list"}>
                  <li>
                    <a
                      target={"_blank"}
                      href={"https://www.facebook.com/app.vaidevan"}
                      rel={"noopener noreferrer"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Icon type="facebook" className={"social-icon"} />
                      <span className="menu-text-footer">Vai de Van</span>
                    </a>
                  </li>
                  <li>
                    <Icon
                      type="mail"
                      style={{
                        color: "white",
                        fontSize: "16px"
                      }}
                    />
                    <span>
                      <a
                        className="menu-text-footer"
                        href="mailto:contato@vaidevan.app"
                      >
                        contato@vaidevan.app
                      </a>
                    </span>
                  </li>
                </ol>
              </div>
            </Col>
            <Col lg={8} sm={24}>
              <div className={"footer-column"}>
                <Title
                  style={{
                    textAlign: "left",
                    color: "white"
                  }}
                >
                  Vai de van
                </Title>
                <ol className={"reset-list"}>
                  <li>
                    <span
                      className="menu-text-footer"
                      onClick={() => {
                        showModal(FeedbackModal, { roles: this.props.roles });
                      }}
                    >
                      Avaliar
                    </span>
                  </li>
                  <li>
                    <Link to={InternalUrl.SIGNUP} className="menu-text-footer">
                      Cadastre-se
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={InternalUrl.PRIVACY_POLICY}
                      className="menu-text-footer"
                    >
                      Políticas de privacidade
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={InternalUrl.TERMS_AND_CONDITIONS}
                      className="menu-text-footer"
                    >
                      Termos de uso
                    </Link>
                  </li>
                </ol>
              </div>
            </Col>
            <Col lg={8} sm={24}>
              <div className={"footer-column"}>
                <Title
                  style={{
                    textAlign: "left",
                    color: "white"
                  }}
                >
                  Baixe o App
                </Title>
                <a href="https://play.google.com/store/apps/details?id=app.vaidevanapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    style={{ width: "180px" }}
                    alt="Disponível no Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
                  />
                </a>
                <img
                  style={{ width: "160px" }}
                  alt="Em breve na App Store"
                  src={app_store}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className={"footer-fourlabs"}>
          <div className={"footer-fourlabs-content"}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://fourlabs.com.br/" target="_blank">
              <img
                style={{ height: "40px" }}
                alt="Desenvolvido e mantido por Fourlabs"
                src={fourlabs}
              />
            </a>
            <div className={"separator"}>
              <hr></hr>
            </div>
            <span>
              Desenvolvido e administrado por Fourlabs Soluções e
              Desenvolvimento de Sistemas - CNPJ 39.455.648/0001-98{" "}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  roles: PropTypes.object.isRequired
};

export default lodash.flow([withRoles])(Footer);
