import * as HomeType from "../actionTypes/home";
import { fetchTrips } from "../../queries/queries";

export const getTrips = (departure, arrival, departureDate, page, pageSize) => (
  dispatch,
  getState
) => {
  dispatch({
    type: HomeType.GET_TRIPS,
    payload: fetchTrips(departure, arrival, departureDate, page, pageSize)
  });
};

export const setDepartureCity = departureCity => ({
  type: HomeType.SET_DEPARTURE_CITY,
  payload: departureCity
});

export const clearDepartureCity = () => ({
  type: HomeType.CLEAR_DEPARTURE_CITY
});

export const setArrivalCity = arrivalCity => ({
  type: HomeType.SET_ARRIVAL_CITY,
  payload: arrivalCity
});

export const clearArrivalCity = () => ({
  type: HomeType.CLEAR_ARRIVAL_CITY
});

export const setDepartureDate = departureDate => ({
  type: HomeType.SET_DEPARTURE_DATE,
  payload: departureDate
});

export const setScrollPosition = scrollPosition => ({
  type: HomeType.SET_SCROLL_POSITION,
  payload: scrollPosition
});
