export function capitalizeFirstLetter(string) {
  if (!string) {
    return string;
  }

  // let newString = string;

  return string.charAt(0).toUpperCase() + string.slice(1);

  // return newString;
}
