import { MoipValidator } from "moip-sdk-js";

const { REACT_APP_PAGSEGURO_PUBLIC_KEY } = process.env;

export const validateNumber = number => {
  return MoipValidator.isValidNumber(number);
};

export const validateCvc = (number, cvc) => {
  return MoipValidator.isSecurityCodeValid(number, cvc);
};

export const validateExpiryDate = (month, year) => {
  return MoipValidator.isExpiryDateValid(month, year);
};

export const encryptCard = (holder, number, expMonth, expYear) => {
  const { PagSeguro } = window;

  return PagSeguro.encryptCard({
    publicKey: REACT_APP_PAGSEGURO_PUBLIC_KEY,
    holder,
    number,
    expMonth,
    expYear
  });
};
