import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import {
  Input,
  Modal,
  Form,
  Button,
  Icon,
  Checkbox,
  message,
  Divider
} from "antd";
import { login, oAuthLogin } from "queries/queries";
import { setToken } from "services/auth";
import { withStore } from "hocs/withStore";
import { setRememberMe } from "redux/actions/token";
import "./styles.css";
import EmailLink from "../../emailLink/EmailLink";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const FormFields = Object.freeze({
  EMAIL_OR_CPF: "emailOrCpf",
  PASSWORD: "password",
  REMEMBER_ME: "rememberMe"
});

class LoginModal extends Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    const { form } = this.props;
    const { validateFields, setFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const emailOrCpf = values[FormFields.EMAIL_OR_CPF];
        const password = values[FormFields.PASSWORD];

        this.setState({
          loading: true
        });

        login(emailOrCpf, password)
          .then(token => {
            this.onLoginSuccess(token);
          })
          .catch(error => {
            this.setState({
              loading: false
            });

            /* eslint-disable */
            const newFieldsValue = {};
            lodash.set(newFieldsValue, FormFields.PASSWORD, {value: '', errors: [new Error(error.message)]});
            /* eslint-enable */

            setFields(newFieldsValue);
          });
      }
    });
  };

  onLoginSuccess = token => {
    const {
      callback,
      form: { getFieldValue },
      dispatch
    } = this.props;

    const rememberMe = getFieldValue(FormFields.REMEMBER_ME);

    setToken(token, rememberMe);
    dispatch(setRememberMe(rememberMe));

    this.setState({
      loading: false
    });

    callback();
  };

  render() {
    const { loading } = this.state;
    const { visible, callback, form, storeState } = this.props;
    const { getFieldDecorator } = form;

    const {
      userInfoReducer: { rememberMe }
    } = storeState;

    return (
      <Modal
        title={
          <span className="login-form-header">
            Por favor, faça login e tente novamente
          </span>
        }
        visible={visible}
        centered
        onCancel={() => {
          callback();
        }}
        footer={null}
        className={"container"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <Form onSubmit={this.handleSubmit} className={"login-form"}>
            <Form.Item>
              {getFieldDecorator(FormFields.EMAIL_OR_CPF, {
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Por favor, digite seu email ou seu CPF."
                  }
                ]
              })(
                <Input
                  size={"large"}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email ou CPF"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.PASSWORD, {
                initialValue: "",
                rules: [
                  { required: true, message: "Por favor, digite sua senha." }
                ]
              })(
                <Input.Password
                  size={"large"}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Senha"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.REMEMBER_ME, {
                valuePropName: "checked",
                initialValue: rememberMe
              })(<Checkbox>Lembre de mim</Checkbox>)}
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={"login-form-button"}
              loading={loading}
              size={"large"}
            >
              Entrar
            </Button>
          </Form>
          <div className={"divider-container"}>
            <Divider>ou</Divider>
          </div>
          <GoogleOAuthProvider clientId="185043139350-kvldrceg6u3iqq951relc9r7a2tjhtol.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={credentialResponse => {
                oAuthLogin(credentialResponse.credential)
                  .then(token => {
                    this.onLoginSuccess(token);
                    // const rememberMe = getFieldValue(FormFields.REMEMBER_ME);
                    // setToken(token, rememberMe);
                  })
                  .catch(error => {
                    //TODO
                    message.error(
                      `Erro ao tentar fazer login: ${error.message}`
                    );
                    // debugger;
                  });
              }}
              onError={() => {
                Modal.error({
                  title: "Não foi possível fazer o login social",
                  content: (
                    <p>
                      Por favor, tente novamente. Se o problema persistir, envie
                      um email para{" "}
                      <EmailLink subject="Erro ao efetuar login social" />{" "}
                      informando que há um erro ao tentar efetuar o login
                      social.
                    </p>
                  )
                });
              }}
              width={300}
            />
          </GoogleOAuthProvider>
        </div>
      </Modal>
    );
  }
}

LoginModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  storeState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool.isRequired
};

export default lodash.flow([withStore, Form.create({ name: "login-modal" })])(
  LoginModal
);
