import * as ConfigsType from "../actionTypes/configs";

const DEFAULT_NOTIFICATION_INTERVAL = 60000;
const DEFAULT_GOOGLE_LOGIN_ENABLED = false;
const DEFAULT_ROUTE_SEARCH_STYLE_ENABLED = false;

const initialState = {
  notificationInterval: DEFAULT_NOTIFICATION_INTERVAL,
  oAuthLoginEnabled: DEFAULT_GOOGLE_LOGIN_ENABLED,
  routeSearchStyleEnabled: DEFAULT_ROUTE_SEARCH_STYLE_ENABLED
};

const configs = (state = initialState, { type, payload }) => {
  switch (type) {
    case ConfigsType.RESET_NOTIFICATION_INTERVAL_TO_DEFAULT: {
      return {
        ...state,
        notificationInterval: DEFAULT_NOTIFICATION_INTERVAL
      };
    }
    case ConfigsType.SET_NOTIFICATION_INTERVAL: {
      return {
        ...state,
        notificationInterval: payload
      };
    }
    case ConfigsType.GET_GOOGLE_LOGIN_ENABLED_FULFILLED: {
      return {
        ...state,
        oAuthLoginEnabled: payload.value
      };
    }
    case ConfigsType.GET_ROUTE_SEARCH_STYLE_ENABLED_FULFILLED: {
      return {
        ...state,
        routeSearchStyleEnabled: payload.value
      };
    }
    default:
      return state;
  }
};

export default configs;
