const PREFIX = "home/";
export const GET_TRIPS = `${PREFIX}GET_TRIPS`;
export const GET_TRIPS_PENDING = `${GET_TRIPS}_PENDING`;
export const GET_TRIPS_FULFILLED = `${GET_TRIPS}_FULFILLED`;
export const GET_TRIPS_REJECTED = `${GET_TRIPS}_REJECTED`;
export const SET_DEPARTURE_CITY = `${PREFIX}SET_DEPARTURE_CITY`;
export const CLEAR_DEPARTURE_CITY = `${PREFIX}CLEAR_DEPARTURE_CITY`;
export const SET_ARRIVAL_CITY = `${PREFIX}SET_ARRIVAL_CITY`;
export const CLEAR_ARRIVAL_CITY = `${PREFIX}CLEAR_ARRIVAL_CITY`;
export const SET_DEPARTURE_DATE = `${PREFIX}SET_DEPARTURE_DATE`;
export const SET_SCROLL_POSITION = `${PREFIX}SET_SCROLL_POSITION`;
