export const ReservationStatus = Object.freeze({
  REQUESTED: "REQUESTED", // - só chegou o pedido com um número x de lugares
  CONFIRMED: "CONFIRMED", // - o pagamento foi confirmado e o usuário não precisa fazer mais nada
  READY_FOR_PAYMENT: "READY_FOR_PAYMENT", // - aguardando o usuário informar um meio de pagamento
  PENDING_PAYMENT_CONFIRMATION: "PENDING_PAYMENT_CONFIRMATION", // - aguardando o pagamento ser aprovado
  READY_FOR_BOARDING_POINT: "READY_FOR_BOARDING_POINT", // - aguardando o ponto de embarque ser informado
  PAYMENT_FAILED: "PAYMENT_FAILED", // - o pagamento foi recusado e o usuário precisa efetuar o pagamento novamente
  CANCELLED: "CANCELLED", // - reserva cancelada pelo usuario
  EXPIRED: "EXPIRED" // - esgotou o tempo para que o usuario tome uma ação
});
