import * as TripCalendarType from "../actionTypes/tripCalendar";
import { fetchTrips } from "../../queries/queries";

export const getTrips = (departure, arrival, departureDate) => (
  dispatch,
  getState
) => {
  dispatch({
    type: TripCalendarType.GET_TRIPS,
    payload: fetchTrips(departure, arrival, departureDate, 0, 100, true)
  });
};

export const setSelectedDate = selectedDate => ({
  type: TripCalendarType.SET_SELECTED_DATE,
  payload: selectedDate
});

export const setScrollPosition = scrollPosition => ({
  type: TripCalendarType.SET_SCROLL_POSITION,
  payload: scrollPosition
});
