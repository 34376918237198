import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import Sound from "react-sound";
import WidthListener from "components/widthListener";
import Routes from "./components/routes";
import HeaderAndSidebar from "./components/headerAndSidebar/HeaderAndSidebar";
import history from "./services/history";
import { getToken, parseToken } from "./services/auth";
import { updateNotifications } from "./redux/actions/notifications";
import { saveDeviceInfoIfNeeded } from "./services/device";
import notificationSound from "assets/sounds/notification-sound.wav";
import { getCookie } from "./util/cookieUtils";
import { setRememberMe, updateToken } from "./redux/actions/token";
import { CookieKey } from "./constants/cookieKey";
import { stringToBoolean } from "./util/formatUtils";
import {
  getOAuthLoginEnabledStatus,
  getRouteSearchStyleEnabledStatus
} from "./redux/actions/configs";
import "./App.css";
import { setIsDesktop } from "./redux/actions/events";
import "react-loading-skeleton/dist/skeleton.css";

const SIDEBAR_DOCKED_BREAKPOINT_WIDTH = 768;

class App extends Component {
  state = {
    notificationSoundStatus: Sound.status.STOPPED
  };

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(`version: ${process.env.REACT_APP_VERSION}`);

    const {
      updateToken,
      setRememberMe,
      getOAuthLoginEnabledStatus,
      getRouteSearchStyleEnabledStatus
    } = this.props;

    const parsedToken = parseToken(getToken());
    const rememberMeCookie = stringToBoolean(getCookie(CookieKey.REMEMBER_ME));

    updateToken(parsedToken);
    setRememberMe(rememberMeCookie);

    getOAuthLoginEnabledStatus();
    setTimeout(() => {
      getRouteSearchStyleEnabledStatus();
    }, 100);

    saveDeviceInfoIfNeeded();
  }

  onWidthChange = width => {
    const { isDesktop, setIsDesktop } = this.props;
    const newIsDesktop = width >= SIDEBAR_DOCKED_BREAKPOINT_WIDTH;

    if (newIsDesktop !== isDesktop) {
      setIsDesktop(newIsDesktop);
    }
  };

  render() {
    const { notificationSoundStatus } = this.state;
    const { initialized, isDesktop } = this.props;

    if (!initialized) {
      return null;
    }

    return (
      <>
        <Helmet>
          <title>Vai de Van</title>
        </Helmet>
        <WidthListener onWidthChange={this.onWidthChange} />
        <Sound
          url={notificationSound}
          playStatus={notificationSoundStatus}
          onFinishedPlaying={this.stopNotificationSound}
        />
        <Router history={history}>
          <HeaderAndSidebar isDesktop={isDesktop}>
            <Routes />
          </HeaderAndSidebar>
        </Router>
      </>
    );
  }
}

App.defaultProps = {
  roles: undefined
};

App.propTypes = {
  updateNotifications: PropTypes.func.isRequired,
  chatNotifications: PropTypes.array.isRequired,
  defaultNotifications: PropTypes.array.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  updateToken: PropTypes.func.isRequired,
  setRememberMe: PropTypes.func.isRequired,
  getOAuthLoginEnabledStatus: PropTypes.func.isRequired,
  setIsDesktop: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  getRouteSearchStyleEnabledStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    chatNotifications: state.notificationsReducer.chatNotifications,
    defaultNotifications: state.notificationsReducer.defaultNotifications,
    roles: state.userInfoReducer.roles,
    initialized: state.userInfoReducer.initialized,
    isDesktop: state.eventsReducer.isDesktop
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateNotifications,
      updateToken,
      setRememberMe,
      getOAuthLoginEnabledStatus,
      setIsDesktop,
      getRouteSearchStyleEnabledStatus
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
