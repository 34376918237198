import {
  osName,
  osVersion,
  browserName,
  browserVersion,
  isMobile
} from "react-device-detect";
import publicIp from "public-ip";
import { saveDeviceInfo } from "queries/queries";
import { getCookie, storeCookie } from "../util/cookieUtils";
import { CookieKey } from "../constants/cookieKey";

export const saveDeviceInfoIfNeeded = () => {
  if (!isDeviceInfoSaved()) {
    publicIp
      .v4()
      .then(ipAddress => {
        saveDeviceInfo(
          osName,
          osVersion,
          browserName,
          browserVersion,
          isMobile,
          ipAddress
        )
          .then(() => {
            setDeviceInfoSaved();
          })
          .catch(() => {
            //TODO
          });
      })
      .catch(() => {
        //TODO
      });
  }
};

const setDeviceInfoSaved = () => {
  storeCookie(CookieKey.DEVICE_INFO_SAVED_KEY, true, { maxAge: 31536000 }); //this is 1 year
};

const isDeviceInfoSaved = () => {
  return !!getCookie(CookieKey.DEVICE_INFO_SAVED_KEY);
};
