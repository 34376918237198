import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import PropTypes from "prop-types";
import "./styles.css";

export class MapContainer extends Component {
  componentDidMount() {
    /*global google*/

    var TOUR_GMAP = new google.maps.Map(document.getElementById("map-canvas"), {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    });

    TOUR_GMAP.setOptions({ gestureHandling: "none", keyboardShortcuts: false });

    var DIRECTIONS_RENDER = new google.maps.DirectionsRenderer({
      map: TOUR_GMAP
    });

    DIRECTIONS_RENDER.setOptions({
      markerOptions: {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 7,
          fillColor: "#8333ff",
          fillOpacity: 1,
          strokeWeight: 0
        },
        clickable: false
      },
      preserveViewport: false
    });
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: { placeId: this.props.departure },
        destination: { placeId: this.props.arrival },
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: null
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          DIRECTIONS_RENDER.setDirections(result);
        }
      }
    );
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div id={"map-canvas"} className="map-container" />;
  }
}

MapContainer.propTypes = {
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC_hRLfgN_hSAtY7FRpQB7alBjVeVCWIvk",
  language: "PT"
})(MapContainer);
