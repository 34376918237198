import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Typography } from "antd";
import TermsAndConditionsContent from "../../components/termsAndConditionsContent";
import "./styles.css";

const { Title } = Typography;

class TermsAndConditions extends Component {
  state = {
    shouldRender: false //to avoid first render of the page with the scroll in the bottom
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        shouldRender: true
      });
    }, 50);
  }

  render() {
    const { shouldRender } = this.state;
    return (
      <>
        <Helmet>
          <title>Termos e condições | Vai de Van</title>
        </Helmet>
        {(() => {
          if (shouldRender) {
            return (
              <div className="main-container">
                <div className="max-width-container">
                  <Title level={3}>Termos de Uso</Title>
                  <div>
                    <TermsAndConditionsContent />
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })()}
      </>
    );
  }
}

TermsAndConditions.propTypes = {};

export default TermsAndConditions;
