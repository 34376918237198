import * as ReservationPaymentType from "../actionTypes/reservationPayment";

export const clearCardInfo = () => ({
  type: ReservationPaymentType.CLEAR_CARD_INFO
});

export const setCardNumber = cardNumber => ({
  type: ReservationPaymentType.SET_CARD_NUMBER,
  payload: cardNumber
});

export const setCvc = cvc => ({
  type: ReservationPaymentType.SET_CVC,
  payload: cvc
});

export const setExpirationDate = expirationDate => ({
  type: ReservationPaymentType.SET_EXPIRATION_DATE,
  payload: expirationDate
});

export const setHolderName = holderName => ({
  type: ReservationPaymentType.SET_HOLDER_NAME,
  payload: holderName
});

export const setCpf = cpf => ({
  type: ReservationPaymentType.SET_CPF,
  payload: cpf
});
