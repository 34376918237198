//TODO finish implementation
export const CreditCardBrands = Object.freeze({
  MASTERCARD: {
    brand: "MASTERCARD",
    cardLibAcceptedBrand: "mastercard",
    img: require("assets/img/creditcard/logo-mastercard-256.png")
  },
  VISA: {
    brand: "VISA",
    cardLibAcceptedBrand: "visa",
    img: require("assets/img/creditcard/logo-visa-256.png")
  },
  AMEX: {
    brand: "AMEX",
    cardLibAcceptedBrand: "amex",
    img: require("assets/img/creditcard/logo-american-express-256.png")
  },
  ELO: {
    brand: "ELO",
    cardLibAcceptedBrand: "elo",
    img: require("assets/img/creditcard/logo-elo-256.png")
  },
  DINERS: {
    brand: "DINERS",
    cardLibAcceptedBrand: "dinersclub",
    img: require("assets/img/creditcard/logo-diners-club-256.png")
  },
  HIPER: {
    brand: "HIPER",
    cardLibAcceptedBrand: "hipercard",
    img: require("assets/img/creditcard/logo-hiper.png")
  },
  HIPERCARD: {
    brand: "HIPERCARD",
    cardLibAcceptedBrand: "hipercard",
    img: require("assets/img/creditcard/logo-hipercard-256.png")
  }
});
