import * as UserInfoTypes from "../actionTypes/userInfo";
import * as TokenTypes from "../actionTypes/token";

const getInitialState = () => ({
  initialized: false,
  roles: [],
  paymentMethods: [],
  userName: "",
  phoneNumber: "",
  rememberMe: false
});

const initialState = getInitialState();

const userInfo = (state = initialState, { type, payload }) => {
  switch (type) {
    case TokenTypes.UPDATE_TOKEN: {
      const {
        paymentMethods,
        roles,
        sub: userName,
        user: { phone: phoneNumber }
      } = payload;
      return {
        ...state,
        initialized: true,
        roles,
        paymentMethods,
        userName,
        phoneNumber
      };
    }
    case TokenTypes.CLEAR_TOKEN: {
      return {
        ...initialState,
        initialized: true
      };
    }
    case TokenTypes.SET_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: payload
      };
    }
    case UserInfoTypes.SET_PHONE: {
      return {
        ...state,
        phoneNumber: payload
      };
    }
    default:
      return state;
  }
};

export default userInfo;
