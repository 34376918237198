import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Modal, Button, Form, Input, Icon, Checkbox, message } from "antd";
import { withStore } from "../../../hocs/withStore";
import "./styles.css";
import { validate as validateCpf } from "cpf-check";
import MaskedInput from "../../maskedInput";
import { savePassenger } from "../../../queries/queries";
import StringMask from "string-mask";

const FormFields = Object.freeze({
  NAME: "name",
  CPF: "cpf",
  PHONE: "phone",
  REMEMBER: "remember"
});

class CreateNewPassengerModal extends Component {
  state = {
    loading: false
  };

  componentWillUnmount() {
    Modal.destroyAll();
  }

  continueSavingPassenger = (name, cpf, phone, remember) => {
    const { callback } = this.props;

    this.setState({
      loading: true
    });

    savePassenger({ name, cpf, phone, remember })
      .then(passenger => {
        callback(passenger);
      })
      .catch(error => {
        this.setState({
          loading: false
        });

        message.error(`Erro ao salvar o passageiro: ${error.message}`);
      });
  };

  handleSubmit = e => {
    const { form, favoritePassengers } = this.props;
    const { validateFields } = form;
    const continueSavingPassengerRef = this.continueSavingPassenger;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const name = values[FormFields.NAME];
        const cpf = values[FormFields.CPF];
        const phone = values[FormFields.PHONE];
        const remember = values[FormFields.REMEMBER];

        const paxSameCpf = favoritePassengers.find(pax => pax.cpf === cpf);

        if (paxSameCpf) {
          Modal.confirm({
            title: "CPF já cadastrado",
            content: (
              <div>
                <p>
                  Ao continuar, esse passageiro irá substituir o passageiro
                  abaixo:
                </p>
                <span>{paxSameCpf.name}</span>
                <br />
                <span>
                  {StringMask.apply(paxSameCpf.phone, "(00) 0 0000-0000")}
                </span>
                <br />
                <br />
                <span>Deseja continuar?</span>
              </div>
            ),
            centered: true,
            okText: "Sim",
            cancelText: "Não",
            onOk() {
              continueSavingPassengerRef(name, cpf, phone, remember);
            }
          });
        } else {
          continueSavingPassengerRef(name, cpf, phone, remember);
        }
      }
    });
  };

  render() {
    const { visible, callback, form } = this.props;
    const { loading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title={
          <span className="new-passenger-form-header">Novo passageiro</span>
        }
        visible={visible}
        centered
        footer={[
          <Button key="cancel" onClick={() => callback()}>
            Cancelar
          </Button>,
          <Button
            key="save"
            type="primary"
            htmlType="submit"
            form="new-passenger-form"
            loading={loading}
          >
            Salvar
          </Button>
        ]}
        className={"new-passenger-modal-container"}
        closable={false}
      >
        <Form id="new-passenger-form" onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator(FormFields.NAME, {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "Por favor, digite o nome do passageiro."
                }
              ]
            })(
              <Input
                size={"large"}
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Nome"
                autoFocus
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(FormFields.CPF, {
              initialValue: "",
              rules: [
                {
                  validator: (rule, value, callback) => {
                    if (!value || value.length === 0) {
                      callback("Por favor, digite o CPF do passageiro.");
                    } else if (!validateCpf(value)) {
                      callback("Digite um CPF válido.");
                    }

                    callback();
                  }
                }
              ]
            })(
              <MaskedInput
                mask="111.111.111-11"
                placeholder="CPF"
                size="large"
                prefix={<Icon type="idcard" className="form-icon" />}
                inputMode="numeric"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(FormFields.PHONE, {
              initialValue: "",
              rules: [
                {
                  validator: (rule, value, callback) => {
                    if (!value || value.length === 0) {
                      callback("Por favor, digite o numero do seu celular.");
                    } else if (value.length < 11) {
                      callback("Digite um número de celular válido.");
                    }

                    callback();
                  }
                }
              ]
            })(
              <MaskedInput
                mask="11 1 1111-1111"
                placeholder="Celular"
                size="large"
                prefix={<Icon type="phone" className="form-icon" />}
                inputMode="numeric"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(FormFields.REMEMBER, {
              valuePropName: "checked",
              initialValue: false
            })(<Checkbox>Lembrar passageiro</Checkbox>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

CreateNewPassengerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  storeState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
  favoritePassengers: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default lodash.flow([
  Form.create({ name: "new-passenger-modal" }),
  withStore
])(CreateNewPassengerModal);
