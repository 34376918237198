import React, { Component } from "react";
import PropTypes from "prop-types";
import { AutoComplete, Input } from "antd";
import { debounce } from "throttle-debounce";
import { fetchAutoCompleteCities } from "queries/queries";
import { mdiMapMarkerOutline } from "@mdi/js";
import Icon from "@mdi/react";
import "./styles.css";

const FETCH_CITIES_DELAY = 500;

class CityAutoComplete extends Component {
  inputRef = React.createRef();

  state = {
    dataSource: []
  };

  onCitySelect = value => {
    const { dataSource } = this.state;
    const { onCitySelect } = this.props;

    const selectedCity = dataSource.find(
      city => city.value === parseInt(value, 10)
    );

    this.setState({
      dataSource: []
    });
    onCitySelect(selectedCity);
  };

  fetchCities = debounce(FETCH_CITIES_DELAY, false, text => {
    fetchAutoCompleteCities(text).then(cities => {
      const newDataSource = cities.map(city => {
        return {
          value: city.id,
          text: `${city.name} - ${city.state}`
        };
      });
      this.setState({
        dataSource: newDataSource
      });
    });
  });

  onSearch = searchText => {
    if (searchText.length < 2) {
      this.setState({
        dataSource: []
      });
    } else {
      this.fetchCities(searchText);
    }
  };

  render() {
    const { dataSource } = this.state;

    const { placeholder } = this.props;

    return (
      <AutoComplete
        dataSource={dataSource}
        onSelect={this.onCitySelect}
        onSearch={this.onSearch}
        size="small"
        style={{ width: "100%" }}
      >
        <Input
          ref={ref => {
            this.inputRef = ref;
          }}
          style={{ height: "40px", backgroundColor: "white" }}
          placeholder={placeholder}
          suffix={
            <Icon
              path={mdiMapMarkerOutline}
              size={0.8}
              color={"rgba(0,0,0,.25)"}
              className="icon"
            />
          }
        />
      </AutoComplete>
    );
  }
}

CityAutoComplete.defaultProps = {
  placeholder: ""
};

CityAutoComplete.propTypes = {
  placeholder: PropTypes.string,
  onCitySelect: PropTypes.func.isRequired
};

export default CityAutoComplete;
