import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Button, Modal, Result } from "antd";
import { fetchTripDetails, getReservation } from "queries/queries";
import { getParamsFromUrl } from "util/routeUtils";
import Loading from "components/loading";
import { InternalUrl } from "constants/urls";
import { ReservationStatus } from "constants/reservationStatus";
import { TripStatus } from "constants/TripStatus";
import RequestStatus, { Status } from "../../model/RequestStatus";
import { Route, Switch } from "react-router-dom";
import SubRoutes from "./subRoutes/subRoutes";
import Passengers from "./subRoutes/passengers";
import Payment from "./subRoutes/payment";
import Confirmed from "./subRoutes/confirmed";
import Cancelled from "./subRoutes/cancelled";
import BoardingPoint from "./subRoutes/boardingPoint";
import ReservationSteps from "../../components/reservationSteps/ReservationSteps";
import "./styles.css";
import { remountOnUrlParamsChange } from "../../hocs/remountOnUrlParamsChange";
import ReservationCountdown from "../../components/reservationCountdown";
import {
  backendDateTimeStringToMoment,
  currentTimeMoment,
  timeDiff
} from "../../util/formatUtils";

class Reservation extends Component {
  reservationDeadlineCheckerInterval;

  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    reservationAllowanceDeadline: undefined
  };

  componentDidMount() {
    const { history } = this.props;
    const { reservationCode } = getParamsFromUrl(this.props);

    //prevent user from choosing which subroute to be accessed by typing an URL
    history.replace(
      InternalUrl.RESERVATION.replace(":reservationCode", reservationCode)
    );
    this.getReservation();
  }

  componentWillUnmount() {
    clearInterval(this.reservationDeadlineCheckerInterval);
  }

  getReservation = () => {
    const { reservationCode } = getParamsFromUrl(this.props);
    const {
      history,
      match: { url }
    } = this.props;

    this.setState({
      requestStatus: new RequestStatus(Status.LOADING)
    });

    getReservation(reservationCode)
      .then(reservation => {
        const { tripId } = reservation;

        fetchTripDetails(tripId).then(tripDetails => {
          const { reservationAllowanceDeadline } = tripDetails;

          this.setState({
            requestStatus: new RequestStatus(Status.SUCCESS),
            reservationAllowanceDeadline
          });

          const {
            status: reservationStatus,
            passengers,
            boardingPoint
          } = reservation;
          const { status: tripStatus } = tripDetails;

          if (tripStatus === TripStatus.CANCELLED) {
            history.replace(`${url}${SubRoutes.CANCELLED}`, {
              reservation,
              tripDetails
            });
          } else if (
            (reservationStatus === ReservationStatus.EXPIRED &&
              passengers.length === 0) ||
            reservationStatus === ReservationStatus.REQUESTED
          ) {
            history.replace(`${url}${SubRoutes.PASSENGERS}`, {
              reservation,
              tripDetails
            });
          } else if (
            (reservationStatus === ReservationStatus.EXPIRED &&
              boardingPoint == null) ||
            reservationStatus === ReservationStatus.READY_FOR_BOARDING_POINT
          ) {
            history.replace(`${url}${SubRoutes.BOARDING_POINT}`, {
              reservation,
              tripDetails
            });
          } else if (
            (reservationStatus === ReservationStatus.EXPIRED &&
              passengers.length > 0) ||
            reservationStatus === ReservationStatus.READY_FOR_PAYMENT ||
            reservationStatus ===
              ReservationStatus.PENDING_PAYMENT_CONFIRMATION ||
            reservationStatus === ReservationStatus.PAYMENT_FAILED
          ) {
            history.replace(`${url}${SubRoutes.PAYMENT}`, {
              reservation,
              tripDetails
            });
          } else if (reservationStatus === ReservationStatus.CONFIRMED) {
            history.replace(`${url}${SubRoutes.CONFIRMED}`, {
              reservation,
              tripDetails
            });
          } else if (reservationStatus === ReservationStatus.CANCELLED) {
            history.replace(`${url}${SubRoutes.CANCELLED}`, {
              reservation,
              tripDetails
            });
          }

          this.setReservationDeadlineCheckerInterval(
            reservationAllowanceDeadline,
            reservationStatus
          );
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  setReservationDeadlineCheckerInterval = (
    reservationAllowanceDeadline,
    reservationStatus
  ) => {
    const { history } = this.props;

    this.reservationDeadlineCheckerInterval = setInterval(() => {
      const timeDiffDuration = timeDiff(
        currentTimeMoment(),
        backendDateTimeStringToMoment(reservationAllowanceDeadline)
      );

      if (
        timeDiffDuration.asMilliseconds() < 0 &&
        ![ReservationStatus.CONFIRMED, ReservationStatus.CANCELLED].includes(
          reservationStatus
        )
      ) {
        clearInterval(this.reservationDeadlineCheckerInterval);
        Modal.info({
          title: "Aviso",
          content: `Esta reserva não pode ser concluída pois o prazo para criação de reservas desta viagem foi encerrado.`,
          centered: true,
          onOk: () => {
            history.goBack();
          }
        });
      }
    }, 1000);
  };

  render() {
    const { requestStatus } = this.state;
    const {
      history,
      match: { path, url },
      location: { pathname }
    } = this.props;

    const currentSubPage = pathname.replace(url, "");
    let currentStep = null;
    if (currentSubPage === SubRoutes.PASSENGERS) {
      currentStep = 1;
    } else if (currentSubPage === SubRoutes.BOARDING_POINT) {
      currentStep = 2;
    } else if (currentSubPage === SubRoutes.PAYMENT) {
      currentStep = 3;
    }

    return (
      <>
        <Helmet>
          <title>Reserva de viagem | Vai de Van</title>
        </Helmet>
        <div className="main-container" style={{ height: "100%" }}>
          <div className="reservation-container">
            {(() => {
              if (requestStatus.status === Status.LOADING) {
                return <Loading />;
              }

              if (requestStatus.status === Status.ERROR) {
                return (
                  <Result
                    status="warning"
                    title={"Erro ao obter os detalhes da reserva"}
                    subTitle={requestStatus.message}
                    extra={[
                      <Button
                        type="primary"
                        key="goBack"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Entendi
                      </Button>
                    ]}
                  />
                );
              }

              if (requestStatus.status === Status.SUCCESS) {
                const { reservationAllowanceDeadline } = this.state;

                return (
                  <>
                    {![SubRoutes.CONFIRMED, SubRoutes.CANCELLED].includes(
                      currentSubPage
                    ) && (
                      <ReservationCountdown
                        reservationAllowanceDeadline={
                          reservationAllowanceDeadline
                        }
                      />
                    )}
                    {currentStep !== null && (
                      <div style={{ marginBottom: "20px" }}>
                        <ReservationSteps currentStep={currentStep} />
                      </div>
                    )}
                    <Switch>
                      <Route
                        path={`${path}${SubRoutes.PASSENGERS}`}
                        component={Passengers}
                      />
                      <Route
                        path={`${path}${SubRoutes.PAYMENT}`}
                        component={Payment}
                      />
                      <Route
                        path={`${path}${SubRoutes.BOARDING_POINT}`}
                        component={BoardingPoint}
                      />
                      <Route
                        path={`${path}${SubRoutes.CONFIRMED}`}
                        component={Confirmed}
                      />
                      <Route
                        path={`${path}${SubRoutes.CANCELLED}`}
                        component={Cancelled}
                      />
                    </Switch>
                  </>
                );
              }

              return null;
            })()}
          </div>
        </div>
      </>
    );
  }
}

Reservation.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired
};

export default remountOnUrlParamsChange(Reservation);
