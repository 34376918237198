import axios from "axios";
import { getToken } from "services/auth";
import { ExternalUrl } from "constants/urls";

const api = axios.create({
  baseURL: ExternalUrl.BACKEND_URL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
