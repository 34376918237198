import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";

import eventsReducer from "./reducers/events";
import notificationsReducer from "./reducers/notifications";
import userInfoReducer from "./reducers/userInfo";
import homeReducer from "./reducers/home";
import configsReducer from "./reducers/configs";
import themeReducer from "./reducers/theme";
import reservationPaymentReducer from "./reducers/reservationPayment";
import tripCalendarReducer from "./reducers/tripCalendar";

function configureStore() {
  return createStore(
    combineReducers({
      eventsReducer,
      notificationsReducer,
      userInfoReducer,
      homeReducer,
      configsReducer,
      themeReducer,
      reservationPaymentReducer,
      tripCalendarReducer
    }),
    composeWithDevTools(applyMiddleware(thunk, promise))
  );
}

export default configureStore();
