import * as EventsType from "../actionTypes/events";

export const setIsDesktop = isDesktop => ({
  type: EventsType.SET_IS_DESKTOP,
  payload: isDesktop
});

export const setScrollPosition = scrollPosition => ({
  type: EventsType.SET_SCROLL_POSITION,
  payload: scrollPosition
});

export const restoreScrollPosition = scrollPosition => ({
  type: EventsType.RESTORE_SCROLL_POSITION,
  payload: scrollPosition
});
