import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  backendDateTimeStringToMoment,
  currentTimeMoment,
  numberToMinimumDigits,
  timeDiff
} from "../../util/formatUtils";

class CountdownText extends Component {
  tickInterval;

  state = {
    timeRemaining: "", //format: 12:34
    shouldDisplayTime: false,
    onTimeReachedCalled: false
  };

  componentDidMount() {
    this.calculateAndSetTimeRemaining();
    this.tickInterval = setInterval(this.calculateAndSetTimeRemaining, 100);
  }

  componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dateTimeString } = this.props;
    const { dateTimeString: prevDateTimeString } = prevProps;

    if (dateTimeString !== prevDateTimeString) {
      this.setState({
        onTimeReachedCalled: false
      });
    }
  }

  calculateAndSetTimeRemaining = () => {
    const { dateTimeString, displayNegativeTime, onTimeReached } = this.props;
    const { onTimeReachedCalled } = this.state;

    if (dateTimeString !== null) {
      const duration = timeDiff(
        currentTimeMoment(),
        backendDateTimeStringToMoment(dateTimeString)
      );

      const minutes = numberToMinimumDigits(Math.trunc(duration.asMinutes()));
      const seconds = numberToMinimumDigits(duration.seconds());

      let shouldDisplayTime = true;
      if (!displayNegativeTime && duration.asMilliseconds() < 0) {
        shouldDisplayTime = false;
      }

      if (!onTimeReachedCalled && duration.asMilliseconds() < 0) {
        onTimeReached();
        this.setState({
          onTimeReachedCalled: true
        });
      }

      this.setState({
        timeRemaining: `${minutes}:${seconds}`,
        shouldDisplayTime
      });
    }
  };

  render() {
    const { timeRemaining, shouldDisplayTime } = this.state;
    if (shouldDisplayTime) return <span>{timeRemaining}</span>;

    return null;
  }
}

CountdownText.defaultProps = {
  dateTimeString: null,
  displayNegativeTime: false,
  onTimeReached: () => {}
};

CountdownText.propTypes = {
  dateTimeString: PropTypes.string,
  displayNegativeTime: PropTypes.bool,
  onTimeReached: PropTypes.func
};

export default CountdownText;
