import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  Form,
  Modal,
  Icon,
  Input,
  Button,
  Checkbox,
  Divider,
  message
} from "antd";
import MaskedInput from "components/maskedInput";
import TermsAndConditionsContent from "components/termsAndConditionsContent";
import { validate as validateCpf } from "cpf-check";
import { oAuthLogin, signup } from "queries/queries";
import { setToken } from "services/auth";
import { InternalUrl } from "constants/urls";
import { Color } from "constants/colors";
import { validatePassword } from "util/validationUtils";
import { validateBirthday } from "../../util/validationUtils";
import { momentToBackendDateString } from "../../util/formatUtils";
import "./styles.css";
import showModal from "../../util/modalHandler";
import RememberMeModal from "../../components/modals/rememberMe/RememberMeModal";
import { getCookie } from "../../util/cookieUtils";
import { CookieKey } from "../../constants/cookieKey";
import { GoogleLogin } from "@react-oauth/google";
import EmailLink from "../../components/emailLink/EmailLink";
import { getParamsFromPrevLocation } from "../../util/routeUtils";
import lodash from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PrivacyPolicy from "pages/privacyPolicy/PrivacyPolicy";

const logo = require("assets/img/logo.png");

const { INPUT_ICON_COLOR } = Color;

const UNMASKED_BIRTH_DATE_FORMAT = "DDMMYYYY";

const FormFields = Object.freeze({
  NAME: "name",
  EMAIL: "email",
  PHONE: "phone",
  DOCUMENT_NUMBER: "documentNumber",
  BIRTH: "birth",
  PASSWORD: "password",
  TERMS: "acceptedTerms",
  F2F_PAYMENT: "requestF2FPayment"
});

class Signup extends Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    const { form, history } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const userInfo = {
          name: values[FormFields.NAME],
          cpf: values[FormFields.DOCUMENT_NUMBER],
          birth: momentToBackendDateString(
            moment(values[FormFields.BIRTH], UNMASKED_BIRTH_DATE_FORMAT)
          ),
          phone: values[FormFields.PHONE],
          username: values[FormFields.EMAIL],
          password: values[FormFields.PASSWORD],
          acceptedTerms: values[FormFields.TERMS]
          // requestF2FPayment: values[FormFields.F2F_PAYMENT]
        };
        const referralCode = getCookie(CookieKey.REFERRAL_CODE);

        this.setState({
          loading: true
        });

        signup(userInfo, referralCode)
          .then(token => {
            setToken(token, false);

            this.setState({
              loading: false
            });

            //TODO use parameters to send user to the correct destination after signup
            history.push(InternalUrl.HOME);

            showModal(RememberMeModal);
          })
          .catch(error => {
            this.setState({
              loading: false
            });

            //TODO display errors from backend under the field related
            Modal.error({
              title: "Não foi possível criar a sua conta",
              content: error.message,
              centered: true
            });
          });
      }
    });
  };

  onLoginSuccess = token => {
    const { history } = this.props;

    setToken(token);

    //TODO use parameters to send user to the correct destination after login
    const prevLocationState = getParamsFromPrevLocation(this.props);
    const prevLocation = lodash.get(
      prevLocationState,
      "from.pathname",
      InternalUrl.HOME
    );
    history.replace(prevLocation);
  };

  render() {
    const { loading } = this.state;
    const { history, form, oAuthLoginEnabled } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <Helmet>
          <title>Cadastre-se | Vai de Van</title>
        </Helmet>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
            paddingBottom: "10px"
          }}
        >
          <img
            src={logo}
            alt="Logo"
            height={"192"}
            style={{
              height: "80px",
              margin: "30px auto"
            }}
          />
          <Form onSubmit={this.handleSubmit} className={"login-form"}>
            <Form.Item>
              {getFieldDecorator(FormFields.NAME, {
                initialValue: "",
                rules: [
                  { required: true, message: "Por favor, digite seu nome." }
                ]
              })(
                <Input
                  type={"text"}
                  size="large"
                  prefix={
                    <Icon type="user" style={{ color: INPUT_ICON_COLOR }} />
                  }
                  placeholder="Nome"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.PHONE, {
                initialValue: "",
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value.length === 0) {
                        callback("Por favor, digite o numero do seu celular.");
                      } else if (value.length < 11) {
                        callback("Digite um número de celular válido.");
                      }

                      callback();
                    }
                  }
                ]
              })(
                <MaskedInput
                  mask="11 1 1111-1111"
                  placeholder="Celular"
                  size="large"
                  prefix={<Icon type="phone" className="form-icon" />}
                  inputMode="numeric"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.DOCUMENT_NUMBER, {
                initialValue: "",
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value.length === 0) {
                        callback("Por favor, digite o seu CPF.");
                      } else if (!validateCpf(value)) {
                        callback("Digite um CPF válido.");
                      }

                      callback();
                    }
                  }
                ]
              })(
                <MaskedInput
                  mask="111.111.111-11"
                  placeholder="CPF"
                  size="large"
                  prefix={<Icon type="idcard" className="form-icon" />}
                  inputMode="numeric"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.BIRTH, {
                initialValue: "",
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value.length === 0) {
                        callback("Por favor, digite a sua data de nascimento.");
                      } else if (value.length < 8) {
                        callback("Por favor, digite uma data válida.");
                      } else {
                        try {
                          validateBirthday(
                            moment(value, UNMASKED_BIRTH_DATE_FORMAT)
                          );
                        } catch (e) {
                          callback(e.message);
                        }
                      }

                      callback();
                    }
                  }
                ]
              })(
                <MaskedInput
                  mask="11/11/1111"
                  placeholder="Data de nascimento"
                  size="large"
                  prefix={<Icon type="calendar" className="form-icon" />}
                  inputMode="numeric"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.EMAIL, {
                initialValue: "",
                rules: [
                  { required: true, message: "Por favor, digite seu email." }
                ]
              })(
                <Input
                  type={"email"}
                  size="large"
                  prefix={
                    <Icon type="mail" style={{ color: INPUT_ICON_COLOR }} />
                  }
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.PASSWORD, {
                initialValue: "",
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value.length === 0) {
                        callback("Por favor, digite uma senha.");
                      } else {
                        try {
                          validatePassword(value);
                        } catch (e) {
                          callback(e.message);
                        }
                      }

                      callback();
                    }
                  }
                ]
              })(
                <Input.Password
                  size="large"
                  prefix={
                    <Icon type="lock" style={{ color: INPUT_ICON_COLOR }} />
                  }
                  placeholder="Senha"
                />
              )}
            </Form.Item>
            {/*<Form.Item>*/}
            {/*  {getFieldDecorator(FormFields.F2F_PAYMENT, {*/}
            {/*    valuePropName: "checked",*/}
            {/*    initialValue: false*/}
            {/*  })(*/}
            {/*    <div>*/}
            {/*      <Checkbox>Pagamento presencial</Checkbox>*/}
            {/*      <Icon*/}
            {/*        className="clickable"*/}
            {/*        type="question-circle"*/}
            {/*        onClick={() => {*/}
            {/*          Modal.info({*/}
            {/*            centered: true,*/}
            {/*            icon: "question-circle",*/}
            {/*            maskClosable: true,*/}
            {/*            title: "Pagamento Presencial",*/}
            {/*            content:*/}
            {/*              "Ao assinalar essa opção você estará pedindo para se cadastrar no programa de pagamento presencial. Esse pedido passará por uma análise para aprovação. Você não precisa fazer isso agora, é possível fazer isso a qualquer momento no seu perfil."*/}
            {/*          });*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</Form.Item>*/}
            <Form.Item>
              {getFieldDecorator(FormFields.TERMS, {
                valuePropName: "checked",
                initialValue: false,
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value) {
                        callback("É necessário aceitar os Termos de Uso.");
                      }

                      callback();
                    }
                  }
                ]
              })(
                <div>
                  <Checkbox>Li e aceito os</Checkbox>
                  <span
                    className="text-link"
                    onClick={() => {
                      Modal.info({
                        title: "Termos de Uso",
                        width: "800px",
                        content: (
                          <div className="terms-of-use">
                            <TermsAndConditionsContent />
                          </div>
                        ),
                        okText: "Entendi"
                      });
                    }}
                  >
                    Termos de Uso
                  </span>
                  <span className="sm-padding-horizontal">e</span>
                  <span
                    className="text-link"
                    onClick={() => {
                      Modal.info({
                        title: "Políticas de privacidade",
                        width: "800px",
                        content: (
                          <div className="privacy-policy">
                            <PrivacyPolicy />
                          </div>
                        ),
                        okText: "Entendi"
                      });
                    }}
                  >
                    Políticas de privacidade
                  </span>
                </div>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className={"login-form-button"}
                loading={loading}
              >
                Criar conta
              </Button>
              <span
                className="text-link"
                onClick={() => {
                  history.push(InternalUrl.LOGIN);
                }}
              >
                Fazer login{" "}
              </span>
              em vez disso.
            </Form.Item>
          </Form>
          {oAuthLoginEnabled && (
            <>
              <div className={"divider-container"}>
                <Divider>ou cadastre-se com</Divider>
              </div>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  oAuthLogin(credentialResponse.credential)
                    .then(token => {
                      //TODO
                      this.onLoginSuccess(token);
                      // const rememberMe = getFieldValue(FormFields.REMEMBER_ME);
                      // setToken(token, rememberMe);
                    })
                    .catch(error => {
                      message.error(
                        `Erro ao tentar fazer login: ${error.message}`
                      );
                    });
                }}
                onError={() => {
                  Modal.error({
                    title: "Não foi possível fazer o login social",
                    content: (
                      <p>
                        Por favor, tente novamente. Se o problema persistir,
                        envie um email para{" "}
                        <EmailLink subject="Erro ao efetuar login social" />{" "}
                        informando que há um erro ao tentar efetuar o login
                        social.
                      </p>
                    )
                  });
                }}
                width={290}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

Signup.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired,
  form: PropTypes.PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  oAuthLoginEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    oAuthLoginEnabled: state.configsReducer.oAuthLoginEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default lodash.flow([
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: "signup" })
])(Signup);
