import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Helmet } from "react-helmet";
import {
  Collapse,
  Alert,
  Pagination,
  Modal,
  Row,
  Col,
  Button,
  DatePicker,
  Typography,
  Skeleton,
  Icon
} from "antd";
import styled from "styled-components";
import { requestStatusType, cityType, tripType } from "types/propTypes";
import TripCard from "components/tripCard/TripCard";
import CityAutoComplete from "components/cityAutocomplete/CityAutoComplete";
import Footer from "components/footer/Footer";
import RequestStatus, { Status } from "../../model/RequestStatus";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import { BACKEND_DATETIME_FORMAT } from "constants/backend";
import { InternalUrl } from "constants/urls";
import commonQuestions from "constants/commonQuestions";
import {
  backendDateTimeStringToMoment,
  momentToBackendDateString
} from "util/formatUtils";
import HomePageDynamicContent from "./components/HomepageDynamicContent";
import "./styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearArrivalCity,
  clearDepartureCity,
  getTrips,
  setArrivalCity,
  setDepartureCity,
  setDepartureDate,
  setScrollPosition
} from "../../redux/actions/home";
import { backendDateStringToMoment } from "../../util/formatUtils";
import { restoreScrollPosition } from "../../redux/actions/events";
import SimpleCard from "../../components/cards/SimpleCard";
import { getRoutes } from "../../queries/queries";
import Loading from "../../components/loading";
import classNames from "classnames/bind";
import { getCustomData } from "../../queries/queries";
import { CustomDataKey } from "../../constants/customDataKey";

const imagesViewport = [
  require("assets/img/viewport/black_van_road.jpg"),
  require("assets/img/viewport/stopped_van_boarding.jpg"),
  require("assets/img/viewport/white_van_road.jpg")
];

const { Title } = Typography;

const DATEPICKER_DATE_FORMAT = "DD/MM/YYYY";
const PAGE_SIZE = 6;

const DEFAULT_COVER_TEXT =
  "Viaje com o conforto de uma van a preços mais acessíveis";

const PaginationContainer = styled.div`
  text-align: center;
  padding-bottom: 8px;
`;

class Home extends Component {
  departureRef = React.createRef();
  arrivalRef = React.createRef();
  tripsListRef = React.createRef();

  state = {
    tripsResultComesFromSearchClick: false,
    viewport: imagesViewport[Math.floor(Math.random() * imagesViewport.length)],
    getRoutesRequestStatus: new RequestStatus(Status.NOT_STARTED),
    routes: [],
    homePageDynamicContentRequestStatus: new RequestStatus(Status.NOT_STARTED),
    homePageDynamicContent: undefined
  };

  componentDidMount() {
    const {
      history,
      requestStatus,
      scrollPositionWhenLeftPage,
      restoreScrollPosition,
      routeSearchStyleEnabled
    } = this.props;

    if (routeSearchStyleEnabled) {
      this.getRoutes();
    } else {
      if (history.action === "POP" && requestStatus.status === Status.SUCCESS) {
        restoreScrollPosition(scrollPositionWhenLeftPage);
      } else {
        this.goToPage(0);
      }
    }

    this.getHomePageDynamicContent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      requestStatus: prevRequestStatus,
      page: prevPage,
      routeSearchStyleEnabled: prevRouteSearchStyleEnabled
    } = prevProps;
    const { requestStatus, page, routeSearchStyleEnabled } = this.props;
    const { tripsResultComesFromSearchClick } = this.state;

    if (
      prevRequestStatus.status !== requestStatus.status &&
      requestStatus.status === Status.SUCCESS
    ) {
      if (tripsResultComesFromSearchClick || page !== prevPage) {
        //scroll to trips list automatically
        setTimeout(() => {
          const prop = lodash.get(this.tripsListRef, "current.scrollIntoView");
          if (typeof prop === "function") {
            this.tripsListRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
          }
        }, 200);
      }
    }

    if (
      prevRequestStatus.status === Status.LOADING &&
      requestStatus.status === Status.ERROR
    ) {
      Modal.info({
        title: "Oops!",
        content: (
          <div>
            <p>{requestStatus.message}</p>
          </div>
        )
      });
    }

    if (
      routeSearchStyleEnabled &&
      prevRouteSearchStyleEnabled !== routeSearchStyleEnabled
    ) {
      this.getRoutes();
    }
  }

  componentWillUnmount() {
    const { scrollPosition, setScrollPosition } = this.props;

    setScrollPosition(scrollPosition);
  }

  getRoutes = () => {
    const {
      history,
      restoreScrollPosition,
      scrollPositionWhenLeftPage
    } = this.props;

    this.setState({
      getRoutesRequestStatus: new RequestStatus(
        Status.LOADING,
        "Carregando rotas"
      )
    });

    getRoutes()
      .then(routes => {
        this.setState(
          {
            getRoutesRequestStatus: new RequestStatus(Status.SUCCESS),
            routes
          },
          () => {
            if (history.action === "POP") {
              restoreScrollPosition(scrollPositionWhenLeftPage);
            }
          }
        );
      })
      .catch(error => {
        this.setState({
          getRoutesRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          ),
          routes: []
        });
      });
  };

  goToPage = pageNumber => {
    const { getTrips, departure, arrival, departureDate } = this.props;

    getTrips(
      departure ? departure.value : null,
      arrival ? arrival.value : null,
      departureDate,
      pageNumber,
      PAGE_SIZE
    );
  };

  goToTripDetails = tripId => {
    const { history } = this.props;
    history.push(InternalUrl.TRIP_DETAILS.replace(":tripId", tripId));
  };

  onDateSelect = moment => {
    this.setState({
      departureDate: moment
    });
  };

  onSearchClick = () => {
    this.setState(
      {
        tripsResultComesFromSearchClick: true
      },
      () => {
        this.goToPage(0);
      }
    );
  };

  getHomePageDynamicContent = () => {
    this.setState({
      homePageDynamicContentRequestStatus: new RequestStatus(Status.LOADING)
    });

    getCustomData(CustomDataKey.HOME_PAGE)
      .then(response => {
        this.setState({
          homePageDynamicContentRequestStatus: new RequestStatus(
            Status.SUCCESS
          ),
          homePageDynamicContent: response
        });
      })
      .catch(error => {
        this.setState({
          homePageDynamicContentRequestStatus: new RequestStatus(Status.ERROR),
          homePageDynamicContent: undefined
        });
      });
  };

  renderTripSearchStyleCover() {
    const {
      requestStatus,
      departure,
      arrival,
      departureDate,
      setDepartureCity,
      clearDepartureCity,
      setArrivalCity,
      clearArrivalCity,
      setDepartureDate,
      coverText
    } = this.props;

    return (
      <div
        className="viewport viewport-align page-header"
        style={{
          backgroundImage: `linear-gradient(rgba(55, 0, 255, 0.3), rgba(55, 0, 255, 0.3)), url(${this.state.viewport})`
        }}
      >
        <div className="search-box search-margin">
          <Row gutter={[10, 10]}>
            <Col lg={6} sm={24}>
              {departure ? (
                <Button
                  size="large"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    padding: "0 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                  onClick={() => {
                    clearDepartureCity();

                    setTimeout(() => {
                      this.departureRef.current.inputRef.input.focus();
                    }, 200);
                  }}
                >
                  {departure.text}
                  <Icon
                    type={"close-circle"}
                    style={{ color: "rgba(0,0,0,.25)" }}
                  />
                </Button>
              ) : (
                <CityAutoComplete
                  ref={this.departureRef}
                  placeholder="Saindo de"
                  onCitySelect={setDepartureCity}
                />
              )}
            </Col>
            <Col lg={6} sm={24}>
              {arrival ? (
                <Button
                  size="large"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    padding: "0 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                  onClick={() => {
                    clearArrivalCity();

                    setTimeout(() => {
                      this.arrivalRef.current.inputRef.input.focus();
                    }, 200);
                  }}
                >
                  {arrival.text}
                  <Icon
                    type={"close-circle"}
                    style={{ color: "rgba(0,0,0,.25)" }}
                  />
                </Button>
              ) : (
                <CityAutoComplete
                  ref={this.arrivalRef}
                  placeholder="Indo para"
                  onCitySelect={setArrivalCity}
                />
              )}
            </Col>
            <Col lg={6} sm={24}>
              <DatePicker
                disabledDate={endValue => {
                  const startDate = moment().subtract(1, "days");
                  if (!endValue) {
                    return false;
                  }
                  return endValue.valueOf() < startDate.valueOf();
                }}
                className="search-column"
                format={DATEPICKER_DATE_FORMAT}
                value={backendDateStringToMoment(departureDate)}
                locale={locale}
                size="large"
                placeholder="Dia"
                onChange={departureDate => {
                  setDepartureDate(momentToBackendDateString(departureDate));
                }}
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg={6} sm={24}>
              <Button
                type="primary"
                size="large"
                onClick={this.onSearchClick}
                style={{ width: "100%" }}
                loading={requestStatus.status === Status.LOADING}
              >
                BUSCAR
              </Button>
            </Col>
          </Row>
        </div>
        <Title level={1} className={"highlight"}>
          {coverText || DEFAULT_COVER_TEXT}
        </Title>
      </div>
    );
  }

  renderTripCards = () => {
    const {
      page,
      totalElements,
      trips,
      departureDate,
      departure,
      arrival
    } = this.props;
    const { tripsResultComesFromSearchClick } = this.state;

    const hasAnyTripFilterSet = departureDate || departure || arrival;

    const perfectMatchTrips = [];
    const imperfectMatchTrips = [];

    trips.forEach(trip => {
      const tripDepartureDate = backendDateTimeStringToMoment(
        trip.departureTime
      ).format(DATEPICKER_DATE_FORMAT);

      if (
        tripsResultComesFromSearchClick &&
        ((departure && departure.value !== trip.departureCity.id) ||
          (arrival && arrival.value !== trip.arrivalCity.id) ||
          (departureDate &&
            backendDateStringToMoment(departureDate).format(
              DATEPICKER_DATE_FORMAT
            ) !== tripDepartureDate))
      ) {
        imperfectMatchTrips.push(trip);
      } else {
        perfectMatchTrips.push(trip);
      }
    });

    return (
      <>
        <Row>
          <div>{perfectMatchTrips.map(this.renderTripCard)}</div>
        </Row>
        {tripsResultComesFromSearchClick && hasAnyTripFilterSet && (
          <div className={"trip-lists-separator"}>
            <Alert
              className={"alert"}
              message={`Não encontramos ${
                perfectMatchTrips.length > 0
                  ? "mais viagens que correspondam "
                  : "nenhuma viagem que corresponda "
              } aos seus critérios de busca. ${
                imperfectMatchTrips.length > 0
                  ? "Talvez essas outras viagens possam te interessar."
                  : ""
              } `}
              type="error"
              closable
            />
          </div>
        )}
        <div className={"trip-lists-separator"}>
          <Row>
            {imperfectMatchTrips.map(trip => this.renderTripCard(trip))}
          </Row>
        </div>
        <br />
        <PaginationContainer>
          <Pagination
            size="small"
            current={parseInt(page, 10) + 1}
            pageSize={PAGE_SIZE}
            total={totalElements}
            onChange={pageNumber => this.goToPage(pageNumber - 1)}
          />
        </PaginationContainer>
      </>
    );
  };

  renderRoutes = () => {
    const { history } = this.props;
    const { getRoutesRequestStatus, routes } = this.state;

    if (getRoutesRequestStatus.status === Status.NOT_STARTED) {
      return null;
    } else if (getRoutesRequestStatus.status === Status.LOADING) {
      return <Loading />;
    } else if (getRoutesRequestStatus.status === Status.SUCCESS) {
      return (
        <div>
          <Row>
            {routes.map(route => (
              <Col key={`${route.departure.id}-${route.arrival.id}`} sm={12}>
                <SimpleCard
                  hoverable
                  clickable
                  onClick={() => {
                    history.push(
                      InternalUrl.TRIP_CALENDAR.replace(
                        ":departureId",
                        route.departure.id
                      ).replace(":arrivalId", route.arrival.id)
                    );
                  }}
                >
                  <div
                    className={"trip-card-2"}
                    style={{
                      backgroundImage: `url(https://res.cloudinary.com/dwvw6ursm/image/upload/v1684209424/app/cities/HD/${route.arrival.id}.jpg)`
                    }}
                  >
                    <h3>
                      Viaje para <strong>{route.arrival.displayName}</strong>
                    </h3>
                    <span>(Saindo de {route.departure.displayName})</span>
                  </div>
                </SimpleCard>
              </Col>
            ))}
          </Row>
        </div>
      );
    } else if (getRoutesRequestStatus.status === Status.ERROR) {
      return (
        <Alert
          className={"alert"}
          message={
            "Houve um erro ao buscar as viagens. Por favor, atualize a página para tentar novamente."
          }
          type="error"
        />
      );
    }
  };

  renderTripCard = trip => {
    return (
      <div key={trip.id}>
        <Col key={trip.id} xs={24} sm={24} md={24} lg={12}>
          <TripCard
            id={trip.id}
            departure={`${trip.departureCity.name}`}
            arrival={`${trip.arrivalCity.name}`}
            price={trip.price}
            departureDate={moment(trip.departureTime, BACKEND_DATETIME_FORMAT)}
            logo={trip.companyLogo}
            companyId={trip.companyId}
            onReservationClick={() => this.goToTripDetails(trip.id)}
            probability={trip.probability}
          />
        </Col>
      </div>
    );
  };

  renderTripsList = () => {
    const { requestStatus, trips, routeSearchStyleEnabled } = this.props;
    const { tripsResultComesFromSearchClick } = this.state;

    if (requestStatus.status === Status.LOADING)
      return (
        <div>
          <Skeleton active />
        </div>
      );

    if (requestStatus.status === Status.ERROR) {
      return (
        <Alert
          className={"alert"}
          message={
            "Houve um erro ao buscar as viagens. Por favor, tente novamente."
          }
          type="error"
        />
      );
    }

    if (tripsResultComesFromSearchClick && trips.length === 0) {
      return (
        <div>
          <Alert
            className={"alert"}
            message="Não encontramos nenhuma viagem que corresponda aos seus critérios de busca."
            type="error"
            closable
          />
        </div>
      );
    }

    return (
      <>
        <div>
          {routeSearchStyleEnabled
            ? this.renderRoutes()
            : this.renderTripCards()}
        </div>
      </>
    );
  };

  render() {
    const {
      homePageDynamicContentRequestStatus,
      homePageDynamicContent
    } = this.state;
    const { routeSearchStyleEnabled } = this.props;

    return (
      <div
        className={classNames({
          "search-style-home-container": !routeSearchStyleEnabled
        })}
      >
        <Helmet>
          <title>Procurar viagens | Vai de Van</title>
        </Helmet>
        {routeSearchStyleEnabled
          ? this.renderRoutesStyleCover()
          : this.renderTripSearchStyleCover()}
        <div className={"sectionTitle"}>
          <Title level={2}>Para onde você quer ir?</Title>
        </div>
        <div ref={this.tripsListRef} className={"trip-list-container"}>
          <div>{this.renderTripsList()}</div>
        </div>
        <div>
          {homePageDynamicContentRequestStatus.status === Status.SUCCESS &&
            homePageDynamicContent && (
              <HomePageDynamicContent content={homePageDynamicContent} />
            )}
          {/* TODO: add common questions here */}
          <div className={"common-questions-container"}>
            <Title
              level={1}
              style={{
                textAlign: "center",
                padding: "40px 20px 5px 20px"
              }}
            >
              Perguntas frequentes
            </Title>
            <Collapse accordion>
              {commonQuestions.map((commonQuestion, index) => {
                return (
                  <Collapse.Panel header={commonQuestion.question} key={index}>
                    <p>{commonQuestion.answer}</p>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  renderRoutesStyleCover(coverText) {
    return (
      <div
        className={"coverImage"}
        style={{
          backgroundImage: `linear-gradient(rgba(55, 0, 255, 0.3), rgba(55, 0, 255, 0.3)), url(${this.state.viewport})`
        }}
      >
        <div className={"coverImageContent"}>
          <div className={"coverImageText"}>
            <div className={"coverImageTitle"}>
              <span>{coverText || DEFAULT_COVER_TEXT}</span>
            </div>
            <div className={"coverImageSubtext"}>
              <span>Escolha seu destino</span>
              <span>Preencha as informações</span>
              <span>Tenha uma boa viagem</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  homeReducer,
  eventsReducer,
  themeReducer,
  configsReducer
}) => {
  const {
    requestStatus,
    departure,
    arrival,
    departureDate,
    page,
    totalElements,
    trips,
    scrollPositionWhenLeftPage
  } = homeReducer;
  const { scrollPosition } = eventsReducer;
  const { coverText } = themeReducer;
  const { routeSearchStyleEnabled } = configsReducer;

  return {
    requestStatus,
    departure,
    arrival,
    departureDate,
    page,
    totalElements,
    trips,
    scrollPositionWhenLeftPage,
    scrollPosition,
    coverText,
    routeSearchStyleEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTrips,
      setDepartureCity,
      clearDepartureCity,
      setArrivalCity,
      clearArrivalCity,
      setDepartureDate,
      setScrollPosition,
      restoreScrollPosition
    },
    dispatch
  );
};

Home.defaultProps = {
  coverText: DEFAULT_COVER_TEXT
};

Home.propTypes = {
  history: PropTypes.any.isRequired,
  requestStatus: requestStatusType.isRequired,
  departure: cityType,
  arrival: cityType,
  departureDate: PropTypes.string,
  page: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  trips: PropTypes.arrayOf(tripType).isRequired,
  scrollPositionWhenLeftPage: PropTypes.number.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  setDepartureCity: PropTypes.func.isRequired,
  clearDepartureCity: PropTypes.func.isRequired,
  setArrivalCity: PropTypes.func.isRequired,
  clearArrivalCity: PropTypes.func.isRequired,
  setDepartureDate: PropTypes.func.isRequired,
  setScrollPosition: PropTypes.func.isRequired,
  restoreScrollPosition: PropTypes.func.isRequired,
  getTrips: PropTypes.func.isRequired,
  coverText: PropTypes.string,
  routeSearchStyleEnabled: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
