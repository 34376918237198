import { Component } from "react";
import PropTypes from "prop-types";

// This route simply redirect to the desired route, used to force some routes remount in some cases.
class RedirectTo extends Component {
  componentDidMount() {
    const {
      history,
      location: {
        state: { redirectTo }
      }
    } = this.props;
    history.replace(redirectTo);
  }

  render() {
    return null;
  }
}

RedirectTo.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired
};

export default RedirectTo;
