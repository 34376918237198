import * as ReservationPaymentType from "../actionTypes/reservationPayment";

const initialState = {
  creditCardForm: {
    cardNumber: "",
    cvc: "",
    expirationDate: "",
    holderName: "",
    cpf: ""
  }
};

const reservationPayment = (state = initialState, { type, payload }) => {
  switch (type) {
    case ReservationPaymentType.CLEAR_CARD_INFO: {
      return {
        ...state,
        creditCardForm: { ...initialState.creditCardForm }
      };
    }
    case ReservationPaymentType.SET_CARD_NUMBER: {
      return {
        ...state,
        creditCardForm: {
          ...state.creditCardForm,
          cardNumber: payload
        }
      };
    }
    case ReservationPaymentType.SET_CVC: {
      return {
        ...state,
        creditCardForm: {
          ...state.creditCardForm,
          cvc: payload
        }
      };
    }
    case ReservationPaymentType.SET_EXPIRATION_DATE: {
      return {
        ...state,
        creditCardForm: {
          ...state.creditCardForm,
          expirationDate: payload
        }
      };
    }
    case ReservationPaymentType.SET_HOLDER_NAME: {
      return {
        ...state,
        creditCardForm: {
          ...state.creditCardForm,
          holderName: payload
        }
      };
    }
    case ReservationPaymentType.SET_CPF: {
      return {
        ...state,
        creditCardForm: {
          ...state.creditCardForm,
          cpf: payload
        }
      };
    }
    default:
      return state;
  }
};

export default reservationPayment;
