import {
  mdiTelevisionClassic,
  mdiSnowflake,
  mdiMusic,
  mdiSmoking,
  mdiPaw,
  mdiHeadphones,
  mdiCupOff,
  mdiUsb,
  mdiCupWater,
  mdiCoffee,
  mdiSeatReclineExtra,
  mdiPizza
} from "@mdi/js";

export const icons = {
  1: mdiTelevisionClassic,
  2: mdiSnowflake,
  3: mdiMusic,
  4: mdiSmoking,
  5: mdiPaw,
  6: mdiHeadphones,
  7: mdiCupOff,
  8: mdiPaw,
  9: mdiUsb,
  10: mdiCupWater,
  11: mdiCoffee,
  12: mdiSeatReclineExtra,
  13: mdiPizza
};
