import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Button, Form, message, Modal, Result } from "antd";
import RequestStatus, { Status } from "model/RequestStatus";
import {
  getParamsFromPrevLocation,
  goToLocationForceRefresh
} from "util/routeUtils";
import { numberToPrice } from "util/formatUtils";
import {
  cancelReservation,
  getBoardingPointsForTripOrReservation,
  addBoardingPointForTrip
} from "queries/queries";
import { InternalUrl } from "constants/urls";
import style from "./styles.module.css";
import Loading from "components/loading";
import { CreditCardBrands } from "constants/creditCardBrands";
import SimpleCard from "components/cards/SimpleCard";
import { PRICE_GREEN_COLOR } from "../../../../constants/colorDefs";
import HighlightText from "../../../../components/highlightText/HighlightText";
import SubRoutes from "../subRoutes";
import { Color } from "../../../../constants/colors";

const CurrentAction = Object.freeze({
  ADD_BOARDING_POINT: "ADD_BOARDING_POINT",
  CANCEL_RESERVATION: "CANCEL_RESERVATION",
  FETCH_INTIAL_DATA: "FETCH_INTIAL_DATA"
});

class BoardingPoint extends Component {
  formSnapshot = undefined;
  acceptedCards = Object.entries(CreditCardBrands).map(
    ([, value]) => value.cardLibAcceptedBrand
  );

  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    currentAction: CurrentAction.FETCH_INTIAL_DATA,
    selectedBoardingPoint: undefined,
    tripDetails: undefined,
    reservation: undefined,
    boardingPoints: []
  };

  constructor(props) {
    super(props);

    const { tripDetails, reservation } = getParamsFromPrevLocation(props);

    this.state = {
      ...this.state,
      tripDetails,
      reservation
    };
  }

  componentDidMount() {
    this.fetchBoardingPoints();
  }

  fetchBoardingPoints = () => {
    const {
      reservation: { code }
    } = this.state;

    this.setState({
      requestStatus: new RequestStatus(
        Status.LOADING,
        "Carregando pontos de embarque"
      ),
      currentAction: CurrentAction.FETCH_INTIAL_DATA
    });

    getBoardingPointsForTripOrReservation(code)
      .then(boardingPoints => {
        let orderedBoardingPoints = [];

        if (boardingPoints) {
          orderedBoardingPoints = boardingPoints.sort(
            (a, b) => a.position - b.position
          );
        }

        this.setState({
          requestStatus: new RequestStatus(Status.SUCCESS),
          boardingPoints: orderedBoardingPoints
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  selectBoardingPoint = id => {
    this.setState({
      selectedBoardingPoint: id
    });
  };

  saveSelectedBoardingPoint = () => {
    const { history } = this.props;
    const { tripDetails, reservation, selectedBoardingPoint } = this.state;
    const { code } = reservation;

    this.setState({
      requestStatus: new RequestStatus(
        Status.LOADING,
        "Salvando o ponto de embarque"
      ),
      currentAction: CurrentAction.ADD_BOARDING_POINT
    });

    addBoardingPointForTrip(code, selectedBoardingPoint)
      .then(reservation => {
        this.setState({
          requestStatus: new RequestStatus(Status.SUCCESS)
        });

        const nextLocation = InternalUrl.RESERVATION.replace(
          ":reservationCode",
          code
        ).concat(SubRoutes.PAYMENT);
        history.replace(nextLocation, {
          tripDetails,
          reservation
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  onCancelReservationClick = () => {
    const {
      reservation: { code: reservationCode }
    } = this.state;
    const {
      location: { pathname }
    } = this.props;

    Modal.confirm({
      title: "Cancelar reserva",
      content: "Deseja realmente cancelar essa reserva?",
      centered: true,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => {
        this.setState({
          requestStatus: new RequestStatus(
            Status.LOADING,
            "Cancelando reserva"
          ),
          currentAction: CurrentAction.CANCEL_RESERVATION
        });

        cancelReservation(reservationCode)
          .then(reservation => {
            message.success("Sua reserva foi cancelada!");
            goToLocationForceRefresh(pathname, true);
          })
          .catch(error => {
            this.setState({
              requestStatus: new RequestStatus(Status.ERROR, error.message),
              currentAction: CurrentAction.CANCEL_RESERVATION
            });
          });
      }
    });
  };

  render() {
    const { requestStatus, currentAction, boardingPoints } = this.state;

    if (requestStatus.status === Status.NOT_STARTED) {
      return null;
    } else if (requestStatus.status === Status.LOADING) {
      return <Loading loadingMessage={requestStatus.message} />;
    } else if (requestStatus.status === Status.SUCCESS) {
      if (currentAction === CurrentAction.FETCH_INTIAL_DATA) {
        const {
          selectedBoardingPoint,
          tripDetails: {
            departure: { fullName }
          }
        } = this.state;

        return (
          <>
            {(() => {
              if (boardingPoints.length === 0) {
                return (
                  <>
                    <h1>Ponto de embarque</h1>
                    <p className={style.textContent}>
                      Essa viagem possui um único ponto de embarque que é em{" "}
                      <HighlightText>{fullName}</HighlightText>.
                    </p>
                  </>
                );
              } else {
                return (
                  <>
                    <div
                      style={{
                        flexGrow: 1,
                        overflowY: "auto"
                      }}
                    >
                      <h2>Escolha o ponto de embarque</h2>
                      {boardingPoints.map(boardingPoint => {
                        const {
                          id,
                          displayName,
                          address,
                          departureTime,
                          price
                        } = boardingPoint;

                        return (
                          <SimpleCard
                            key={id}
                            clickable
                            selected={selectedBoardingPoint === id}
                            onClick={() => this.selectBoardingPoint(id)}
                          >
                            <div className={style.boardingPointFirstContainer}>
                              <div style={{ flexGrow: 1 }}>
                                <div>
                                  <h2>
                                    <HighlightText>{displayName}</HighlightText>
                                  </h2>
                                  <h3>{address}</h3>
                                </div>
                                <div className={style.thirdRow}>
                                  <div
                                    className={style.boardingPointTimeContainer}
                                  >
                                    <img
                                      src={require("assets/icons/boarding_point_departure_time.svg")}
                                      alt={"Horário desse ponto de embarque"}
                                    />
                                    <span>
                                      <HighlightText>
                                        {departureTime}
                                      </HighlightText>
                                    </span>
                                  </div>
                                  <div className={style.price}>
                                    <HighlightText color={PRICE_GREEN_COLOR}>
                                      R$ {numberToPrice(price)}
                                    </HighlightText>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SimpleCard>
                        );
                      })}
                    </div>
                  </>
                );
              }
            })()}

            <div
              style={{
                backgroundColor: "#FFFFFF", //TODO find a way to use the variable defined on body tag on the main App.css
                borderTop: `1px solid ${Color.ANTD_LIGHT_GREY_BORDER}`,
                paddingTop: "8px"
              }}
            >
              <div
                className="steps-action"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Button
                  type="danger"
                  ghost
                  onClick={this.onCancelReservationClick}
                >
                  Cancelar reserva
                </Button>
                <Button
                  type="primary"
                  disabled={boardingPoints.length > 0 && !selectedBoardingPoint}
                  onClick={this.saveSelectedBoardingPoint}
                >
                  Próximo
                </Button>
              </div>
            </div>
          </>
        );
      }
    } else if (requestStatus.status === Status.ERROR) {
      if (currentAction === CurrentAction.FETCH_INTIAL_DATA) {
        return (
          <Result
            status="warning"
            title={"Não foi possível carregar os pontos de embarque"}
            subTitle={requestStatus.message}
            extra={[
              <Button
                type="primary"
                key="tryAgain"
                onClick={this.fetchBoardingPoints}
              >
                Entendi
              </Button>
            ]}
          />
        );
      } else if (currentAction === CurrentAction.ADD_BOARDING_POINT) {
        return (
          <Result
            status="warning"
            title={"Não foi possível escolher o ponto de embarque"}
            subTitle={requestStatus.message}
            extra={[
              <Button
                type="primary"
                key="tryAgain"
                onClick={() => {
                  this.setState({
                    requestStatus: new RequestStatus(Status.SUCCESS),
                    currentAction: CurrentAction.FETCH_INTIAL_DATA
                  });
                }}
              >
                Entendi
              </Button>
            ]}
          />
        );
      } else if (currentAction === CurrentAction.CANCEL_RESERVATION) {
        return (
          <Result
            status="warning"
            title={"Não foi possível cancelar a reserva"}
            subTitle={requestStatus.message}
            extra={[
              <Button
                type="primary"
                key="tryAgain"
                onClick={() => {
                  this.setState({
                    requestStatus: new RequestStatus(Status.SUCCESS),
                    currentAction: CurrentAction.FETCH_INTIAL_DATA
                  });
                }}
              >
                Entendi
              </Button>
            ]}
          />
        );
      }
    }

    return null;
  }
}

BoardingPoint.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired
};

export default lodash.flowRight(Form.create({ name: "payment" }))(
  BoardingPoint
);
