import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@mdi/react";
import {
  mdiCheck,
  mdiCarSeat,
  mdiAccount,
  mdiMapMarker,
  mdiCurrencyUsd
} from "@mdi/js";
import "./styles.less";

const steps = [
  {
    key: "seats",
    title: "Assentos",
    icon: mdiCarSeat
  },
  {
    key: "passengers",
    title: "Passageiros",
    icon: mdiAccount
  },
  {
    key: "boardingPoint",
    title: "Ponto de embarque",
    icon: mdiMapMarker
  },
  {
    key: "payment",
    title: "Pagamento",
    icon: mdiCurrencyUsd
  }
];

const ReservationSteps = ({ currentStep }) => {
  const totalSteps = steps.length;

  return (
    <div style={{ position: "relative" }}>
      <div className={"wizard-wrapper"}>
        {steps.map((step, index) => {
          let stepStatusClass = "disabled";
          let { icon } = step;

          if (index < currentStep) {
            stepStatusClass = "completed";
            icon = mdiCheck;
          } else if (index === currentStep) {
            stepStatusClass = "current";
          }

          return (
            <div key={step.key} className={`wizard-step ${stepStatusClass}`}>
              <div className={"wizard-step-icon"}>
                <Icon path={icon} size={0.9} />
              </div>
            </div>
          );
        })}
      </div>
      <div className={"wizard-progress-bar"} />
      <div
        className={"wizard-progress-bar current"}
        style={{ width: `${(100 / (totalSteps - 1)) * currentStep}%` }}
      />
    </div>
  );
};

ReservationSteps.propTypes = {
  currentStep: PropTypes.number.isRequired
};

export default ReservationSteps;
