import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { PrimaryColor } from "constants/colors";

const Card = styled.div`
  border: 1px solid rgb(221, 221, 221);
  margin: 10px 0px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;

  ${props =>
    props.hoverable &&
    css`
      &:hover {
        background-color: ${props => (props.selected ? "#D2D2D2" : "#F3F3F3")};
        cursor: ${props => (props.clickable ? "pointer" : "auto")};
      }
    `}

  ${props =>
    props.clickable &&
    css`
      &:active {
        background-color: #cdcdcd;
        cursor: pointer;
      }
    `}
  
  ${props =>
    props.selected &&
    css`
       {
        background-color: #dfdfdf;
        border: 3px solid ${PrimaryColor[4]};
        padding: 3px;
      }
    `}
  
  ${props =>
    props.disabled &&
    css`
       {
        pointer-events: none;
      }
    `}
`;

const SimpleCard = ({
  children,
  hoverable = false,
  clickable = false,
  selected = false,
  disabled = false,
  ...rest
}) => (
  <Card
    hoverable={clickable || hoverable}
    clickable={clickable}
    selected={selected}
    disabled={disabled}
    {...rest}
  >
    {children}
  </Card>
);

SimpleCard.propTypes = {
  children: PropTypes.any,
  hoverable: PropTypes.bool,
  clickable: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SimpleCard;
