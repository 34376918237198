export const ExternalUrl = Object.freeze({
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL
});

export const InternalUrl = Object.freeze({
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  PROFILE: "/profile/userInfo",
  PAYMENT: "/profile/payment",
  CHANGE_PASSWORD: "/changePassword",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword",
  TRIP_CALENDAR: "/tripCalendar/:departureId/:arrivalId",
  TRIP_DETAILS: "/tripDetails/:tripId",
  MY_TRIPS_UPCOMING: "/myTrips/upcoming",
  RESERVATION: "/reservation/:reservationCode",
  MY_TRIPS_PAST: "/myTrips/past",
  CHATS: "/chat",
  CHAT: "/chat/:chatCode",
  VERIFY_EMAIL: "/verifyEmail",
  VERIFY_PHONE: "/verifyPhone",
  TERMS_AND_CONDITIONS: "/termsAndConditions",
  PRIVACY_POLICY: "/privacyPolicy",
  REDIRECT_TO: "/redirectTo"
});

export const SubscriptionsUrl = Object.freeze({
  NOTIFICATIONS: ExternalUrl.BACKEND_URL + "/v1/notification/subscribe",
  CHAT: ExternalUrl.BACKEND_URL + "/v1/chats/:chatCode"
});
