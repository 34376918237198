import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ResponseStatus } from "constants/responseStatus";
import { forgotPassword } from "queries/queries";
import { Button, Form, Icon, Input, Result } from "antd";
import Loading from "components/loading";
import { InternalUrl } from "constants/urls";
import { getParamsFromPrevLocation } from "../../util/routeUtils";
import HighlightText from "../../components/highlightText/HighlightText";

const FormFields = Object.freeze({
  EMAIL: "email"
});

class ForgotPassword extends Component {
  state = {
    responseStatus: ResponseStatus.SUCCESS,
    responseErrorMessage: "",
    isForgotPasswordEmailSent: false,
    invalidCode: false
  };

  handleSubmit = e => {
    const { form } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        this.setState({
          responseStatus: ResponseStatus.LOADING
        });

        const email = values[FormFields.EMAIL];

        forgotPassword(email)
          .then(() => {
            this.setState({
              responseStatus: ResponseStatus.SUCCESS,
              isForgotPasswordEmailSent: true
            });
          })
          .catch(error => {
            this.setState({
              responseStatus: ResponseStatus.ERROR,
              responseErrorMessage: error.message
            });
          });
      }
    });
  };

  componentDidMount() {
    const { invalidCode } = getParamsFromPrevLocation(this.props);

    if (invalidCode) {
      this.setState({
        invalidCode: true
      });
    }
  }

  render() {
    const { invalidCode } = this.state;

    const infoMessage = invalidCode
      ? "Esse código não é válido. Digite novamente seu email para recuperar o acesso à sua conta."
      : "Digite seu email para recuperar o acesso à sua conta";

    return (
      <>
        <Helmet>
          <title>Recuperar senha | Vai de Van</title>
        </Helmet>
        {(() => {
          const {
            responseStatus,
            responseErrorMessage,
            isForgotPasswordEmailSent
          } = this.state;
          const { history, form } = this.props;
          const { getFieldDecorator } = form;

          if (!isForgotPasswordEmailSent) {
            if (responseStatus === ResponseStatus.ERROR) {
              return (
                <Result
                  status="warning"
                  title="Não foi possível recuperar sua senha"
                  subTitle={responseErrorMessage}
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        this.setState({
                          responseStatus: ResponseStatus.SUCCESS
                        });
                      }}
                    >
                      Entendi
                    </Button>
                  ]}
                />
              );
            }

            if (responseStatus === ResponseStatus.LOADING) {
              return <Loading loadingMessage="Recuperando senha" />;
            }

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                    paddingTop: "20px"
                  }}
                >
                  <h2>Recuperar senha</h2>
                  <p style={{ maxWidth: "300px", textAlign: "justify" }}>
                    {infoMessage}
                  </p>
                  <Form onSubmit={this.handleSubmit} className={"login-form"}>
                    <Form.Item>
                      {getFieldDecorator(FormFields.EMAIL, {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Por favor, digite seu email."
                          }
                        ]
                      })(
                        <Input
                          type={"email"}
                          size="large"
                          prefix={
                            <Icon
                              type="mail"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Email"
                        />
                      )}
                    </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={"login-form-button"}
                    >
                      Continuar
                    </Button>
                  </Form>
                </div>
              </>
            );
          } else {
            return (
              <Result
                status="success"
                title="Verifique seu email"
                subTitle={
                  <div>
                    <p>
                      Enviamos um email para você poder recuperar sua conta. Por
                      favor, verifique sua{" "}
                      <HighlightText>caixa de entrada</HighlightText>.
                    </p>
                    <p>
                      Caso não encontre o email na sua caixa de entrada,
                      verifique na sua{" "}
                      <HighlightText>caixa de spam</HighlightText>.
                    </p>
                  </div>
                }
                extra={[
                  <Button
                    key="home"
                    onClick={() => {
                      history.push(InternalUrl.HOME);
                    }}
                  >
                    Voltar ao Vai de Van
                  </Button>
                ]}
              />
            );
          }
        })()}
      </>
    );
  }
}

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default Form.create({ name: "forgot_password" })(ForgotPassword);
