import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { getParamsFromUrlSearchString } from "util/routeUtils";
import { validateRequest, redefinePassword } from "queries/queries";
import { Button, Form, Icon, Input, message, Result } from "antd";
import Loading from "components/loading";
import { InternalUrl } from "constants/urls";
import { RequestType } from "../../constants/RequestType";
import RequestStatus, { Status } from "../../model/RequestStatus";
import { validatePassword } from "../../util/validationUtils";
import { setToken } from "../../services/auth";

const FormFields = Object.freeze({
  NEW_PASSWORD: "newPassword"
});

const Steps = Object.freeze({
  VERIFY_CODE: "VERIFY_CODE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD"
});

class ResetPassword extends Component {
  state = {
    step: Steps.VERIFY_CODE,
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    isCodeValid: undefined
  };

  componentDidMount() {
    const { history } = this.props;
    const { code } = getParamsFromUrlSearchString(this.props);

    if (code) {
      this.verifyCode();
    } else {
      history.replace(InternalUrl.HOME);
    }
  }

  verifyCode = () => {
    const { history } = this.props;
    const { code } = getParamsFromUrlSearchString(this.props);

    this.setState({
      requestStatus: new RequestStatus(Status.LOADING, "Verificando código")
    });

    validateRequest(code, RequestType.FORGOT_PASSWORD)
      .then(isCodeValid => {
        if (isCodeValid) {
          this.setState({
            requestStatus: new RequestStatus(Status.SUCCESS),
            isCodeValid
          });
        } else {
          message.error("Código inválido ou expirado");
          history.replace(InternalUrl.FORGOT_PASSWORD, { invalidCode: true });
        }
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  handleSubmit = e => {
    const { form } = this.props;
    const { validateFields } = form;
    const { code } = getParamsFromUrlSearchString(this.props);

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        this.setState({
          requestStatus: new RequestStatus(Status.LOADING),
          step: Steps.CHANGE_PASSWORD
        });

        const newPassword = values[FormFields.NEW_PASSWORD];

        redefinePassword(code, newPassword)
          .then(token => {
            setToken(token);
            this.setState({
              requestStatus: new RequestStatus(Status.SUCCESS)
            });
          })
          .catch(error => {
            this.setState({
              requestStatus: new RequestStatus(Status.ERROR, error.message)
            });
          });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <Helmet>
          <title>Troca de senha | Vai de Van</title>
        </Helmet>
        {(() => {
          const { requestStatus, step } = this.state;
          const { history } = this.props;

          if (requestStatus.status === Status.LOADING) {
            return <Loading loadingMessage={requestStatus.message} />;
          }

          if (step === Steps.VERIFY_CODE) {
            if (requestStatus.status === Status.SUCCESS) {
              const { isCodeValid } = this.state;

              if (isCodeValid) {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      flexDirection: "column",
                      paddingTop: "20px"
                    }}
                  >
                    <h2>Recuperar senha</h2>
                    <p style={{ maxWidth: "300px", textAlign: "justify" }}>
                      Digite uma nova senha para acessar sua conta da Vai de Van
                    </p>
                    <Form onSubmit={this.handleSubmit} className={"login-form"}>
                      <Form.Item>
                        {getFieldDecorator(FormFields.NEW_PASSWORD, {
                          initialValue: "",
                          rules: [
                            {
                              validator: (rule, value, callback) => {
                                if (!value || value.length === 0) {
                                  callback("Por favor, digite uma nova senha.");
                                } else {
                                  try {
                                    validatePassword(value);
                                  } catch (e) {
                                    callback(e.message);
                                  }
                                }

                                callback();
                              }
                            }
                          ]
                        })(
                          <Input.Password
                            type={"password"}
                            size="large"
                            prefix={
                              <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Nova senha"
                          />
                        )}
                      </Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={"login-form-button"}
                      >
                        Confirmar
                      </Button>
                    </Form>
                  </div>
                );
              }
            } else if (requestStatus.status === Status.ERROR) {
              return (
                <Result
                  status="error"
                  title="Erro ao verificar código"
                  subTitle={requestStatus.message}
                  extra={[
                    <Button key="home" type="primary" onClick={this.verifyCode}>
                      Entendi
                    </Button>
                  ]}
                />
              );
            }
          } else if (step === Steps.CHANGE_PASSWORD) {
            if (requestStatus.status === Status.SUCCESS) {
              return (
                <Result
                  status="success"
                  title="Senha redefinida"
                  subTitle={`Agora você já pode usar a sua nova senha para acessar o Vai de Van`}
                  extra={[
                    <Button
                      key="home"
                      type="primary"
                      onClick={() => {
                        history.push(InternalUrl.HOME);
                      }}
                    >
                      Procurar viagens
                    </Button>
                  ]}
                />
              );
            } else if (requestStatus.status === Status.ERROR) {
              return (
                <Result
                  status="warning"
                  title="Não foi possível criar uma nova senha"
                  subTitle={requestStatus.message}
                  extra={[
                    <Button
                      key="home"
                      type="primary"
                      onClick={() => {
                        this.setState({
                          requestStatus: new RequestStatus(Status.SUCCESS),
                          step: Steps.VERIFY_CODE
                        });
                      }}
                    >
                      Entendi
                    </Button>
                  ]}
                />
              );
            }
          }
        })()}
      </>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default Form.create({ name: "reset_password" })(ResetPassword);
