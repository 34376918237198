import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Modal, Carousel, Skeleton } from "antd";
import { getCompanyProfile } from "../../../queries/queries";
import "./styles.css";

class ProfileModal extends Component {
  state = {
    loading: true,
    company: {}
  };

  componentDidMount() {
    const { companyId } = this.props;

    this.setState({
      loading: true
    });

    getCompanyProfile(companyId)
      .then(company => {
        this.setState({
          company: company
        });
      })
      .catch(error => {
        //TODO should give feedback to user if fail loading profile?
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  renderPhotoGallery = () => {
    const { company } = this.state;
    const { photos = [] } = company;

    if (photos.length < 1) return null;

    return (
      <div className="company-gallery">
        <Carousel arrows={true} dotPosition={"top"} autoplay>
          {photos.map((photo, index) => (
            <div key={photo}>
              <img src={photo} alt={`Imagem ${index}`} />
            </div>
          ))}
        </Carousel>
      </div>
    );
  };

  render() {
    const { visible, callback } = this.props;
    const { loading, company } = this.state;
    const { logo: companyLogo = "", name = "", about = "" } = company;

    const avatarIcon = loading || companyLogo ? "loading" : "car";

    return (
      <Modal
        title={null}
        visible={visible}
        centered
        onCancel={() => {
          callback();
        }}
        footer={null}
        className="modal"
      >
        <div className="modal-body">
          <div className="company-profile-header">
            <Avatar
              shape="circle"
              size={96}
              icon={avatarIcon}
              style={{
                minWidth: "96px"
              }}
              src={companyLogo}
            />
            <span className="company-name">{name}</span>
          </div>
          <div className="company-profile-body">
            <span className="company-about">
              {loading ? (
                <Skeleton active />
              ) : (
                <span>{about || "A empresa não forneceu uma descrição."}</span>
              )}
            </span>
          </div>
          {this.renderPhotoGallery()}
        </div>
      </Modal>
    );
  }
}

ProfileModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired
};

export default ProfileModal;
