import * as HomeType from "../actionTypes/home";
import RequestStatus, { Status } from "../../model/RequestStatus";
import moment from "moment";
import { momentToBackendDateString } from "../../util/formatUtils";

const initialState = {
  requestStatus: new RequestStatus(Status.NOT_STARTED),
  departure: null,
  arrival: null,
  departureDate: momentToBackendDateString(moment()),
  page: 0,
  totalElements: 0,
  trips: [],
  scrollPositionWhenLeftPage: 0
};

const home = (state = initialState, { type, payload }) => {
  switch (type) {
    case HomeType.GET_TRIPS_PENDING: {
      return {
        ...state,
        requestStatus: new RequestStatus(Status.LOADING)
      };
    }
    case HomeType.GET_TRIPS_FULFILLED: {
      return {
        ...state,
        requestStatus: new RequestStatus(Status.SUCCESS),
        ...payload
      };
    }
    case HomeType.GET_TRIPS_REJECTED: {
      return {
        ...state,
        requestStatus: new RequestStatus(Status.ERROR, payload.message)
      };
    }
    case HomeType.SET_DEPARTURE_CITY: {
      return {
        ...state,
        departure: payload
      };
    }
    case HomeType.CLEAR_DEPARTURE_CITY: {
      return {
        ...state,
        departure: null
      };
    }
    case HomeType.SET_ARRIVAL_CITY: {
      return {
        ...state,
        arrival: payload
      };
    }
    case HomeType.CLEAR_ARRIVAL_CITY: {
      return {
        ...state,
        arrival: null
      };
    }
    case HomeType.SET_DEPARTURE_DATE: {
      return {
        ...state,
        departureDate: payload
      };
    }
    case HomeType.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPositionWhenLeftPage: payload
      };
    }
    default:
      return state;
  }
};

export default home;
