import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, DatePicker } from "antd";
import "./styles.css";
import moment from "moment";
import {
  backendDateStringToMoment,
  currentTimeMoment,
  momentToBackendDateString
} from "../../../util/formatUtils";
import locale from "antd/es/date-picker/locale/pt_BR";

const DATEPICKER_DATE_FORMAT = "DD/MM/YYYY";

class SelectDateModal extends Component {
  state = {
    selectedDate: currentTimeMoment()
  };

  componentDidMount() {
    const { selectedDate } = this.props;

    this.setState({
      selectedDate
    });
  }

  render() {
    const { visible, callback } = this.props;
    const { selectedDate } = this.state;

    return (
      <Modal
        title={<span className="login-form-header">Escolha outra data</span>}
        visible={visible}
        centered
        onCancel={() => {
          callback();
        }}
        footer={null}
        className={"container"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            width: "100%"
          }}
        >
          <DatePicker
            disabledDate={endValue => {
              const startDate = moment().subtract(1, "days");
              if (!endValue) {
                return false;
              }
              return endValue.valueOf() < startDate.valueOf();
            }}
            className="search-column"
            format={DATEPICKER_DATE_FORMAT}
            value={backendDateStringToMoment(selectedDate)}
            locale={locale}
            size="large"
            placeholder="Dia"
            onChange={selectedDate => {
              this.setState({
                selectedDate: momentToBackendDateString(selectedDate)
              });
            }}
            style={{ width: "100%" }}
          />
          <Button
            type="primary"
            size="large"
            onClick={() => {
              const { selectedDate } = this.state;
              callback(selectedDate);
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    );
  }
}

SelectDateModal.defaultProps = {};

SelectDateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  selectedDate: PropTypes.string
};

export default SelectDateModal;
