import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Button, Typography, Result } from "antd";
import { InternalUrl } from "constants/urls";
import RequestStatus, { Status } from "model/RequestStatus";
import Loading from "components/loading";
import { getF2fPaymentStatus } from "../../queries/queries";
import { F2FPaymentStatus } from "../../constants/f2FPaymentStatus";
import { connect } from "react-redux";
import style from "./styles.module.css";
import { PaymentMethods } from "../../constants/paymentMethods";

const { Title, Text, Paragraph } = Typography;

class PaymentProfile extends Component {
  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    f2fPaymentStatus: undefined
  };

  componentDidMount() {
    this.fetchF2fPaymentStatus();
  }

  fetchF2fPaymentStatus = () => {
    this.setState({
      requestStatus: new RequestStatus(
        Status.LOADING,
        "Carregando informações de pagamento"
      )
    });

    getF2fPaymentStatus()
      .then(status => {
        this.setState({
          requestStatus: new RequestStatus(Status.SUCCESS),
          f2fPaymentStatus: status
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  renderF2fPaymentSection = () => {
    const { f2fPaymentStatus } = this.state;

    return (
      <div className={style.paymentMethodSection}>
        {(() => {
          let color;
          let title;
          let details;

          if (f2fPaymentStatus === F2FPaymentStatus.NOT_FOUND) {
            return null;
          }

          switch (f2fPaymentStatus) {
            case F2FPaymentStatus.PENDING:
              color = "orange";
              title = "Pedido efetuado";
              details =
                "Estamos analisando seu pedido e você será notificado caso você seja aprovado no programa de pagamento presencial.";
              break;
            case F2FPaymentStatus.APPROVED:
              color = "green";
              title = "Aprovado";
              details =
                "Você faz parte do programa de pagamento presencial e este modo de pagamento estará disponível no momento em que você efetuar uma nova reserva.";
              break;
            case F2FPaymentStatus.DENIED:
              color = "red";
              title = "Pedido recusado";
              details =
                "Você não foi aprovado no programa de pagamento presencial, mas você pode tentar de novo em alguns meses.";
              break;
            default:
              break;
          }

          return (
            <div>
              <Text strong={true}>Pagamento presencial: </Text>
              <Text strong={true} style={{ color: color }}>
                {title}
              </Text>
              <Paragraph>{details}</Paragraph>
            </div>
          );
        })()}
      </div>
    );
  };

  renderPixPaymentSection = () => {
    const { paymentMethods } = this.props;

    const userHasPixPaymentMethod = paymentMethods.includes(PaymentMethods.PIX);

    return (
      <div className={style.paymentMethodSection}>
        {(() => {
          if (userHasPixPaymentMethod) {
            return (
              <>
                <Text strong={true}>Pix: </Text>
                <Text strong={true} style={{ color: "green" }}>
                  Aprovado
                </Text>
                <Paragraph>
                  O pagamento é instantâneo e sua reserva é aprovada na hora.
                </Paragraph>
              </>
            );
          } else {
            return (
              <>
                <Text strong={true}>Pix: </Text>
                <Text strong={true} style={{ color: "red" }}>
                  Não liberado
                </Text>
                <Paragraph>
                  Sua permissão para efetuar pagamentos com Pix ainda não foi
                  liberada.
                </Paragraph>
              </>
            );
          }
        })()}
      </div>
    );
  };

  renderCreditCardPaymentSection = () => {
    const { paymentMethods } = this.props;

    const userHasCreditCardPaymentMethod = paymentMethods.includes(
      PaymentMethods.CREDIT_CARD
    );

    return (
      <div className={style.paymentMethodSection}>
        {(() => {
          if (!userHasCreditCardPaymentMethod) {
            return (
              <>
                <Text strong={true}>Cartão de crédito: </Text>
                <Text strong={true} style={{ color: "green" }}>
                  Aprovado
                </Text>
                <Paragraph>
                  A sua reserva é aprovada automaticamente após a identificação
                  do pagamento, que costuma levar até 2 minutos.
                </Paragraph>
              </>
            );
          } else {
            return (
              <>
                <Text strong={true}>Cartão de crédito: </Text>
                <Text strong={true} style={{ color: "red" }}>
                  Não liberado
                </Text>
                <Paragraph>
                  Sua permissão para pagar com cartão de crédito ainda não foi
                  liberada.
                </Paragraph>
              </>
            );
          }
        })()}
      </div>
    );
  };

  render() {
    const { history } = this.props;
    const { requestStatus } = this.state;

    return (
      <>
        <Helmet>
          <title>Pagamento | Vai de Van</title>
        </Helmet>
        <div
          className={"main-container"}
          style={{
            marginTop: "20px"
          }}
        >
          <div style={{ width: "300px" }}>
            {(() => {
              if (requestStatus.status === Status.LOADING) {
                return <Loading loadingMessage={requestStatus.message} />;
              }

              if (requestStatus.status === Status.SUCCESS) {
                return (
                  <div>
                    <Title level={4} className={style.header}>
                      Pagamento
                    </Title>
                    <Paragraph>
                      Abaixo estão os meios de pagamento que você pode usar para
                      efetuar reservas
                    </Paragraph>
                    {/*{this.renderF2fPaymentSection()}*/}
                    {this.renderPixPaymentSection()}
                    {this.renderCreditCardPaymentSection()}
                    <Text strong={true}>Outras formas de pagamento</Text>
                    <Paragraph>
                      Estamos trabalhando constantemente para adicionar novas
                      formas de pagamento à plataforma para dar mais liberdade
                      de escolha aos nossos usuários.
                    </Paragraph>
                  </div>
                );
              }

              if (requestStatus.status === Status.ERROR) {
                return (
                  <Result
                    status="warning"
                    title="Erro ao carregar seus meios de pagamento"
                    subTitle={requestStatus.message}
                    extra={[
                      <Button
                        key="goBack"
                        size="large"
                        onClick={() => history.push(InternalUrl.HOME)}
                      >
                        Voltar
                      </Button>,
                      <Button
                        key="retry"
                        type="primary"
                        size="large"
                        onClick={this.fetchF2fPaymentStatus}
                      >
                        Entendi
                      </Button>
                    ]}
                  />
                );
              }

              return null;
            })()}
          </div>
        </div>
      </>
    );
  }
}

PaymentProfile.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = state => {
  return {
    paymentMethods: state.userInfoReducer.paymentMethods
  };
};

export default connect(mapStateToProps)(PaymentProfile);
