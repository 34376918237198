import React from "react";
import { getParamsFromUrl } from "../util/routeUtils";

export const remountOnUrlParamsChange = Component => {
  class HOC extends React.Component {
    state = {
      key: 1
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
      const prevParams = getParamsFromUrl(prevProps);
      const newParams = getParamsFromUrl(this.props);

      Object.entries(prevParams).forEach(([key, value]) => {
        //if URL params changes
        if (newParams[key] !== value) {
          const { key } = this.state;

          //increment key
          this.setState({
            key: key + 1
          });
        }
      });
    }

    render() {
      const { key } = this.state;

      //if the key prop of a component changes, it will be destroyed and remounted
      return <Component {...this.props} key={key} />;
    }
  }

  return HOC;
};
