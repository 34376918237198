import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Modal, Form, Button, Icon } from "antd";
import { patchUser } from "queries/queries";
import { withStore } from "hocs/withStore";
import "./styles.css";
import MaskedInput from "../../maskedInput";

const FormFields = Object.freeze({
  PHONE: "phone"
});

class AddPhoneNumberModal extends Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    const { callback, form } = this.props;
    const { validateFields, setFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const phone = values[FormFields.PHONE];

        this.setState({
          loading: true
        });

        patchUser({ phone })
          .then(response => {
            this.setState({
              loading: false
            });

            callback(true);
          })
          .catch(error => {
            this.setState({
              loading: false
            });

            /* eslint-disable */
            const newFieldsValue = {};
            lodash.set(newFieldsValue, FormFields.PHONE, {value: '', errors: [new Error(error.message)]});
            /* eslint-enable */

            setFields(newFieldsValue);
          });
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { visible, callback, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title={
          <span className="login-form-header">
            Adicione um número de celular para continuar
          </span>
        }
        visible={visible}
        centered
        onCancel={() => {
          callback();
        }}
        footer={null}
        className={"container"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <Form onSubmit={this.handleSubmit} className={"login-form"}>
            <Form.Item>
              {getFieldDecorator(FormFields.PHONE, {
                initialValue: "",
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value.length === 0) {
                        callback("Por favor, digite o numero do seu celular.");
                      } else if (value.length < 11) {
                        callback("Digite um número de celular válido.");
                      }

                      callback();
                    }
                  }
                ]
              })(
                <MaskedInput
                  mask="11 1 1111-1111"
                  placeholder="Celular"
                  size="large"
                  prefix={<Icon type="phone" className="form-icon" />}
                  inputMode="numeric"
                />
              )}
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={"login-form-button"}
              loading={loading}
              size={"large"}
            >
              Confirmar
            </Button>
          </Form>
        </div>
      </Modal>
    );
  }
}

AddPhoneNumberModal.propTypes = {
  history: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  storeState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool.isRequired
};

export default lodash.flow([withStore, Form.create({ name: "phone-modal" })])(
  AddPhoneNumberModal
);
