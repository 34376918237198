const PREFIX = "configs/";
export const RESET_NOTIFICATION_INTERVAL_TO_DEFAULT = `${PREFIX}RESET_NOTIFICATION_INTERVAL_TO_DEFAULT`;
export const SET_NOTIFICATION_INTERVAL = `${PREFIX}SET_NOTIFICATION_INTERVAL`;
export const GET_GOOGLE_LOGIN_ENABLED = `${PREFIX}GET_GOOGLE_LOGIN_ENABLED`;
export const GET_GOOGLE_LOGIN_ENABLED_PENDING = `${GET_GOOGLE_LOGIN_ENABLED}_PENDING`;
export const GET_GOOGLE_LOGIN_ENABLED_FULFILLED = `${GET_GOOGLE_LOGIN_ENABLED}_FULFILLED`;
export const GET_GOOGLE_LOGIN_ENABLED_REJECTED = `${GET_GOOGLE_LOGIN_ENABLED}_REJECTED`;
export const GET_ROUTE_SEARCH_STYLE_ENABLED = `${PREFIX}GET_ROUTE_SEARCH_STYLE_ENABLED`;
export const GET_ROUTE_SEARCH_STYLE_ENABLED_PENDING = `${GET_ROUTE_SEARCH_STYLE_ENABLED}_PENDING`;
export const GET_ROUTE_SEARCH_STYLE_ENABLED_FULFILLED = `${GET_ROUTE_SEARCH_STYLE_ENABLED}_FULFILLED`;
export const GET_ROUTE_SEARCH_STYLE_ENABLED_REJECTED = `${GET_ROUTE_SEARCH_STYLE_ENABLED}_REJECTED`;
