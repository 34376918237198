import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import history from "../../services/history";
import {
  Badge,
  Button,
  Divider,
  Layout,
  Icon,
  Menu,
  Tooltip,
  message
} from "antd";
import { InternalUrl, SubscriptionsUrl } from "../../constants/urls";
import Sidebar from "react-sidebar";
import { NotificationTypes } from "../../constants/notificationTypes";
import { getTheme, markNotificationAsRead } from "../../queries/queries";
import { clearToken, getToken } from "../../services/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import showModal from "../../util/modalHandler";
import {
  findInternalUrlThatMatchesRoute,
  getParamsFromUrlSearchString,
  goToLocationForceRefresh
} from "../../util/routeUtils";
import FeedbackModal from "../modals/feedback/FeedbackModal";
import { matchPath, withRouter } from "react-router-dom";
import {
  removeNotification,
  updateNotifications
} from "../../redux/actions/notifications";
import { withRoles } from "../../hocs/withRoles";
import NotificationDrawer from "./subComponents/notificationDrawer/NotificationDrawer";
import { EventSourcePolyfill } from "event-source-polyfill";
import Sound from "react-sound";
import { setScrollPosition } from "../../redux/actions/events";
import classNames from "classnames/bind";
import { setTheme } from "../../redux/actions/theme";
import { storeCookie } from "../../util/cookieUtils";
import { CookieKey } from "../../constants/cookieKey";
import AddPhoneNumberModal from "../modals/addPhoneNumber/AddPhoneNumberModal";

const logo = require("assets/img/white_no_background.png");
const written_logo = require("assets/img/name_black.png");

const { Header } = Layout;
const { SubMenu } = Menu;

const SIDEBAR_WIDTH = "256px";
const ANTD_DARK_COLOR = "#001529";
const ANTD_LIGHT_COLOR = "#fff";
const SIDEBAR_DIVIDER_COLOR = "rgba(232,232,232,0.5)";
const ANTD_BORDER_BOTTOM = "1px solid #e8e8e8";

const DrawerTypes = Object.freeze({
  DEFAULT_NOTIFICATIONS: "DEFAULT_NOTIFICATIONS",
  CHAT_NOTIFICATIONS: "CHAT_NOTIFICATIONS"
});

const SidebarDivider = () => (
  <Divider
    type={"horizontal"}
    style={{ backgroundColor: SIDEBAR_DIVIDER_COLOR, margin: "8px 0" }}
  />
);

class HeaderAndSidebar extends Component {
  notificationsEventSource = null;
  notificationsList = [];
  notificationsTimeout = null;
  isConsumingNotifications = false;
  contentDiv = React.createRef();

  /*
  This is the items tree on the sidebar.
  These items may have as many levels as you want, as long as you follow the rules below.
  All and only the first-level items must have an icon.
  All and only the leaf items must have a linkTo property.
  All items must have an unique key property.
  */
  MENU_ITEMS = {
    SEARCH_TRIPS: {
      label: "Procurar viagens",
      icon: "search",
      linkTo: InternalUrl.HOME,
      key: "searchTrips"
    },
    //TODO use this object MY_TRIPS below when past trips is implemented
    // MY_TRIPS: {
    //   label: "Minhas viagens",
    //   icon: "car",
    //   key: "myTrips",
    //   subItems: [
    //     {
    //       label: "Agendadas",
    //       linkTo: InternalUrl.MY_TRIPS_UPCOMING,
    //       key: "myTrips-upcoming"
    //     },
    //     {
    //       label: "Realizadas",
    //       linkTo: InternalUrl.HOME,
    //       key: "myTrips-past"
    //     }
    //   ]
    // },
    MY_TRIPS: {
      label: "Viagens agendadas",
      icon: "car",
      linkTo: InternalUrl.MY_TRIPS_UPCOMING,
      key: "myTrips"
    },
    NOTIFICATIONS: {
      label: "Notificações",
      icon: "bell",
      linkTo: InternalUrl.CHATS, //TODO
      key: "notifications"
    },
    // CHATS: {
    //   label: "Chat",
    //   icon: "message",
    //   linkTo: InternalUrl.CHATS,
    //   key: "chats"
    // },
    RATE: {
      label: "Avaliar",
      icon: "star",
      action: () => {
        showModal(FeedbackModal, { roles: this.props.roles });
      },
      key: "rate"
    },
    LOGIN: {
      label: "Entrar",
      icon: "login",
      linkTo: InternalUrl.LOGIN,
      key: "login"
    },
    LOGOUT: {
      label: "Sair",
      icon: "logout",
      action: () => {
        clearToken();
        history.push(InternalUrl.HOME);
      },
      key: "logout"
    },
    SIGN_UP: {
      label: "Cadastre-se",
      icon: "form",
      linkTo: InternalUrl.SIGNUP,
      key: "signUp"
    },
    // PROFILE: {
    //   label: "Perfil",
    //   icon: "user",
    //   linkTo: InternalUrl.PROFILE,
    //   key: "profile"
    // },
    PROFILE: {
      label: "Perfil",
      icon: "user",
      key: "profile",
      subItems: [
        {
          label: "Meus dados",
          linkTo: InternalUrl.PROFILE,
          key: "profile-userInfo"
        },
        {
          label: "Pagamento",
          linkTo: InternalUrl.PAYMENT,
          key: "profile-payment"
        }
      ]
    }
  };

  /**
   * Map routes to be matched to the same Menu Item of another route
   * e.g., Chat route can be mapped to match Chats Menu Item
   */
  ADDITIONAL_ROUTES_MAPPING = [
    {
      routes: [InternalUrl.CHAT],
      mapTo: InternalUrl.CHATS
    }
  ];

  desktopNotAuthenticatedMenu = [
    this.MENU_ITEMS.SEARCH_TRIPS,
    {
      ...this.MENU_ITEMS.SIGN_UP,
      icon: null
    },
    {
      ...this.MENU_ITEMS.LOGIN,
      icon: null
    },
    {
      icon: "more",
      key: "more",
      subItems: [this.MENU_ITEMS.RATE]
    }
  ];

  desktopAuthenticatedMenu = [
    this.MENU_ITEMS.SEARCH_TRIPS,
    this.MENU_ITEMS.MY_TRIPS,
    // {
    //   ...this.MENU_ITEMS.CHATS,
    //   label: null
    // },
    {
      ...this.MENU_ITEMS.NOTIFICATIONS,
      label: null
    },
    {
      icon: "more",
      key: "more",
      subItems: [
        this.MENU_ITEMS.PROFILE,
        this.MENU_ITEMS.RATE,
        this.MENU_ITEMS.LOGOUT
      ]
    }
  ];

  mobileNotAuthenticatedHeaderMenu = [
    {
      ...this.MENU_ITEMS.LOGIN,
      icon: null
    },
    {
      icon: "more",
      key: "more",
      subItems: [this.MENU_ITEMS.SIGN_UP, this.MENU_ITEMS.RATE]
    }
  ];

  mobileNotAuthenticatedSidebarMenu = [
    this.MENU_ITEMS.SEARCH_TRIPS,
    this.MENU_ITEMS.RATE
  ];

  mobileAuthenticatedHeaderMenu = [
    // {
    //   ...this.MENU_ITEMS.CHATS,
    //   label: null
    // },
    {
      ...this.MENU_ITEMS.NOTIFICATIONS,
      label: null
    }
  ];

  mobileAuthenticatedSidebarMenu = [
    this.MENU_ITEMS.SEARCH_TRIPS,
    this.MENU_ITEMS.MY_TRIPS,
    this.MENU_ITEMS.RATE
  ];

  state = {
    sidebarOpen: false,
    openedDrawer: undefined
  };

  componentDidMount() {
    const { roles } = this.props;
    history.listen(location => this.updateMenuEntryMatchingUrl(location));
    this.updateMenuEntryMatchingUrl(history.location);

    if (roles.isUser) {
      this.subscribeToNotifications();
    }

    this.getTheme();

    const { referralCode } = getParamsFromUrlSearchString(this.props);
    if (referralCode) {
      storeCookie(CookieKey.REFERRAL_CODE, referralCode, {
        maxAge: 31104000 //this is 360 days
      });
      goToLocationForceRefresh(this.props.history.location.pathname, true); //removes the referralCode from url
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { roles: prevRoles } = prevProps;
    const { roles } = this.props;

    //if user logs in, subscribe to notifications
    if (!prevRoles.isUser && roles.isUser) {
      this.subscribeToNotifications();
    }

    //if user logs out, unsubscribe from notifications
    if (prevRoles.isUser && !roles.isUser) {
      this.unsubscribeFromNotifications();
    }

    const {
      chatNotifications: oldChatNotifications,
      defaultNotifications: oldDefaultNotifications
    } = prevProps;
    const {
      chatNotifications: newChatNotifications,
      defaultNotifications: newDefaultNotifications
    } = this.props;
    const oldNotificationIds = oldChatNotifications
      .concat(oldDefaultNotifications)
      .map(notification => notification.id);
    const newNotificationIds = newChatNotifications
      .concat(newDefaultNotifications)
      .map(notification => notification.id);

    const addedNotificationIds = [];
    newNotificationIds.forEach(newNotificationId => {
      if (
        oldNotificationIds.find(
          oldNotificationId => oldNotificationId === newNotificationId
        ) === undefined
      ) {
        addedNotificationIds.push(newNotificationId);
      }
    });

    if (addedNotificationIds.length > 0) {
      this.playNotificationSound();
    }

    const {
      randomNumberToTriggerRestore,
      lastRestoredScrollPosition
    } = this.props;
    const {
      randomNumberToTriggerRestore: prevRandomNumberToTriggerRestore
    } = prevProps;

    if (randomNumberToTriggerRestore !== prevRandomNumberToTriggerRestore) {
      this.contentDiv.current.scrollTop = lastRestoredScrollPosition;
    }

    //if notification interval is updated, start consuming now
    const { notificationInterval } = this.props;
    const { notificationInterval: prevNotificationInterval } = prevProps;
    if (
      notificationInterval !== prevNotificationInterval &&
      !this.isConsumingNotifications
    ) {
      this.subscribeToNotifications();
    }

    //if user logs in, check his phone number
    if (!prevRoles.isUser && roles.isUser) {
      const { phoneNumber } = this.props;
      if (!roles.verifiedPhone && !phoneNumber) {
        //logged in user has no phone number
        if (phoneNumber) {
          message.success("Agora você só precisa verificar o seu celular");
          history.push(InternalUrl.VERIFY_PHONE);
        } else {
          showModal(AddPhoneNumberModal, {}, data => {
            if (data) {
              message.success("Agora você só precisa verificar o seu celular");
              history.push(InternalUrl.VERIFY_PHONE);
            }
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromNotifications();
  }

  getTheme = () => {
    const { setTheme } = this.props;
    getTheme()
      .then(response => {
        const { blackLogoUrl, whiteLogoUrl, logoTooltip, coverText } = response;
        setTheme(blackLogoUrl, whiteLogoUrl, logoTooltip, coverText);
      })
      .catch(error => {
        //do nothing
      });
  };

  contentDivOnScroll = () => {
    const { setScrollPosition } = this.props;

    const {
      current: { scrollTop }
    } = this.contentDiv;
    setScrollPosition(scrollTop);
  };

  subscribeToNotifications = () => {
    if (this.notificationsEventSource !== null) return;

    const token = getToken();

    this.notificationsEventSource = new EventSourcePolyfill(
      SubscriptionsUrl.NOTIFICATIONS,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    this.notificationsEventSource.onmessage = e => {
      const notification = JSON.parse(e.data);
      this.handleNotificationReceived(notification);
    };

    this.notificationsEventSource.onerror = e => {
      this.handleSubscriptionError();
    };

    this.isConsumingNotifications = true;
  };

  unsubscribeFromNotifications = () => {
    if (this.notificationsEventSource === null) return;

    this.notificationsEventSource.close();
    this.notificationsEventSource = null;
    this.isConsumingNotifications = false;
  };

  shouldRefreshPageOnNotificationReceived = destinationRoute => {
    switch (destinationRoute) {
      case InternalUrl.RESERVATION:
        return true;
      default:
        return false;
    }
  };

  handleNotificationReceived = notification => {
    const {
      history: {
        location: { pathname }
      }
    } = this.props;

    const { type, resourceId } = notification;

    const internalUrlRoute = findInternalUrlThatMatchesRoute(pathname);
    const destinationUrl = this.getDestinationInternalUrlForNotification(
      type,
      resourceId
    );

    let shouldMarkNotificationAsReadAndAutoRefreshPage =
      internalUrlRoute.url === destinationUrl;

    //fot these notification types we don't want to auto read notification and refresh the page
    if (type === NotificationTypes.PIX_PAYMENT_REVERSED) {
      shouldMarkNotificationAsReadAndAutoRefreshPage = false;
    }

    if (shouldMarkNotificationAsReadAndAutoRefreshPage) {
      this.onNotificationClear(notification);

      if (this.shouldRefreshPageOnNotificationReceived(internalUrlRoute.path)) {
        goToLocationForceRefresh(destinationUrl, true);
      }
    } else {
      this.notificationsList.push(notification);
    }
  };

  handleSubscriptionError = () => {
    const { updateNotifications, notificationInterval } = this.props;

    updateNotifications(this.notificationsList);
    this.notificationsList = [];

    this.unsubscribeFromNotifications();
    if (this.notificationsTimeout) {
      clearTimeout(this.notificationsTimeout);
    }
    this.notificationsTimeout = setTimeout(() => {
      this.subscribeToNotifications();
    }, notificationInterval);
  };

  playNotificationSound = () => {
    this.setState({
      notificationSoundStatus: Sound.status.PLAYING
    });
  };

  stopNotificationSound = () => {
    this.setState({
      notificationSoundStatus: Sound.status.STOPPED
    });
  };

  updateMenuEntryMatchingUrl = location => {
    const locationClone = Object.assign({}, location);

    // Map routes to the same Menu Item as other routes
    this.ADDITIONAL_ROUTES_MAPPING.forEach(({ routes, mapTo }) => {
      routes.forEach(route => {
        if (
          matchPath(locationClone.pathname, {
            path: route,
            exact: true,
            strict: false
          })
        ) {
          locationClone.pathname = mapTo;
        }
      });
    });

    Object.entries(this.MENU_ITEMS).forEach(([, value]) => {
      const match = matchPath(locationClone.pathname, {
        path: value.linkTo,
        exact: true,
        strict: false
      });

      if (match) {
        this.setState({
          selectedKeys: [value.key]
        });
      }
    });
  };

  onSetSidebarOpen = open => {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: open ? open : !sidebarOpen });
  };

  getDestinationInternalUrlForNotification = (type, resourceId) => {
    switch (type) {
      case NotificationTypes.NEW_RESERVATION:
      case NotificationTypes.CONFIRM_RESERVATION:
      case NotificationTypes.CANCEL_RESERVATION:
      case NotificationTypes.PAYMENT_FAILED_RESERVATION:
      case NotificationTypes.PIX_PAYMENT_REVERSED:
        return InternalUrl.RESERVATION.replace(":reservationCode", resourceId);
      case NotificationTypes.TRIP_CONFIRMATION:
        return InternalUrl.TRIP_DETAILS.replace(":tripId", resourceId);
      case NotificationTypes.CHAT:
        return InternalUrl.CHAT.replace(":chatCode", resourceId);
      default:
        return null;
    }
  };

  onNotificationClick = ({ id, type, resourceId }) => {
    const destinationUrl = this.getDestinationInternalUrlForNotification(
      type,
      resourceId
    );
    if (destinationUrl) {
      goToLocationForceRefresh(destinationUrl);
    }

    this.onNotificationClear({ id });
    this.closeDrawer();
  };

  onNotificationClear = notification => {
    const { removeNotification } = this.props;

    removeNotification(notification);
    markNotificationAsRead(notification.id)
      .then(response => {})
      .catch(error => {});
  };

  onMenuClick = item => {
    this.setState({
      sidebarOpen: false
    });
    if (item.linkTo) {
      history.push(item.linkTo);
    } else {
      item.action.call();
    }
  };

  renderMenuEntry = entry => {
    //Handle specific menu entries
    // if (
    //   entry.key === this.MENU_ITEMS.NOTIFICATIONS.key ||
    //   entry.key === this.MENU_ITEMS.CHATS.key
    // ) {
    if (entry.key === this.MENU_ITEMS.NOTIFICATIONS.key) {
      const { defaultNotifications, chatNotifications } = this.props;

      const notificationsList =
        entry.key === this.MENU_ITEMS.NOTIFICATIONS.key
          ? defaultNotifications
          : chatNotifications;
      return (
        <Menu.Item
          key={entry.key}
          onClick={() => {
            // if (entry.key === this.MENU_ITEMS.CHATS.key) {
            //   if (chatNotifications.length > 0) {
            //     this.openDrawer(DrawerTypes.CHAT_NOTIFICATIONS);
            //   } else {
            //     history.push(InternalUrl.CHATS);
            //   }
            // } else {
            this.openDrawer(DrawerTypes.DEFAULT_NOTIFICATIONS);
            // }
          }}
        >
          <div>
            <Badge
              count={notificationsList.length}
              style={{ marginRight: entry.label ? "10px" : 0 }}
            >
              <Icon
                type={entry.icon}
                style={{
                  marginRight: entry.label ? "10px" : 0,
                  fontSize: "18px"
                }}
              />
            </Badge>
            <span>{entry.label}</span>
          </div>
        </Menu.Item>
      );
    }

    if (!entry.subItems) {
      //last level menu
      if (entry.icon) {
        return (
          <Menu.Item key={entry.key} onClick={() => this.onMenuClick(entry)}>
            <Icon
              type={entry.icon}
              style={{ marginRight: entry.label ? null : 0 }}
            />
            <span>{entry.label}</span>
          </Menu.Item>
        );
      } else {
        return (
          <Menu.Item key={entry.key} onClick={() => this.onMenuClick(entry)}>
            {entry.label}
          </Menu.Item>
        );
      }
    } else {
      if (entry.icon) {
        return (
          <SubMenu
            key={entry.key}
            title={
              <span>
                <Icon
                  className={"topbar-icon"}
                  type={entry.icon}
                  style={{
                    marginRight: entry.label ? null : 0
                  }}
                />
                <span>{entry.label}</span>
              </span>
            }
          >
            {entry.subItems.map(subItem => this.renderMenuEntry(subItem))}
          </SubMenu>
        );
      } else {
        return (
          <SubMenu key={entry.key} title={entry.label}>
            {entry.subItems.map(subItem => this.renderMenuEntry(subItem))}
          </SubMenu>
        );
      }
    }
  };

  renderHeaderMenu = () => {
    const { selectedKeys } = this.state;
    const {
      isDesktop,
      roles: { isUser }
    } = this.props;
    let menu;
    if (isDesktop) {
      menu = isUser
        ? this.desktopAuthenticatedMenu
        : this.desktopNotAuthenticatedMenu;
    } else {
      menu = isUser
        ? this.mobileAuthenticatedHeaderMenu
        : this.mobileNotAuthenticatedHeaderMenu;
    }

    //TODO: highlight item when current route changes.
    return (
      <Menu
        // defaultSelectedKeys={["0"]}
        // defaultOpenKeys={["sub1"]}
        selectedKeys={selectedKeys}
        mode="horizontal"
        theme="light"
      >
        {menu.map(item => {
          return this.renderMenuEntry(item);
        })}
      </Menu>
    );
  };

  renderSidebarMenu = () => {
    const { selectedKeys } = this.state;
    const {
      roles: { isUser }
    } = this.props;
    const menuItems = isUser
      ? this.mobileAuthenticatedSidebarMenu
      : this.mobileNotAuthenticatedSidebarMenu;

    //TODO: highlight item when current route changes.
    return (
      <Menu
        // defaultSelectedKeys={["myTrips-upcoming"]}
        // defaultOpenKeys={["myTrips"]}
        selectedKeys={selectedKeys}
        mode="inline"
        theme="dark"
      >
        {(menuEntry => {
          return this.renderMenuEntry(menuEntry);
        })(this.MENU_ITEMS.PROFILE)}
        <SidebarDivider />
        {menuItems.map(item => {
          return this.renderMenuEntry(item);
        })}
        <SidebarDivider />
        <Menu.Item
          key={this.MENU_ITEMS.LOGOUT.key}
          onClick={() => this.onMenuClick({ ...this.MENU_ITEMS.LOGOUT })}
        >
          <Icon
            type={this.MENU_ITEMS.LOGOUT.icon}
            style={{ marginRight: null }}
          />
          <span>{this.MENU_ITEMS.LOGOUT.label}</span>
        </Menu.Item>
      </Menu>
    );
  };

  renderSidebarContent = () => (
    <>
      <Header style={{ color: "#fff", padding: "0 0 0 25px" }}>
        <img src={logo} alt="Logo" height={"30px"} />
        <img
          src={written_logo}
          alt="Vai de Van"
          height={"54px"}
          style={{ marginLeft: "10px", filter: "invert(100%)" }}
        />
      </Header>
      {this.renderSidebarMenu()}
    </>
  );

  openDrawer = drawerType => {
    this.setState({
      openedDrawer: drawerType
    });
  };

  closeDrawer = () => {
    this.setState({
      openedDrawer: undefined
    });
  };

  renderNotificationsDrawer = () => {
    const { openedDrawer } = this.state;
    const { defaultNotifications } = this.props;
    const isDrawerOpen = openedDrawer === DrawerTypes.DEFAULT_NOTIFICATIONS;

    return (
      <NotificationDrawer
        title="Notificações"
        isDrawerOpen={isDrawerOpen}
        notifications={defaultNotifications}
        emptyNotificationsMessage="Não há notificações não lidas"
        onNotificationClick={this.onNotificationClick}
        onNotificationClear={this.onNotificationClear}
        onClose={this.closeDrawer}
      />
    );
  };

  renderChatNotificationsDrawer = () => {
    const { openedDrawer } = this.state;
    const { chatNotifications, history } = this.props;
    const isDrawerOpen = openedDrawer === DrawerTypes.CHAT_NOTIFICATIONS;

    return (
      <NotificationDrawer
        title="Mensagens"
        isDrawerOpen={isDrawerOpen}
        notifications={chatNotifications}
        emptyNotificationsMessage="Não há mensagens não lidas"
        onNotificationClick={this.onNotificationClick}
        onNotificationClear={this.onNotificationClear}
        onClose={this.closeDrawer}
        footerAction={{
          text: "Ver todas as mensagens",
          callback: () => {
            history.push(InternalUrl.CHATS);
            this.closeDrawer();
          }
        }}
      />
    );
  };

  render() {
    const { sidebarOpen } = this.state;
    const { themedLogoUrl, logoTooltip } = this.props;
    const {
      children,
      isDesktop,
      roles: { isUser }
    } = this.props;

    return (
      <Sidebar
        sidebarId={"sidebar"}
        overlayId={"sidebarOverlay"}
        sidebar={this.renderSidebarContent()}
        docked={false}
        open={isUser && sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        touch={false}
        styles={{
          sidebar: { background: ANTD_DARK_COLOR, width: SIDEBAR_WIDTH }
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "0",
            width: "100%",
            height: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div
            className={"antd-customize"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: ANTD_LIGHT_COLOR,
              borderBottom: ANTD_BORDER_BOTTOM
            }}
          >
            <div
              style={{
                color: ANTD_DARK_COLOR,
                display: "flex",
                alignItems: "center"
              }}
            >
              {isUser && !isDesktop && (
                <Button
                  type="primary"
                  style={{
                    backgroundColor: ANTD_LIGHT_COLOR,
                    border: "none",
                    boxShadow: "none"
                  }}
                  onClick={() => this.onSetSidebarOpen(true)}
                >
                  <Icon type={"menu"} style={{ color: ANTD_DARK_COLOR }} />
                </Button>
              )}
              <div
                className={classNames(
                  "clickable",
                  "written-logo",
                  isDesktop || !isUser ? "margin-left" : null
                )}
                onClick={() => history.push(InternalUrl.HOME)}
              >
                <Tooltip placement="bottom" title={logoTooltip}>
                  <img
                    src={themedLogoUrl || written_logo}
                    alt="Logo Vai de Van"
                  />
                </Tooltip>
              </div>
            </div>
            {this.renderHeaderMenu()}
          </div>
          <div
            style={{ flexGrow: "1", width: "100%", overflowY: "auto" }}
            ref={this.contentDiv}
            onScroll={lodash.debounce(this.contentDivOnScroll, 100)}
          >
            {children}
          </div>
        </div>
        {this.renderNotificationsDrawer()}
        {this.renderChatNotificationsDrawer()}
      </Sidebar>
    );
  }
}

HeaderAndSidebar.defaultProps = {
  themedLogoUrl: undefined,
  logoTooltip: ""
};

HeaderAndSidebar.propTypes = {
  history: PropTypes.any.isRequired,
  children: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  roles: PropTypes.object.isRequired,
  defaultNotifications: PropTypes.array.isRequired,
  chatNotifications: PropTypes.array.isRequired,
  setScrollPosition: PropTypes.func.isRequired,
  updateNotifications: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  randomNumberToTriggerRestore: PropTypes.number.isRequired,
  lastRestoredScrollPosition: PropTypes.number.isRequired,
  notificationInterval: PropTypes.number.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  themedLogoUrl: PropTypes.string,
  logoTooltip: PropTypes.string
};

const mapStateToProps = ({
  eventsReducer,
  notificationsReducer,
  configsReducer,
  themeReducer,
  userInfoReducer
}) => ({
  defaultNotifications: notificationsReducer.defaultNotifications,
  chatNotifications: notificationsReducer.chatNotifications,
  randomNumberToTriggerRestore: eventsReducer.randomNumberToTriggerRestore,
  lastRestoredScrollPosition: eventsReducer.lastRestoredScrollPosition,
  notificationInterval: configsReducer.notificationInterval,
  themedLogoUrl: themeReducer.blackLogoUrl,
  logoTooltip: themeReducer.logoTooltip,
  phoneNumber: userInfoReducer.phoneNumber
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setScrollPosition: setScrollPosition,
      updateNotifications: updateNotifications,
      removeNotification: removeNotification,
      setTheme: setTheme
    },
    dispatch
  );
};

export default lodash.flow([
  withRouter,
  withRoles,
  connect(mapStateToProps, mapDispatchToProps)
])(HeaderAndSidebar);
