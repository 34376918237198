import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { getParamsFromUrlSearchString } from "util/routeUtils";
import { Helmet } from "react-helmet";
import { verifyEmail, resendVerifyEmail } from "queries/queries";
import { Button, Result } from "antd";
import Loading from "components/loading";
import { setToken } from "services/auth";
import { InternalUrl } from "constants/urls";
import { withRoles } from "hocs/withRoles";
import RequestStatus, { Status } from "../../model/RequestStatus";
import "./styles.css";
import lodash from "lodash";
import EmailLink from "../../components/emailLink/EmailLink";

class VerifyEmail extends Component {
  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    resendEmailRequestStatus: new RequestStatus(Status.NOT_STARTED),
    fromEmail: false
  };

  componentDidMount() {
    const { rememberMe } = this.props;
    const { code } = getParamsFromUrlSearchString(this.props);

    if (code) {
      this.setState({
        requestStatus: new RequestStatus(
          Status.LOADING,
          "Verificando email..."
        ),
        fromEmail: true
      });

      verifyEmail(code)
        .then(token => {
          setToken(token, rememberMe);

          this.setState({
            requestStatus: new RequestStatus(Status.SUCCESS)
          });
        })
        .catch(error => {
          this.setState({
            requestStatus: new RequestStatus(Status.ERROR, error.message)
          });
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      roles: { verifiedEmail: oldVerifiedEmail }
    } = prevProps;
    const {
      history,
      roles: { verifiedEmail: newVerifiedEmail, verifiedPhone }
    } = this.props;

    if (!oldVerifiedEmail && newVerifiedEmail && !verifiedPhone) {
      history.push(InternalUrl.VERIFY_PHONE, {
        fromEmailVerification: true
      });
    }
  }

  onResendVerificationEmailClicked = () => {
    this.setState({
      resendEmailRequestStatus: new RequestStatus(
        Status.LOADING,
        "Reenviando email..."
      )
    });

    resendVerifyEmail()
      .then(() => {
        this.setState({
          resendEmailRequestStatus: new RequestStatus(Status.SUCCESS)
        });
      })
      .catch(error => {
        this.setState({
          resendEmailRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          )
        });
      });
  };

  render() {
    const { requestStatus, resendEmailRequestStatus, fromEmail } = this.state;
    const { history } = this.props;

    return (
      <>
        <Helmet>
          <title>Verificar email | Vai de Van</title>
        </Helmet>
        {(() => {
          if (!fromEmail) {
            if (resendEmailRequestStatus.status === Status.ERROR) {
              return (
                <Result
                  status="warning"
                  title="Erro ao reenviar email"
                  subTitle={
                    <>
                      <p>{resendEmailRequestStatus.message}</p>
                      <p>
                        Por favor, tente novamente. Se o problema persistir,
                        envie um email para{" "}
                        <EmailLink subject="Erro ao reenviar email de verificação" />{" "}
                        informando que há um erro ao tentar reenviar o email de
                        verificação.
                      </p>
                    </>
                  }
                  extra={
                    <Button
                      type="primary"
                      key="ok"
                      onClick={() => {
                        this.setState({
                          resendEmailRequestStatus: new RequestStatus(
                            Status.NOT_STARTED
                          )
                        });
                      }}
                    >
                      Entendi
                    </Button>
                  }
                />
              );
            } else {
              return (
                <div className="content">
                  <p>
                    Para concluir a verificação de email abra sua caixa de
                    entrada, procure pelo email que lhe enviamos e clique no
                    link.
                  </p>
                  <p>
                    Verifique a sua caixa de spam caso não encontre o email na
                    sua caixa de entrada.
                  </p>
                  <p className="section">
                    {(() => {
                      if (
                        resendEmailRequestStatus.status === Status.NOT_STARTED
                      ) {
                        return (
                          <>
                            Não recebeu o email?{" "}
                            <span
                              className="text-link"
                              onClick={this.onResendVerificationEmailClicked}
                            >
                              Clique aqui
                            </span>
                          </>
                        );
                      } else if (
                        resendEmailRequestStatus.status === Status.LOADING
                      ) {
                        return <>Reenviando email...</>;
                      } else {
                        return <>O email foi reenviado.</>;
                      }
                    })()}
                  </p>
                </div>
              );
            }
          } else {
            if (requestStatus.status === Status.LOADING) {
              return <Loading loadingMessage={requestStatus.message} />;
            }

            if (requestStatus.status === Status.ERROR) {
              return (
                <Result
                  status="warning"
                  title="Erro ao verificar email"
                  subTitle={requestStatus.message}
                  extra={
                    <Button
                      type="primary"
                      key="ok"
                      onClick={() => {
                        history.push(InternalUrl.HOME);
                      }}
                    >
                      Voltar ao App
                    </Button>
                  }
                />
              );
            }

            if (requestStatus.status === Status.SUCCESS) {
              return (
                <Result
                  status="success"
                  title={"Seu email foi verificado"}
                  subTitle={"Agora você pode efetuar reservas no Vai de Van."}
                  extra={
                    <Button
                      type="primary"
                      key="ok"
                      onClick={() => {
                        history.push(InternalUrl.HOME);
                      }}
                    >
                      Voltar ao App
                    </Button>
                  }
                />
              );
            }
          }
        })()}
      </>
    );
  }
}

VerifyEmail.propTypes = {
  history: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  rememberMe: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    rememberMe: state.userInfoReducer.rememberMe
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default lodash.flow([
  withRoles,
  connect(mapStateToProps, mapDispatchToProps)
])(VerifyEmail);
