export const Status = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
});

export default class RequestStatus {
  status = undefined;
  message = undefined;

  constructor(status, message = undefined) {
    this.status = status;
    this.message = message;
  }
}
