import buildUrl from "build-url";
import lodash from "lodash";
import api from "services/api";
import { clearObject } from "util/objectUtils";
import { GET_MORE_MESSAGES_AMOUNT } from "constants/backend";
import showModal from "../util/modalHandler";
import LoginModal from "../components/modals/login/LoginModal";
import queryString from "query-string";

const GET_REQUEST = "GET";
const POST_REQUEST = "POST";
const PATCH_REQUEST = "PATCH";
const DELETE_REQUEST = "DELETE";

const UNEXPECTED_ERROR_MESSAGE = "Algo inesperado aconteceu. Tente novamente.";
const UNAUTHORIZED_ERROR_MESSAGE =
  "Parece que sua sessão expirou ou você não tem permissão para fazer isso. Por favor, faça login e tente novamente.";

const Endpoint = {
  PAY_RESERVATION: "/v1/reservation/:reservationCode/payment"
};

const backendRequestMaker = async (requestType, url, body = {}) => {
  let response;

  try {
    if (requestType === GET_REQUEST) {
      response = await api.get(url);
    } else if (requestType === POST_REQUEST) {
      response = await api.post(url, body);
    } else if (requestType === PATCH_REQUEST) {
      response = await api.patch(url, body);
    } else if (requestType === DELETE_REQUEST) {
      response = await api.delete(url);
    }
  } catch (e) {
    const responseStatus = lodash.get(e, "response.status");

    if (responseStatus === 403 || responseStatus === 401) {
      showModal(LoginModal);

      e.message = UNAUTHORIZED_ERROR_MESSAGE;
      throw e;
    }
    e.message = UNEXPECTED_ERROR_MESSAGE;
    throw e;
  }

  if (response.data.status === "error") {
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }

  if (response.data.status === "invalid") {
    throw new Error(response.data.data);
  }

  return response.data.data;
};

export const login = (usernameOrCpf, password) => {
  const body = {
    usernameOrCpf: usernameOrCpf,
    password: password
  };

  return backendRequestMaker(POST_REQUEST, `/v2/auth/login`, body);
};

export const oAuthLogin = oAuthToken => {
  const body = {
    token: oAuthToken
  };

  return backendRequestMaker(POST_REQUEST, `/v1/oauth2`, body);
};

export const signup = (userInfo, referralCode) => {
  let referralCodeQueryString = queryString.stringify({ referralCode });
  if (referralCodeQueryString) {
    referralCodeQueryString = `?${referralCodeQueryString}`;
  }

  return backendRequestMaker(
    POST_REQUEST,
    `/v1/users/register${referralCodeQueryString}`,
    userInfo
  );
};

export const forgotPassword = email => {
  const body = {
    username: email
  };

  return backendRequestMaker(POST_REQUEST, `/v1/users/password/forgot`, body);
};

export const getUserProfile = () => {
  return backendRequestMaker(GET_REQUEST, `/v1/users/profile`);
};

export const getF2fPaymentStatus = () => {
  return backendRequestMaker(GET_REQUEST, `/v1/users/f2f-payment`);
};

export const requestF2fPayment = () => {
  return backendRequestMaker(POST_REQUEST, `/v1/users/f2f-payment`);
};

export const editUser = (name, phone, cpf, email, password) => {
  return backendRequestMaker(POST_REQUEST, `/v1/users/edit`, {
    name,
    phone,
    cpf,
    username: email,
    password
  });
};

export const patchUser = data => {
  return backendRequestMaker(PATCH_REQUEST, `/v1/users/`, data);
};

export const resetPassword = code => {
  return backendRequestMaker(POST_REQUEST, `/v1/users/password/forgot/${code}`);
};

export const changePassword = (oldPassword, newPassword) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };

  return backendRequestMaker(POST_REQUEST, `/v1/users/password/change`, body);
};

export const fetchTrips = (
  departure,
  arrival,
  departureDate,
  page,
  pageSize,
  showFullyBooked = false
) => {
  const url = buildUrl(`/v1/trips`, {
    queryParams: clearObject({
      departure,
      arrival,
      departureDate,
      page,
      pageSize,
      showFullyBooked
    })
  });
  return backendRequestMaker(GET_REQUEST, url);
};

export const fetchTripDetails = id => {
  return backendRequestMaker(GET_REQUEST, `/v1/trips/${id}`);
};

export const fetchAutoCompleteCities = searchText => {
  return backendRequestMaker(
    GET_REQUEST,
    `/v1/cities/autocomplete/${searchText}`
  );
};

export const getRoutes = async => {
  return backendRequestMaker(GET_REQUEST, "/v1/trips/routes");
};

export const getCity = async cityId => {
  return backendRequestMaker(GET_REQUEST, `/v1/cities/${cityId}`);
};

export const makeReservation = (tripId, seats) => {
  return backendRequestMaker(POST_REQUEST, "/v1/reservation", {
    numberOfSeats: seats,
    trip: {
      id: tripId
    }
  });
};

export const addPassengers = (code, passengers) => {
  return backendRequestMaker(
    POST_REQUEST,
    `/v1/reservation/${code}/passengers`,
    {
      passengers: passengers
    }
  );
};

export const getSavedPassengers = () => {
  return backendRequestMaker(GET_REQUEST, "/v1/passengers");
};

export const savePassenger = passenger => {
  return backendRequestMaker(POST_REQUEST, "/v1/passengers", passenger);
};

export const forgetPassenger = id => {
  return backendRequestMaker(DELETE_REQUEST, `/v1/passengers/${id}`);
};

export const getFeedbackCategories = () => {
  return backendRequestMaker(GET_REQUEST, "/v1/feedback/category");
};

export const getCompanyProfile = companyId => {
  return backendRequestMaker(GET_REQUEST, `/v1/companies/${companyId}/profile`);
};

export const sendFeedback = feedback => {
  return backendRequestMaker(POST_REQUEST, "/v1/feedback", feedback);
};

export const getUpcomingTripsWithReservations = () => {
  return backendRequestMaker(GET_REQUEST, "/v1/trips/upcoming");
};

export const getReservationFromTrip = tripId => {
  return backendRequestMaker(GET_REQUEST, `/v1/reservation/trip/${tripId}`);
};

export const getReservation = reservationCode => {
  return backendRequestMaker(GET_REQUEST, `/v1/reservation/${reservationCode}`);
};

export const addPassengerToReservation = (reservationCode, passengers) => {
  return backendRequestMaker(POST_REQUEST, `/v1/reservation/passengers`, {
    reservationCode,
    passengers
  });
};

export const cancelReservation = reservationCode => {
  return backendRequestMaker(
    DELETE_REQUEST,
    `/v1/reservation/${reservationCode}`
  );
};

export const getChats = (page, pageSize) => {
  const url = buildUrl(`/v1/chats`, {
    queryParams: clearObject({
      page,
      pageSize
    })
  });
  return backendRequestMaker(GET_REQUEST, url);
};

export const startChat = tripId => {
  return backendRequestMaker(POST_REQUEST, "/v1/chats/start", {
    trip: {
      id: tripId
    }
  });
};

export const sendChatMessage = (chatCode, messageText) => {
  return backendRequestMaker(POST_REQUEST, "/v1/chats/send", {
    chatCode: chatCode,
    value: messageText
  });
};

export const hasMessageBefore = (chatCode, messageId) => {
  const url = buildUrl(`/v1/chats/${chatCode}/hasMessageBefore`, {
    queryParams: {
      fromMessageId: messageId
    }
  });
  return backendRequestMaker(GET_REQUEST, url);
};

export const markMessageAsRead = messageId => {
  return backendRequestMaker(POST_REQUEST, `/v1/chats/read/${messageId}`);
};

export const getMoreMessages = (chatId, fromMessageId) => {
  const url = buildUrl(`/v1/chats/${chatId}/history`, {
    queryParams: {
      fromMessageId: fromMessageId,
      historySize: GET_MORE_MESSAGES_AMOUNT
    }
  });
  return backendRequestMaker(GET_REQUEST, url);
};

export const verifyEmail = code => {
  return backendRequestMaker(
    GET_REQUEST,
    `/v1/users/verification/email/${code}`
  );
};

export const resendVerifyEmail = () => {
  return backendRequestMaker(GET_REQUEST, `/v1/users/resend/verifyEmail`);
};

export const verifyPhone = code => {
  return backendRequestMaker(
    GET_REQUEST,
    `/v1/users/verification/phone/${code}`
  );
};

export const resendVerifyPhone = method => {
  const url = buildUrl(`/v1/users/resend/verifyPhone`, {
    queryParams: clearObject({
      method
    })
  });
  return backendRequestMaker(GET_REQUEST, url);
};

export const markNotificationAsRead = id => {
  return backendRequestMaker(POST_REQUEST, `/v1/notification/read/${id}`);
};

export const saveDeviceInfo = (
  systemName,
  systemVersion,
  browserName,
  browserVersion,
  isMobile,
  ipAddress
) => {
  return backendRequestMaker(POST_REQUEST, `/v1/devices`, {
    systemName,
    systemVersion,
    browserName,
    browserVersion,
    mobile: isMobile,
    ipAddress
  });
};

export const getStoredCreditCards = () => {
  return backendRequestMaker(GET_REQUEST, `/v1/payment/card`);
};

export const payReservationNewCreditCard = (reservationCode, creditCard) => {
  return backendRequestMaker(
    POST_REQUEST,
    `/v1/reservation/${reservationCode}/credit-card-payment`,
    creditCard
  );
};

export const payReservationSavedCreditCard = (
  reservationCode,
  creditCardId
) => {
  return backendRequestMaker(
    POST_REQUEST,
    Endpoint.PAY_RESERVATION.replace(":reservationCode", reservationCode),
    {
      creditCardId
    }
  );
};

export const payReservationF2fPayment = reservationCode => {
  return backendRequestMaker(
    POST_REQUEST,
    Endpoint.PAY_RESERVATION.replace(":reservationCode", reservationCode),
    {
      f2fPayment: true
    }
  );
};

export const getMaxF2fSeatsForPayment = () => {
  return backendRequestMaker(GET_REQUEST, "/v1/payment/f2f-payment/max-seats");
};

export const listPaymentMethodsforReservation = reservationCode => {
  return backendRequestMaker(
    GET_REQUEST,
    `/v1/payment/methods/${reservationCode}`
  );
};

export const getBoardingPointsForTripOrReservation = tripIdOrReservationCode => {
  return backendRequestMaker(
    GET_REQUEST,
    `/v1/places/boarding-points/${tripIdOrReservationCode}`
  );
};

export const addBoardingPointForTrip = (reservationCode, boardingPointId) => {
  return backendRequestMaker(
    POST_REQUEST,
    `/v1/reservation/${reservationCode}/boarding-point`,
    {
      id: boardingPointId
    }
  );
};

export const getPixPaymentDetails = reservationCode => {
  return backendRequestMaker(GET_REQUEST, `/v1/payment/pix/${reservationCode}`);
};

export const validateRequest = (code, type) => {
  return backendRequestMaker(POST_REQUEST, `/v1/requests/validate`, {
    code: code,
    type: type
  });
};

export const redefinePassword = (code, newPassword) => {
  return backendRequestMaker(POST_REQUEST, `/v1/users/password/redefine`, {
    code,
    newPassword
  });
};

export const getTheme = () => {
  return backendRequestMaker(GET_REQUEST, `/v1/theme`);
};

export const getCustomData = customDataKey => {
  return new Promise((resolve, reject) => {
    backendRequestMaker(GET_REQUEST, `/v1/customization/${customDataKey}/data`)
      .then(response => {
        try {
          resolve(JSON.parse(response));
        } catch (e) {
          resolve(undefined);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getPublicVariable = variable => {
  return backendRequestMaker(GET_REQUEST, `/v1/variables/${variable}/open`);
};
