import * as ThemeType from "../actionTypes/theme";

export const setTheme = (
  blackLogoUrl,
  whiteLogoUrl,
  logoTooltip,
  coverText
) => ({
  type: ThemeType.SET_THEME,
  payload: {
    blackLogoUrl,
    whiteLogoUrl,
    logoTooltip,
    coverText
  }
});
