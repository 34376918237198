import lodash from "lodash";
import * as TokenType from "../actionTypes/token";

export const updateToken = parsedToken => {
  if (!lodash.isEmpty(parsedToken)) {
    return {
      type: TokenType.UPDATE_TOKEN,
      payload: parsedToken
    };
  } else {
    return clearToken();
  }
};

export const clearToken = () => ({
  type: TokenType.CLEAR_TOKEN
});

export const setRememberMe = rememberMe => ({
  type: TokenType.SET_REMEMBER_ME,
  payload: rememberMe
});
