import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Helmet } from "react-helmet";
import {
  Alert,
  Col,
  Row,
  Button,
  Tooltip,
  Steps,
  message,
  Result,
  Typography,
  Modal
} from "antd";
import Icon from "@mdi/react";
import { mdiVanPassenger, mdiSeatReclineExtra, mdiCircle } from "@mdi/js";
import moment from "moment";
import styled from "styled-components";
import {
  fetchTripDetails,
  makeReservation,
  startChat,
  getBoardingPointsForTripOrReservation,
  getReservationFromTrip
} from "queries/queries";
import { BACKEND_DATETIME_FORMAT } from "constants/backend";
import { Color } from "constants/colors";
import { icons } from "constants/icons";
import {
  backendDateTimeStringToMoment,
  currentTimeMoment,
  numberToPrice
} from "util/formatUtils";
import Loading from "components/loading";
import MapContainer from "components/mapContainer/MapContainer";
import { InternalUrl } from "constants/urls";
import { getParamsFromUrl } from "util/routeUtils";
import showModal from "../../util/modalHandler";
import ProfileModal from "../../components/modals/companyProfile/ProfileModal";
import { withRoles } from "../../hocs/withRoles";
import RequestStatus, { Status } from "../../model/RequestStatus";
import ReservationSteps from "../../components/reservationSteps";
import "./styles.less";
import { remountOnUrlParamsChange } from "../../hocs/remountOnUrlParamsChange";
import { PRIMARY_COLOR } from "../../constants/colorDefs";
import {
  DecreaseSeatButton,
  IncreaseSeatButton
} from "./subComponents/SeatStepButtons";
import classNames from "classnames/bind";
import { TripStatus } from "../../constants/TripStatus";
import { capitalizeFirstLetter } from "../../util/textUtils";
import { MINUTES_BEFORE_RESERVATION_DEADLINE_TO_SHOW_WARNING } from "../../constants/backend";
import ReservationCountdown from "../../components/reservationCountdown";
import { timeDiff } from "../../util/formatUtils";
import { ReservationStatus } from "../../constants/reservationStatus";
import { connect } from "react-redux";
import { getUserProfile } from "../../queries/queries";
import AddPhoneNumberModal from "../../components/modals/addPhoneNumber/AddPhoneNumberModal";
import HighlightText from "../../components/highlightText/HighlightText";
import ChildrenAndPetsInfoModal from "../../components/modals/childrenAndPetsInfoModal/ChildrenAndPetsInfoModal";

const { Title, Text } = Typography;

const TripDetailsSection = styled.div`
  margin-top: 20px;
`;

const TripStatusItem = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const DEFAULT_SELECTED_SEATS = 1;
const DEPARTURE_TIME_FORMAT = "dddd, DD/MM/YYYY, [às] HH:mm";
const COMFORTS_COLOR = "rgba(0, 255, 0, 0.8)";
const RESTRICTIONS_COLOR = "rgba(255, 0, 0, 0.8)";

const CurrentAction = Object.freeze({
  TRIP_DETAILS: "TRIP_DETAILS",
  RESERVATION_SEATS: "RESERVATION_SEATS"
});

const probabilities = {
  NONE: {
    percent: 0,
    label: "Viagem cancelada. Não é possivel fazer uma reserva.",
    type: "error"
  },
  MODERATE: {
    percent: 20,
    label: "Viagem com baixa chance de confirmação.",
    type: "warning"
  },
  GOOD: {
    percent: 40,
    label: "Viagem com boa chance de confirmação.",
    type: "info"
  },
  HIGH: {
    percent: 60,
    label: "Viagem com alta chance de confirmação.",
    type: "info"
  },
  VERY_HIGH: {
    percent: 80,
    label: "Viagem com chance muito alta de confirmação.",
    type: "info"
  },
  ASSURED: {
    percent: 100,
    label: "Viagem confirmada. Efetue sua reserva e garanta sua vaga.",
    type: "success"
  }
};

class TripDetails extends Component {
  reservationDeadlineCheckerInterval;

  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    getUserInfoRequestStatus: new RequestStatus(Status.NOT_STARTED),
    trip: undefined,
    currentReservation: undefined,
    boardingPoints: undefined,
    isLoading: false,
    isStartingChat: false,
    currentAction: CurrentAction.TRIP_DETAILS,
    seats: DEFAULT_SELECTED_SEATS
  };

  componentDidMount() {
    this.getTripDetails();
  }

  componentWillUnmount() {
    clearInterval(this.reservationDeadlineCheckerInterval);
  }

  getTripDetails = () => {
    const {
      roles: { isUser, verifiedEmail, verifiedPhone }
    } = this.props;
    const { tripId } = getParamsFromUrl(this.props);

    this.setState({
      requestStatus: new RequestStatus(
        Status.LOADING,
        "Carregando detalhes da viagem"
      )
    });

    const queries = [
      fetchTripDetails(tripId),
      getBoardingPointsForTripOrReservation(tripId)
    ];

    if (isUser && verifiedEmail && verifiedPhone) {
      queries.push(getReservationFromTrip(tripId));
    }

    Promise.all(queries)
      .then(([tripDetails, boardingPoints, reservationFromTrip]) => {
        if (!tripDetails?.departure || !tripDetails?.arrival) {
          window.location.reload(); //bug happened
        } else {
          this.setState({
            requestStatus: new RequestStatus(Status.SUCCESS),
            trip: tripDetails,
            currentReservation: reservationFromTrip,
            boardingPoints: boardingPoints
          });

          this.setReservationDeadlineCheckerInterval(tripDetails);
        }
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  setReservationDeadlineCheckerInterval = tripDetails => {
    //schedule modal when reservation deadline arrives
    const { reservationAllowanceDeadline } = tripDetails;

    this.reservationDeadlineCheckerInterval = setInterval(() => {
      const timeDiffDuration = timeDiff(
        currentTimeMoment(),
        backendDateTimeStringToMoment(reservationAllowanceDeadline)
      );

      if (timeDiffDuration.asMilliseconds() < 0) {
        const { currentAction } = this.state;
        if (currentAction === CurrentAction.RESERVATION_SEATS) {
          clearInterval(this.reservationDeadlineCheckerInterval);
          Modal.info({
            title: "Aviso",
            content: `Esta reserva não pode ser concluída pois o prazo para criação de reservas desta viagem foi encerrado.`,
            centered: true,
            onOk: this.onCancelReservationClick
          });
        }
      }
    }, 1000);
  };

  onChatClick = () => {
    const {
      history,
      roles: { isUser }
    } = this.props;
    const { tripId } = getParamsFromUrl(this.props);

    if (isUser) {
      this.setState({
        isStartingChat: true
      });

      startChat(tripId)
        .then(response => {
          this.props.history.push(
            InternalUrl.CHAT.replace(":chatCode", response)
          );
        })
        .catch(error => {
          this.setState({
            isStartingChat: false
          });
        });
    } else {
      history.push(InternalUrl.LOGIN);
    }
  };

  onReservationClick = () => {
    const {
      phoneNumber,
      history,
      roles: { isUser, verifiedEmail, verifiedPhone }
    } = this.props;
    const {
      trip: { reservationAllowanceDeadline },
      currentReservation
    } = this.state;

    if (isUser) {
      if (!verifiedEmail) {
        message.warning(
          "Você precisa verificar seu email para fazer uma reserva"
        );
        history.push(InternalUrl.VERIFY_EMAIL);
      } else if (!verifiedPhone) {
        // let phoneNumber2 = phoneNumber;
        // if(!phoneNumber2){
        //   phoneNumber2 =
        // }
        // debugger
        if (phoneNumber) {
          message.warning(
            "Você precisa verificar seu celular para fazer uma reserva"
          );
          history.push(InternalUrl.VERIFY_PHONE);
        } else {
          this.setState({
            getUserInfoRequestStatus: new RequestStatus(Status.LOADING)
          });
          getUserProfile()
            .then(response => {
              this.setState({
                getUserInfoRequestStatus: new RequestStatus(Status.SUCCESS)
              });

              if (response.phone) {
                message.warning(
                  "Você precisa verificar seu celular para fazer uma reserva"
                );
                history.push(InternalUrl.VERIFY_PHONE);
              } else {
                message.warning(
                  "Você precisa adicionar um número de celular para fazer uma reserva"
                );
                showModal(AddPhoneNumberModal, {}, data => {
                  if (data) {
                    message.success(
                      "Agora você só precisa verificar o seu celular"
                    );
                    history.push(InternalUrl.VERIFY_PHONE);
                  }
                });
                // history.push(InternalUrl.PROFILE);
              }
              // debugger
            })
            .catch(error => {
              this.setState({
                getUserInfoRequestStatus: new RequestStatus(
                  Status.ERROR,
                  error.message
                )
              });
              message.error(
                "Erro ao buscar informações do usuário. Por favor, tente novamente."
              );
            });
        }
      } else {
        if (currentReservation) {
          const { code: reservationCode } = currentReservation;
          if (!reservationCode) {
            window.location.reload(); //bug happened
          } else {
            const goToReservation = () => {
              history.push(
                InternalUrl.RESERVATION.replace(
                  ":reservationCode",
                  reservationCode
                )
              );
            };

            this.showReservationDeadlineModalIfNecessary(
              reservationAllowanceDeadline,
              goToReservation,
              true
            );
          }
        } else {
          const showSeatsStep = () => {
            this.setState({
              currentAction: CurrentAction.RESERVATION_SEATS,
              requestStatus: new RequestStatus(Status.NOT_STARTED)
            });
          };

          this.showReservationDeadlineModalIfNecessary(
            reservationAllowanceDeadline,
            showSeatsStep,
            false
          );
        }
      }
    } else {
      history.push(InternalUrl.LOGIN, {
        //TODO redirect to reservation correctly
        // from: InternalUrl.RESERVATION.replace(":tripId", tripId),
        from: history.location,
        fromUnauthorizedLocation: true
      });
    }
  };

  showReservationDeadlineModalIfNecessary(
    reservationAllowanceDeadline,
    onOkFunction,
    hasReservation
  ) {
    const timeDiffDuration = timeDiff(
      currentTimeMoment(),
      backendDateTimeStringToMoment(reservationAllowanceDeadline)
    );

    const shouldDisplayWarning =
      timeDiffDuration.asMinutes() <=
      MINUTES_BEFORE_RESERVATION_DEADLINE_TO_SHOW_WARNING;

    if (shouldDisplayWarning) {
      if (timeDiffDuration.asMinutes() < 0) {
        Modal.error({
          title: "Não é possível fazer uma reserva",
          content: `${
            hasReservation
              ? "Não é possível continuar essa reserva"
              : "Esta reserva não pode ser iniciada"
          } pois o prazo para criação de reservas desta viagem já foi encerrado.`,
          centered: true
        });
      } else {
        const remainingMinutes = timeDiffDuration.minutes();
        const minutesMessageSection =
          remainingMinutes > 1
            ? `${remainingMinutes} minutos`
            : remainingMinutes === 1
            ? `${remainingMinutes} minuto`
            : `menos de um minuto`;
        Modal.confirm({
          title: "Aviso",
          content: `O prazo de criação de reservas desta viagem encerra-se em ${minutesMessageSection}. Deseja continuar?`,
          centered: true,
          okText: "Sim",
          cancelText: "Não",
          onOk: onOkFunction
        });
      }
    } else {
      onOkFunction();
    }
  }

  getAvailableSeats = () => {
    const {
      trip: { vehicleCapacity, reservedSeats }
    } = this.state;

    return vehicleCapacity - reservedSeats;
  };

  onSeatsChange = seats => {
    const availableSeats = this.getAvailableSeats();

    if (seats > 0 && seats <= availableSeats) {
      this.setState({
        seats: seats
      });
    }
  };

  onCancelReservationClick = () => {
    this.setState({
      currentAction: CurrentAction.TRIP_DETAILS,
      requestStatus: new RequestStatus(Status.SUCCESS)
    });
  };

  onNextClick = () => {
    const { seats } = this.state;
    const { history } = this.props;
    const { tripId } = getParamsFromUrl(this.props);

    this.setState({
      requestStatus: new RequestStatus(Status.LOADING)
    });

    makeReservation(tripId, seats)
      .then(reservation => {
        const { code: reservationCode } = reservation;

        history.push(
          InternalUrl.RESERVATION.replace(":reservationCode", reservationCode)
        );
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  renderComfortsOrRestrictions(item, iconColor) {
    return (
      <div key={item.id} className="conditions-item">
        <Icon size={0.8} path={icons[item.id]} color={iconColor} />
        <span>{item.description}</span>
      </div>
    );
  }

  openCompanyProfile(companyId) {
    showModal(ProfileModal, { companyId: companyId });
  }

  openChildrenAndPetsModal() {
    const {
      trip: { childrenPrice, petsPrice }
    } = this.state;

    showModal(ChildrenAndPetsInfoModal, { childrenPrice, petsPrice });
  }

  renderTripDetails = () => {
    const { Step } = Steps;
    const {
      trip,
      requestStatus,
      currentReservation,
      boardingPoints,
      getUserInfoRequestStatus
    } = this.state;

    if (requestStatus.status === Status.LOADING) {
      return <Loading loadingMessage={requestStatus.message} />;
    } else if (requestStatus.status === Status.ERROR) {
      return (
        <Result
          status="warning"
          title={"Erro ao carregar os detalhes da viagem"}
          subTitle={requestStatus.message}
          extra={[
            <Button type="primary" key="tryAgain" onClick={this.getTripDetails}>
              Entendi
            </Button>
          ]}
        />
      );
    } else if (requestStatus.status === Status.SUCCESS) {
      const {
        reservedSeats,
        vehicleCapacity,
        departure: {
          id: departureId,
          city: departureCity,
          fullName: departurePlace
        },
        arrival: { id: arrivalId, city: arrivalCity, fullName: arrivalPlace },
        departureTime,
        arrivalTime,
        comforts,
        restrictions,
        price,
        probability,
        status,
        reservationAllowanceDeadline
      } = trip;
      const priceString = numberToPrice(price);

      const formattedDepartureTime = capitalizeFirstLetter(
        moment(departureTime, BACKEND_DATETIME_FORMAT).format(
          DEPARTURE_TIME_FORMAT
        )
      );
      const formattedArrivalTime = capitalizeFirstLetter(
        moment(arrivalTime, BACKEND_DATETIME_FORMAT).format(
          DEPARTURE_TIME_FORMAT
        )
      );

      const probabilityObject = probabilities[probability];

      if (probabilityObject && probabilityObject === probabilities.ASSURED) {
        if (currentReservation) {
          if (currentReservation.status === ReservationStatus.CONFIRMED) {
            probabilityObject.label =
              "A viagem e a sua reserva estão confirmadas. Nos vemos no ponto de embarque.";
          } else {
            /* eslint-disable */
            const tripHasEnoughSeatsForReservation = currentReservation.status === ReservationStatus.EXPIRED
              ? currentReservation.numberOfSeats <= vehicleCapacity - reservedSeats
              : currentReservation.numberOfSeats <= vehicleCapacity - reservedSeats + currentReservation.numberOfSeats;
            /* eslint-enable */
            if (tripHasEnoughSeatsForReservation) {
              probabilityObject.label =
                "Viagem confirmada. Finalize a sua reserva para garantir sua vaga.";
            } else {
              probabilityObject.label = `Você não finalizou a sua reserva a tempo e não há mais assentos suficientes para a sua reserva.`;
              probabilityObject.type = "warning";
            }
          }
        } else if (vehicleCapacity === reservedSeats) {
          probabilityObject.label =
            "A viagem está confirmada mas não há mais assentos disponíveis.";
          probabilityObject.type = "warning";
        }
      }

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <div style={{ padding: "0 10px" }}>
            <ReservationCountdown
              reservationAllowanceDeadline={reservationAllowanceDeadline}
            />
          </div>
          <div style={{ flexGrow: 1, overflowY: "auto", paddingTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div className="trip-numbers">
                <Tooltip
                  placement="bottomRight"
                  title="Lotação do veículo"
                  arrowPointAtCenter
                >
                  <TripStatusItem>
                    <Icon
                      path={mdiVanPassenger}
                      size={1}
                      color={PRIMARY_COLOR}
                    />
                    <span className="trip-status-value">{vehicleCapacity}</span>
                    <span className="label">(max)</span>
                  </TripStatusItem>
                </Tooltip>
                {status !== TripStatus.CANCELLED && (
                  <Tooltip
                    placement="bottomRight"
                    title="Assentos livres"
                    arrowPointAtCenter
                  >
                    <TripStatusItem>
                      <Icon
                        path={mdiSeatReclineExtra}
                        size={1}
                        color={PRIMARY_COLOR}
                      />
                      <span className="trip-status-value">
                        {vehicleCapacity - reservedSeats}
                      </span>
                      <span className="label">(livres)</span>
                    </TripStatusItem>
                  </Tooltip>
                )}
              </div>
            </div>
            {probabilityObject && (
              <Alert
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "10px"
                }}
                message={
                  <h3 style={{ margin: "0" }}>{probabilityObject.label}</h3>
                }
                type={probabilityObject.type}
              />
            )}
            <Row>
              <Col lg={12} sm={24}>
                <TripDetailsSection>
                  <Steps direction="vertical">
                    <Step
                      style={{ paddingBottom: "30px" }}
                      icon={
                        <Icon path={mdiCircle} size={0.8} color={"#8333ff"} />
                      }
                      status="finish"
                      title={
                        <div>
                          <h2>{departureCity}</h2>
                          <h3>{formattedDepartureTime}</h3>
                          <h4>{departurePlace}</h4>
                          {boardingPoints && (
                            <h5 style={{ marginTop: "20px" }}>
                              Essa viagem possui {boardingPoints.length} pontos
                              de embarque
                            </h5>
                          )}
                        </div>
                      }
                      className="step-detail"
                    />
                    <Step
                      icon={
                        <Icon path={mdiCircle} size={0.8} color={"#8333ff"} />
                      }
                      status="finish"
                      title={
                        <div>
                          <h2>{arrivalCity}</h2>
                          <h3>{formattedArrivalTime}</h3>
                          <h4>{arrivalPlace}</h4>
                        </div>
                      }
                      className="step-detail"
                    />
                  </Steps>
                </TripDetailsSection>
              </Col>
              <Col lg={12} sm={24}>
                <div className="trip-map">
                  <MapContainer departure={departureId} arrival={arrivalId} />
                </div>
              </Col>
            </Row>
            <TripDetailsSection id={"conditions-section"}>
              <Row>
                <Col lg={12} sm={24}>
                  <div className="conditions-title">Comodidades</div>
                  {comforts.map(comfort =>
                    this.renderComfortsOrRestrictions(comfort, COMFORTS_COLOR)
                  )}
                </Col>
                <Col lg={12} sm={24}>
                  <div className="conditions-title">Restrições</div>
                  {restrictions.map(restriction =>
                    this.renderComfortsOrRestrictions(
                      restriction,
                      RESTRICTIONS_COLOR
                    )
                  )}
                </Col>
              </Row>
            </TripDetailsSection>
          </div>
          <div style={{ padding: "auto 10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF", //TODO find a way to use the variable defined on body tag on the main App.css
                borderTop: `1px solid ${Color.ANTD_LIGHT_GREY_BORDER}`,
                padding: "8px 0"
              }}
            >
              <div className="price-box">
                <span
                  className={classNames(
                    "trip-price",
                    status === TripStatus.CANCELLED ? "cancelled" : null
                  )}
                >
                  R$ {priceString}
                </span>
                <span className="label">(por assento)</span>
              </div>
              <div style={{ display: "flex" }}>
                {/* <CustomIconOnlyButton
                  iconPath={mdiChatOutline}
                  loading={isStartingChat}
                  antdButtonProps={{
                    type: "primary",
                    onClick: () => {
                      this.onChatClick();
                    }
                  }}
                /> */}
                {(() => {
                  if (currentReservation) {
                    return (
                      <Button
                        type="primary"
                        onClick={this.onReservationClick}
                        style={{ marginLeft: "5px" }}
                      >
                        Ver reserva
                      </Button>
                    );
                  } else if (status === TripStatus.CANCELLED) {
                    return null;
                  } else {
                    return (
                      <Button
                        type="primary"
                        loading={
                          getUserInfoRequestStatus.status === Status.LOADING
                        }
                        onClick={this.onReservationClick}
                        style={{ marginLeft: "5px" }}
                      >
                        Reservar
                      </Button>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderReservationSeats = () => {
    const {
      requestStatus,
      seats,
      trip: { price, reservationAllowanceDeadline }
    } = this.state;

    const availableSeats = this.getAvailableSeats();
    const totalPrice = numberToPrice(price * seats);

    if (requestStatus.status === Status.NOT_STARTED) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <div style={{ padding: "0 10px" }}>
            <ReservationCountdown
              reservationAllowanceDeadline={reservationAllowanceDeadline}
            />
          </div>
          <div style={{ marginBottom: "20px", marginTop: "10px" }}>
            <ReservationSteps currentStep={0} />
          </div>
          <h2>Quantos lugares deseja reservar para essa viagem?</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "auto",
              maxWidth: "fit-content"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "auto",
                maxWidth: "fit-content"
              }}
            >
              <DecreaseSeatButton
                disabled={seats <= 1}
                onClick={() => this.onSeatsChange(seats - 1)}
              />
              <Text
                className={"seat-number"}
                style={{
                  fontWeight: "bold",
                  margin: "auto 10px",
                  minWidth: "42px",
                  textAlign: "center"
                }}
              >
                {seats}
              </Text>
              <IncreaseSeatButton
                disabled={seats >= availableSeats}
                onClick={() => this.onSeatsChange(seats + 1)}
              />
            </div>
            <p>
              Vai levar crianças ou animais?{" "}
              <HighlightText
                clickable
                onClick={() => this.openChildrenAndPetsModal()}
              >
                Clique aqui
              </HighlightText>
              .
            </p>
          </div>
          <Title level={4} style={{ marginBottom: "10px", textAlign: "right" }}>
            Total: <span style={{ color: PRIMARY_COLOR }}>R$ {totalPrice}</span>
          </Title>
          <div
            className="steps-action"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: "10px"
            }}
          >
            <Button type="danger" ghost onClick={this.onCancelReservationClick}>
              Cancelar
            </Button>
            <Button type="primary" onClick={this.onNextClick}>
              Próximo
            </Button>
          </div>
        </div>
      );
    }

    if (requestStatus.status === Status.LOADING) {
      return <Loading loadingMessage={requestStatus.message} />;
    }

    if (requestStatus.status === Status.ERROR) {
      return (
        <Result
          status="warning"
          title={"Erro ao efetuar reserva"}
          subTitle={requestStatus.message}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                this.setState({
                  requestStatus: new RequestStatus(Status.NOT_STARTED)
                });
              }}
            >
              Entendi
            </Button>
          ]}
        />
      );
    }

    return null;
  };

  render() {
    const { currentAction, trip } = this.state;

    let helmetTitle = "Detalhes da viagem | Vai de Van";
    if (trip) {
      helmetTitle = `Viagem de ${trip.departure.city} para ${trip.arrival.city} | Vai de Van`;
    }

    return (
      <>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="main-container trip-detail-main-container">
          <div className="trip-detail-container">
            {(() => {
              if (currentAction === CurrentAction.TRIP_DETAILS) {
                return this.renderTripDetails();
              } else if (currentAction === CurrentAction.RESERVATION_SEATS) {
                return this.renderReservationSeats();
              }
              return null;
            })()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    phoneNumber: state.userInfoReducer.phoneNumber
  };
};

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators({}, dispatch);
// };

TripDetails.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  roles: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string
};

export default lodash.flowRight(
  withRoles,
  remountOnUrlParamsChange,
  connect(mapStateToProps)
)(TripDetails);
