import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Icon } from "antd";
import { Color } from "constants/colors";
import { backendDateTimeToStringRelativeTime } from "../../../../util/formatUtils";
import "./styles.css";

class NotificationDrawer extends Component {
  render() {
    const {
      title,
      isDrawerOpen,
      notifications,
      emptyNotificationsMessage,
      onNotificationClick,
      onNotificationClear,
      onClose,
      footerAction
    } = this.props;

    return (
      <Drawer
        title={title}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={isDrawerOpen}
        drawerStyle={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column"
        }}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          padding: "0"
        }}
      >
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          {notifications.length === 0 && (
            <div
              style={{
                backgroundColor: "white",
                textAlign: "center",
                height: "100%",
                display: "flex"
              }}
            >
              <span style={{ margin: "auto" }}>
                {emptyNotificationsMessage}
              </span>
            </div>
          )}
          {notifications.length > 0 && (
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                gridRowGap: "1px",
                gridColumnGap: "1px",
                paddingBottom: "1px",
                backgroundColor: Color.ANTD_LIGHT_GREY_BORDER
              }}
            >
              {notifications.map(notification => {
                return (
                  <div key={notification.id} className={"notification-item"}>
                    <div
                      style={{
                        display: "grid",
                        gridAutoFlow: "column",
                        gap: "1px"
                      }}
                    >
                      <div
                        onClick={() => onNotificationClick(notification)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around"
                        }}
                      >
                        {notification.createdAt && (
                          <span className="notification-time">
                            {backendDateTimeToStringRelativeTime(
                              notification.createdAt
                            )}
                          </span>
                        )}
                        <span className="notification-text">
                          {notification.value}
                        </span>
                      </div>
                      <div
                        onClick={() => onNotificationClear(notification)}
                        className="clickable clear-notification-icon"
                        style={{
                          backgroundColor: "white",
                          padding: "16px",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Icon type="close" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {footerAction && (
          <div
            className="text-link"
            style={{
              minHeight: "30px",
              backgroundColor: "white",
              textAlign: "right",
              marginTop: "1px",
              borderTop: `1px solid ${Color.ANTD_LIGHT_GREY_BORDER}`,
              padding: "0 5px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <span
              style={{ textAlign: "right", width: "100%" }}
              onClick={footerAction.callback}
            >
              {footerAction.text}
            </span>
          </div>
        )}
      </Drawer>
    );
  }
}

NotificationDrawer.defaultProps = {
  footerAction: null
};

NotificationDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  emptyNotificationsMessage: PropTypes.string.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  onNotificationClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  footerAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
  })
};

export default NotificationDrawer;
