import { generate } from "@ant-design/colors";
import { PRIMARY_COLOR } from "constants/colorDefs";

/*
Generated colors for #3300FF are
0: "#EFE6FF"
1: "#C2A3FF"
2: "#A27AFF"
3: "#8052FF"
4: "#5B29FF"
5: "#3300FF"
6: "#2400D9"
7: "#1800B3"
8: "#0E008C"
9: "#070066"
 */
export const PrimaryColor = Object.freeze({
  ...generate(PRIMARY_COLOR),
  primary: PRIMARY_COLOR
});

export const Color = Object.freeze({
  ANTD_LIGHT_GREY_BORDER: "#d9d9d9",
  INPUT_ICON_COLOR: "rgba(0,0,0,.25)"
});
