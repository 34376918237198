import React from "react";

const TermsAndConditionsContent = () => (
  <>
    <h3>Objeto</h3>
    <ol type="1">
      <li>
        São objetos dos TERMOS DE USO o acesso e utilização, por quaisquer
        terceiros, do website, conteúdos e serviços produzidos, disponibilizados
        e/ou distribuídos por e/ou através da VAI DE VAN, os quais doravante
        passam a ser chamados simplesmente de SERVIÇOS.
      </li>
    </ol>
    <h3>Serviços</h3>
    <ol type="1" start="2">
      <li>
        A VAI DE VAN é uma empresa de tecnologia da informação que oferece
        SERVIÇOS prestados através de site de internet, conectando pessoas
        interessadas em viajar a empresas cadastradas em sua plataforma e que
        prestam serviços de transporte privado e coletivo de passageiros.
      </li>
      <li>
        A VAI DE VAN possibilita que pessoas físicas e empresas de transporte
        tenham plena e total liberdade para negociar e contratar entre si a
        prestação de serviços de transporte, dos quais a VAI DE VAN não
        participa sob qualquer aspecto.
      </li>
      <li>
        A VAI DE VAN, desde já, declara e reitera não ser uma empresa de
        transporte, não possuir frota própria e tampouco ser fornecedora de bens
        e/ou serviços no setor de transportes.
      </li>
    </ol>
    <h3>Titularidade</h3>
    <ol type="1" start="5">
      <li>
        Softwares, domínios, marcas, imagens, registros, websites, códigos-fonte
        e/ou quaisquer outras obras de cunho intelectual e/ou industrial
        necessários à prestação dos SERVIÇOS e que tenham sido desenvolvidos
        e/ou fornecidos pela VAI DE VAN pertencem única e exclusivamente a ela,
        pelos prazos definidos na legislação vigente e aplicável a cada um
        desses elementos.
      </li>

      <li>
        Ao cumprir os TERMOS DE USO, a título provisório e abolível a qualquer
        tempo, é concedida a você uma acessão para que goze, na forma de licença
        pessoal, não comercial, não exclusiva, e não suceptível a sublicença
        e/ou transferência, sob quaisquer hipóteses, tão somente de:
      </li>
      <ol type="a">
        <li>
          acesso e uso do website da VAI DE VAN na internet exclusivamente para
          uso dos SERVIÇOS nele oferecidos;
        </li>
        <p>Fica, no entanto, expressamente vetada:</p>
        <li>
          a remoção de qualquer aviso de direito autoral, direito de marca ou
          outro aviso de direito de propriedade de qualquer parte dos SERVIÇOS;
        </li>

        <li>
          a reprodução, a modificação, a distribuição, a locação, a venda, a
          revenda, a transferência, a veiculação, a transmição e ou, de qualquer
          outra maneira, a exploração, total ou parcial, dos SERVIÇOS, a não ser
          pela forma como expressamente permitida pela VAI DE VAN nos presentes
          TERMOS DE USO;
        </li>

        <li>
          a realização de práticas de engenharia reversa, a inspeção do
          código-fonte e/ou a replicação dos SERVIÇOS fora das estritas
          hipóteses permitidas pela legislação vigente e aplicável;
        </li>

        <li>
          a obtenção do acesso não autorizado aos SERVIÇOS e/ou ao banco de
          dados mantido pela VAI DE VAN, tampouco a realização de práticas que
          possam prejudicar quaisquer aspectos dos SERVIÇOS ou de seus sistemas
          ou redes correlatas.
        </li>
      </ol>
    </ol>
    <h3>Serviços de Terceiros</h3>
    <ol type="1" start="7">
      <li>
        Como citado no artigo segundo dos presentes TERMOS DE USO, a VAI DE VAN
        não se constitui, sob hipótese alguma, em uma empresa de transporte,
        senão em uma empresa de tecnologia da informação. O produto daquilo que
        oferta e opera situa-se única e exclusivamente no âmbito da
        intermediação, sendo que, para tanto, cadastra e mantêm, dentro de sua
        plataforma, empresas de transporte independentes, devidamente
        autorizadas, licenciadas e registradas pelos órgãos reguladores
        competentes, bem como pessoas físicas interessadas nas viagens que tais
        empresas eventualmente venham a oferecer.
      </li>
      <li>
        Precisamente por manter em sua base de dados empresas independentes, com
        as quais não possui qualquer vínculo de natureza jurídica e/ou
        econômica, a VAI DE VAN alerta para possibilidade de que os serviços por
        estas empresas prestados estejam sujeitos a termos de usos de terceiros,
        os quais, a VAI DE VAN, naturalmente, desconhece.
      </li>
      <li>
        Apple Inc., Google, Inc., Microsoft Corporation ou BlackBerry Limited
        e/ou suas subsidiárias e afiliadas internacionais serão terceiros
        beneficiários destes TERMOS DE USO, caso você acesse os SERVIÇOS usando
        aplicativos desenvolvidos para dispositivos móveis baseados em Apple
        iOS, Android, Microsoft Windows, ou Blackberry, respectivamente. Esses
        terceiros beneficiários não são partes destes TERMOS DE USO ou de
        qualquer outra relação contratual desenvolvida entre a VAI DE VAN e
        você, e não são responsáveis pela prestação dos SERVIÇOS ou por qualquer
        forma de suporte aos SERVIÇOS. Seu acesso aos SERVIÇOS usando esses
        dispositivos está sujeito às condições estabelecidas nos termos de
        serviços dos respectivos terceiros beneficiários.
      </li>
      <li>
        A VAI DE VAN, embora disponibilize um chat para troca de mensagens entre
        as empresas e os passageiros, não é, sob qualquer perspectiva,
        responsável pelo teor das mensagens veículadas dentro deste chat,
        constituindo-se os autores das mesmas nos únicos responsáveis por elas.
        É vetado o envio de mensagens caluniosas, ofensivas, difamadoras, de
        caráter racista ou discriminatório, de cunho sexual ou religioso, ou que
        de qualquer maneira possam insultar a dignidade de outras pessoas e/ou
        entidades. O intuito único desta ferramenta é auxiliar na comunicação
        entre as partes envolvidas, ficando, desta maneira, vetado o uso para
        finalidades outras.
      </li>
    </ol>
    <h3>Regras e condições de uso</h3>
    <ol type="1" start="11">
      <li>
        Para o utilizar os SERVIÇOS da VAI DE VAN, você precisa aceitar e
        cumprir os presentes TERMOS DE USO.
      </li>
      <li>
        A não observância dos presentes TERMOS DE USO poderá acarretar na
        inativação de seu cadastro, bem como em eventuais penalidades previstas
        pela legislação cabível.
      </li>
      <li>
        Ao realizar o cadastro, você se compromente, de forma inequívoca e
        incondicional, com o envio de informações verídicas, às quais poderão,
        dentro dos limites da lei, incidir procedimentos de validação e
        verificação, com o intuito único de preservar a segurança e a
        integridade dos demais usuários, com os quais o transporte poderá
        eventualmente ser compartilhado, bem como das empresas independentes de
        transporte que são mantidas dentro da plataforma VAI DE VAN.
      </li>
      <li>
        O cadastro exige o envio de nome, CPF, número de telefone celular,
        endereço de email, senha, bem com o aceite dos presentes TERMOS DE USO.
      </li>
      <li>
        Você concorda em manter os seus dados sempre atualizados, com a máxima
        precisão e veracidade.
      </li>
      <li>
        Você está ciente de que é o único responsável por toda e qualquer
        atividade realizada dentro de sua conta VAI DE VAN.
      </li>
      <li>
        A menos que expressamente autorizado pela VAI DE VAN, você tem permissão
        para manter, no máximo, uma conta de usuário.
      </li>
      <li>
        Ao fazer uma reserva você se compromete a comparecer ao local de
        embarque com uma antecedência mínima de 15 minutos.
      </li>
      <li>
        Ao fazer uma reserva você se compromete a efetuar o pagamento
        integralmente ao motorista do veículo, no ato do embarque.
      </li>
      <li>
        Ao embarcar, você se compromete a não trazer consigo quaisquer itens que
        possam ser considerados ilegais ou que possam, de qualquer maneira,
        trazer prejuízos a si ou aos demais envolvidos na viagem.
      </li>
      <li>
        Ao embarcar, você se compromete a não perturbar os demais passageiros e,
        especialmente, o motoristista, sob pena de ser compulsoriamente
        desembarcado.
      </li>
      <li>
        O não comparecimento ao local de embarque ou o não pagamento no ato de
        embarque, quando não devidamente justificados, poderão acarretar, além
        de penalidades dentro da platforma, como eventual expulsão, em processos
        judiciais, de acordo com a legislação cabível.
      </li>
    </ol>
    <h3>Garantias</h3>
    <ol type="1" start="23">
      <li>
        A realização de qualquer viagem está condicionada à ocupação do número
        mínimo de assentos que fora determinado no momento de sua divulgação.
      </li>
      <li>
        A viagem só poderá ser considerada como confirmada quando a VAI DE VAN
        enviar tal notificado.
      </li>
      <li>
        A antecedência mínima de confirmação para uma viagem é de 4 horas.
      </li>
      <li>
        A VAI DE VAN se compromete a manter dentro de sua plataforma apenas
        empresas devidamente licenciadas pelos orgãos reguladores compententes,
        bem como a validar de maneira recorrente a qualidade dos serviços por
        elas prestados, não apenas de forma independente, como por meio de
        avaliações enviadas pelos usuários.
      </li>
      <li>
        A VAI DE VAN se isenta da responsabilidade com eventuais atrasos e/ou
        desvio de rotas, sejam eles motivados por fatores naturais ou por
        quaisquer eventualidades que possam acometer as empresas de transporte.
      </li>
      <li>
        A VAI DE VAN se compromete a manter em sua base apenas com empresas
        seguradas, motivo pelo qual se isenta da responsabilidade com o
        pagamento de indenizações decorrentes de quaisquer incidentes que possam
        ocorrer com as viagens que intermedia.
      </li>
    </ol>
    <h3>Disposições Gerais</h3>
    <ol type="1" start="29">
      <li>
        Ao utilizar os SERVIÇOS da VAI DE VAN, você concorda integralmente com
        todas as condições presentes nos TERMOS DE USO.
      </li>
      <li>
        A não concordância com quaisquer items do presente termo o
        impossibilitará de usar os SERVIÇOS.
      </li>
      <li>
        É garantida à VAI DE VAN a prerrogativa de alteração dos TERMOS DE USO,
        coincidindo o início de sua vigência com a data de sua publicação.
      </li>
      <li>
        É também garantida à VAI DE VAN a prerrogativa exclusiva e irrevogável
        de encerramento dos SERVIÇOS a qualquer tempo.
      </li>
    </ol>
  </>
);

export default TermsAndConditionsContent;
