import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import { InternalUrl } from "../../constants/urls";

const CustomRoute = props => {
  const { component: Component, isAllowed, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props => {
        if (isAllowed) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: InternalUrl.LOGIN,
                state: { from: props.location, fromUnauthorizedLocation: true }
              }}
            />
          );
        }
      }}
    />
  );
};

CustomRoute.defaultProps = {
  isAllowed: true
};

CustomRoute.propTypes = {
  location: PropTypes.object.isRequired,
  component: PropTypes.any.isRequired,
  isAllowed: PropTypes.bool
};

export default withRouter(CustomRoute);
