import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Helmet } from "react-helmet";
import { Form, Icon, Input, Button, Typography, message, Result } from "antd";
import MaskedInput from "components/maskedInput";
import { validate as validateCpf } from "cpf-check";
import { getUserProfile, editUser } from "queries/queries";
import { setToken, parseToken } from "services/auth";
import { InternalUrl } from "constants/urls";
import RequestStatus, { Status } from "model/RequestStatus";
import Loading from "components/loading";
import showModal from "util/modalHandler";
import CurrentPasswordModal from "components/modals/currentPasswordModal/CurrentPasswordModal";
import "./styles.less";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRoles } from "../../hocs/withRoles";
import { patchUser } from "../../queries/queries";
import { setPhone } from "../../redux/actions/userInfo";

const { Title } = Typography;

const INPUT_ICON_COLOR = "rgba(0,0,0,.25)";

const FormFields = Object.freeze({
  NAME: "name",
  PHONE: "phone",
  DOCUMENT_NUMBER: "documentNumber",
  EMAIL: "email"
});

const Steps = Object.freeze({
  LOADING_PROFILE: "LOADING_PROFILE",
  PROFILE_LOADED: "PROFILE_LOADED"
});

class Profile extends Component {
  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    step: Steps.LOADING_PROFILE,
    editMode: false,
    name: undefined,
    phone: undefined,
    cpf: undefined,
    email: undefined
  };

  componentDidMount() {
    this.fetchUserProfile();
  }

  fetchUserProfile = () => {
    const { form } = this.props;
    const { setFieldsValue } = form;

    this.setState({
      requestStatus: new RequestStatus(Status.LOADING, "Carregando perfil"),
      step: Steps.LOADING_PROFILE
    });

    getUserProfile()
      .then(response => {
        const { name, phone, cpf, username: email } = response;

        this.setState({
          requestStatus: new RequestStatus(Status.NOT_STARTED),
          step: Steps.PROFILE_LOADED,
          name: name,
          phone: phone,
          cpf: cpf,
          email: email
        });

        /* eslint-disable */
        const obj = {};
        lodash.set(obj, FormFields.NAME, name);
        lodash.set(obj, FormFields.PHONE, phone);
        lodash.set(obj, FormFields.DOCUMENT_NUMBER, cpf);
        lodash.set(obj, FormFields.EMAIL, email);
        /* eslint-enable */

        setFieldsValue(obj);
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });

        const { name, phone, cpf, email } = this.state;

        /* eslint-disable */
        const obj = {};
        lodash.set(obj, FormFields.NAME, name);
        lodash.set(obj, FormFields.PHONE, phone);
        lodash.set(obj, FormFields.DOCUMENT_NUMBER, cpf);
        lodash.set(obj, FormFields.EMAIL, email);
        /* eslint-enable */

        setFieldsValue(obj);
      });
  };

  toggleEditMode = () => {
    const { editMode } = this.state;

    if (editMode) {
      this.resetFields();
    }

    this.setState({
      editMode: !editMode
    });
  };

  resetFields = () => {
    const { form } = this.props;
    const { resetFields } = form;

    resetFields();
  };

  handleSubmit = e => {
    const {
      form,
      rememberMe,
      history,
      roles: { isSocialLogin }
    } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const name = values[FormFields.NAME];
        const phone = values[FormFields.PHONE];
        const cpf = values[FormFields.DOCUMENT_NUMBER];
        const email = values[FormFields.EMAIL];

        if (isSocialLogin) {
          this.setState({
            requestStatus: new RequestStatus(
              Status.LOADING,
              "Atualizando dados"
            )
          });

          patchUser({
            phone
          })
            .then(token => {
              this.setState({
                requestStatus: new RequestStatus(Status.SUCCESS),
                editMode: false,
                phone: phone
              });
              setToken(token, rememberMe);
            })
            .catch(error => {
              message.error(`Erro ao salvar os dados: ${error.message}`);
              this.setState({
                requestStatus: new RequestStatus(Status.ERROR, error.message)
              });
            });
        } else {
          showModal(CurrentPasswordModal, {}, password => {
            if (password) {
              this.setState({
                requestStatus: new RequestStatus(
                  Status.LOADING,
                  "Atualizando dados"
                )
              });

              editUser(name, phone, cpf, email, password)
                .then(token => {
                  setToken(token, rememberMe);
                  const parsedToken = parseToken(token);

                  const { phone: prevPhone } = this.state;
                  const { phone: newPhone } = parsedToken.user;

                  if (newPhone !== prevPhone) {
                    history.push(InternalUrl.VERIFY_PHONE);
                  } else {
                    this.setState({
                      requestStatus: new RequestStatus(Status.SUCCESS),
                      editMode: false,
                      name: parsedToken?.user?.name || "",
                      phone: phone,
                      cpf: cpf,
                      email: email
                    });
                  }
                  message.success("Seu perfil foi atualizado!");
                })
                .catch(error => {
                  this.setState({
                    requestStatus: new RequestStatus(
                      Status.ERROR,
                      error.message
                    )
                  });
                });
            }
          });
        }
      }
    });
  };

  hasAnyValueChanged = () => {
    const { name, phone, cpf, email } = this.state;
    const {
      form: { getFieldValue }
    } = this.props;

    let hasAnyValueChanged = false;

    if (
      getFieldValue(FormFields.NAME) !== name ||
      getFieldValue(FormFields.PHONE) !== phone ||
      getFieldValue(FormFields.DOCUMENT_NUMBER) !== cpf ||
      getFieldValue(FormFields.EMAIL) !== email
    ) {
      hasAnyValueChanged = true;
    }

    return hasAnyValueChanged;
  };

  render() {
    const {
      requestStatus,
      step,
      editMode,
      name,
      phone,
      cpf,
      email
    } = this.state;
    const {
      history,
      form,
      roles: { isSocialLogin }
    } = this.props;
    const { getFieldDecorator } = form;

    const hasAnyValueChanged = this.hasAnyValueChanged();

    return (
      <>
        <Helmet>
          <title>Perfil | Vai de Van</title>
        </Helmet>
        <div
          className={"main-container"}
          style={{
            marginTop: "20px"
          }}
        >
          <div style={{ width: "300px" }}>
            {(() => {
              if (requestStatus.status === Status.LOADING) {
                return <Loading loadingMessage={requestStatus.message} />;
              }

              if (requestStatus.status === Status.SUCCESS) {
                return (
                  <Result
                    className={"result-wrapper"}
                    status="success"
                    title="Seus dados foram atualizados"
                    extra={[
                      <Button
                        key="continue"
                        type="primary"
                        size="large"
                        onClick={() => {
                          this.setState({
                            requestStatus: new RequestStatus(Status.NOT_STARTED)
                          });
                        }}
                      >
                        Continuar
                      </Button>
                    ]}
                  />
                );
              }

              if (requestStatus.status === Status.ERROR) {
                return (
                  <Result
                    status="warning"
                    title={
                      step === Steps.LOADING_PROFILE
                        ? "Erro ao carregar seu perfil"
                        : "Erro ao atualizar seus dados"
                    }
                    subTitle={requestStatus.message}
                    extra={
                      <Button
                        key="retry"
                        type="primary"
                        size="large"
                        onClick={() => {
                          if (step === Steps.LOADING_PROFILE) {
                            this.fetchUserProfile();
                          } else {
                            this.setState({
                              requestStatus: new RequestStatus(
                                Status.NOT_STARTED
                              ),
                              editMode: false
                            });
                          }
                        }}
                      >
                        Entendi
                      </Button>
                    }
                  />
                );
              }

              //TODO esconder botao de trocar senha se for um usuario de login social DONE
              //TODO deixar input de email como readonly se for um usuario de login social DONE
              //TODO login social só pode chamar o PATCH passando o telefone. nao pode chamar o PUT. DONE

              return (
                <>
                  <div className="header">
                    <Title level={4}>Perfil do usuário</Title>
                    <Button
                      type="ghost"
                      size="large"
                      icon={"undo"}
                      onClick={this.toggleEditMode}
                      style={{ visibility: editMode ? "inherit" : "hidden" }}
                    />
                  </div>
                  <Form onSubmit={this.handleSubmit} className={"login-form"}>
                    <Form.Item>
                      {getFieldDecorator(FormFields.NAME, {
                        initialValue: name,
                        rules: [
                          {
                            required: true,
                            message: "Por favor, digite seu nome."
                          }
                        ]
                      })(
                        <Input
                          type={"text"}
                          size="large"
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: INPUT_ICON_COLOR }}
                            />
                          }
                          placeholder="Nome"
                          disabled={isSocialLogin || !editMode}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator(FormFields.PHONE, {
                        initialValue: phone,
                        rules: [
                          {
                            validator: (rule, value, callback) => {
                              if (!value || value.length === 0) {
                                callback(
                                  "Por favor, digite o numero do seu celular."
                                );
                              } else if (value.length < 11) {
                                callback("Digite um número de celular válido.");
                              }

                              callback();
                            }
                          }
                        ]
                      })(
                        <MaskedInput
                          mask="11 1 1111-1111"
                          placeholder="Celular"
                          size="large"
                          prefix={<Icon type="phone" className="form-icon" />}
                          inputMode="numeric"
                          disabled={!editMode}
                        />
                      )}
                    </Form.Item>
                    <Form.Item className={isSocialLogin ? "hidden" : ""}>
                      {getFieldDecorator(FormFields.DOCUMENT_NUMBER, {
                        initialValue: cpf,
                        rules: [
                          {
                            validator: (rule, value, callback) => {
                              if (!value || value.length === 0) {
                                callback();
                              } else if (!validateCpf(value)) {
                                callback("Digite um CPF válido.");
                              }

                              callback();
                            }
                          }
                        ]
                      })(
                        <MaskedInput
                          mask="111.111.111-11"
                          placeholder="CPF"
                          size="large"
                          prefix={<Icon type="idcard" className="form-icon" />}
                          inputMode="numeric"
                          disabled={isSocialLogin || !editMode}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator(FormFields.EMAIL, {
                        initialValue: email,
                        rules: [
                          {
                            required: true,
                            message: "Por favor, digite seu email."
                          }
                        ]
                      })(
                        <Input
                          type={"email"}
                          size="large"
                          prefix={
                            <Icon
                              type="mail"
                              style={{ color: INPUT_ICON_COLOR }}
                            />
                          }
                          placeholder="Email"
                          disabled={isSocialLogin || !editMode}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      <div className={"buttons"}>
                        {!isSocialLogin && (
                          <Button
                            type="ghost"
                            size="large"
                            onClick={() => {
                              history.push(InternalUrl.CHANGE_PASSWORD);
                            }}
                          >
                            Trocar senha
                          </Button>
                        )}
                        {editMode && (
                          <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!hasAnyValueChanged}
                          >
                            Salvar
                          </Button>
                        )}
                        {!editMode && (
                          <Button
                            type="primary"
                            size="large"
                            onClick={this.toggleEditMode}
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                  </Form>
                </>
              );
            })()}
          </div>
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  form: PropTypes.any.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  roles: PropTypes.object.isRequired,
  setPhone: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  // debugger
  return {
    rememberMe: state.userInfoReducer.rememberMe
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setPhone
    },
    dispatch
  );
};

export default lodash.flow([
  withRoles,
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: "profile" })
])(Profile);
