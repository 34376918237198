import store from "redux/store";
import {
  updateToken,
  clearToken as clearTokenAction
} from "redux/actions/token";
import { getCookie, removeCookie, storeCookie } from "../util/cookieUtils";
import { CookieKey } from "../constants/cookieKey";

export const getToken = () => getCookie(CookieKey.TOKEN);

export const clearToken = () => {
  removeCookie(CookieKey.TOKEN);
  removeCookie(CookieKey.REMEMBER_ME);
  store.dispatch(clearTokenAction());
};
export const setToken = (token, rememberMe = false) => {
  const cookieOptions = rememberMe ? { maxAge: 2592000 } : undefined; //this is 1 month

  storeCookie(CookieKey.TOKEN, token, cookieOptions);
  storeCookie(CookieKey.REMEMBER_ME, rememberMe, cookieOptions);

  store.dispatch(updateToken(parseToken(token)));
};

export const parseToken = tokenString => {
  if (tokenString) {
    // eslint-disable-next-line prefer-destructuring
    const base64Url = tokenString.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return {};
  }
};
