import lodash from "lodash";
import queryString from "query-string";
import history from "../services/history";
import { InternalUrl } from "../constants/urls";
import { matchPath } from "react-router-dom";
import { generateRoutesPropsArray } from "../components/routes";

export const getParamsFromUrl = props => {
  const {
    match: { params }
  } = props;

  return params;
};

export const getParamsFromUrlSearchString = props => {
  const {
    location: { search }
  } = props;

  return queryString.parse(search);
};

export const getParamsFromPrevLocation = props => {
  const {
    location: { state }
  } = props;

  if (state) {
    return state;
  } else {
    return {};
  }
};

export const removeSubRoutesFromCurrentUrl = (props, internalUrl = "") => {
  //TODO update implementation to be able to remove subroutes from current url
  // based on InternalUrl object, without receiving the internal url as parameter
  const urlProperties = lodash.get(props, "match.params", {});
  let newUrl = internalUrl;
  Object.entries(urlProperties).forEach(([key, value]) => {
    newUrl = newUrl.replace(`:${key}`, value);
  });

  return newUrl;
};

export const findInternalUrlThatMatchesRoute = currentPathname => {
  const routesProps = generateRoutesPropsArray();
  for (let i = 0; i < routesProps.length; i++) {
    const result = matchPath(currentPathname, routesProps[i]);
    if (result) {
      return result;
    }
  }
};

export const goToLocationForceRefresh = (location, replaceHistory = false) => {
  const historyMethod = replaceHistory ? history.replace : history.push;
  historyMethod(InternalUrl.REDIRECT_TO, { redirectTo: location });
};
