import React from "react";
import PropTypes from "prop-types";

const EmailLink = ({ subject }) => (
  <a href={`mailto:contato@vaidevan.app?Subject=${subject}`}>
    contato@vaidevan.app
  </a>
);

EmailLink.defaultProps = {
  subject: ""
};

EmailLink.propTypes = {
  subject: PropTypes.string
};

export default EmailLink;
