import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Helmet } from "react-helmet";
import { Button, Empty, Icon, Result } from "antd";
import { withRoles } from "../../hocs/withRoles";
import RequestStatus, { Status } from "../../model/RequestStatus";
import style from "./styles.module.css";
import { remountOnUrlParamsChange } from "../../hocs/remountOnUrlParamsChange";
import { connect } from "react-redux";
import { getParamsFromUrl } from "../../util/routeUtils";
import { fetchTrips, getCity } from "../../queries/queries";
import {
  backendDateStringToMoment,
  backendDateTimeStringToMoment,
  currentTimeMoment,
  momentToBackendDateString,
  numberToPrice
} from "../../util/formatUtils";
import classNames from "classnames/bind";
import { TripStatus } from "../../constants/TripStatus";
import { InternalUrl } from "../../constants/urls";
import showModal from "../../util/modalHandler";
import SelectDateModal from "../../components/modals/selectDateModal/SelectDateModal";
import { PRICE_GREEN_COLOR } from "../../constants/colorDefs";
import HighlightText from "../../components/highlightText/HighlightText";
import Skeleton from "react-loading-skeleton";
import { bindActionCreators } from "redux";
import { setSelectedDate } from "../../redux/actions/tripCalendar";
import Loading from "../../components/loading";

class TripCalendar extends Component {
  state = {
    citiesInfoRequestStatus: new RequestStatus(Status.NOT_STARTED),
    tripsRequestStatus: new RequestStatus(Status.NOT_STARTED),
    todayDate: momentToBackendDateString(currentTimeMoment()),
    selectedDate: momentToBackendDateString(currentTimeMoment()),
    departureCity: undefined,
    arrivalCity: undefined,
    trips: []
  };

  componentDidMount() {
    this.fetchCitiesInfo();
    this.fetchTrips();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDate: prevSelectedDate } = prevProps;
    const { selectedDate } = this.props;

    if (selectedDate !== prevSelectedDate) {
      this.fetchTrips();
    }
  }

  fetchCitiesInfo = () => {
    const { departureId, arrivalId } = getParamsFromUrl(this.props);
    this.setState({
      citiesInfoRequestStatus: new RequestStatus(Status.LOADING)
    });

    getCity(parseInt(departureId))
      .then(departureCity => {
        getCity(parseInt(arrivalId)).then(arrivalCity => {
          this.setState({
            citiesInfoRequestStatus: new RequestStatus(Status.SUCCESS),
            departureCity,
            arrivalCity
          });
        });
      })
      .catch(error => {
        this.setState({
          citiesInfoRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          )
        });
      });
  };

  fetchTrips = () => {
    const { departureId, arrivalId } = getParamsFromUrl(this.props);
    const { selectedDate } = this.props;

    this.setState({
      tripsRequestStatus: new RequestStatus(Status.LOADING)
    });

    fetchTrips(departureId, arrivalId, selectedDate, 0, 100, true)
      .then(trips => {
        this.setState({
          tripsRequestStatus: new RequestStatus(Status.SUCCESS),
          trips: trips.trips.filter(trip => {
            return backendDateStringToMoment(trip.departureTime).isSame(
              backendDateStringToMoment(selectedDate),
              "day"
            );
          })
        });
      })
      .catch(error => {
        this.setState({
          tripsRequestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  onReservationClick = tripId => {
    const { history } = this.props;

    history.push(InternalUrl.TRIP_DETAILS.replace(":tripId", tripId));
  };

  onSelectDateClick = () => {
    const { selectedDate } = this.props;
    showModal(SelectDateModal, { selectedDate }, this.setSelectedDate);
  };

  setSelectedDate = newSelectedDate => {
    const { selectedDate, setSelectedDate } = this.props;

    const selectedDateMoment = backendDateStringToMoment(selectedDate);
    const newSelectedDateMoment = backendDateStringToMoment(newSelectedDate);

    if (
      newSelectedDate &&
      newSelectedDateMoment.diff(selectedDateMoment, "day") !== 0
    ) {
      setSelectedDate(newSelectedDate);
    }
  };

  render() {
    const {
      departureCity,
      arrivalCity,
      todayDate,
      citiesInfoRequestStatus,
      tripsRequestStatus
    } = this.state;
    const { selectedDate } = this.props;

    if (citiesInfoRequestStatus.status === Status.NOT_STARTED) {
      return null;
    } else if (citiesInfoRequestStatus.status === Status.LOADING) {
      return <Loading />;
    } else if (citiesInfoRequestStatus.status === Status.SUCCESS) {
      const departureCityName = departureCity.displayName;
      const arrivalCityName = arrivalCity.displayName;
      const coverImageUrl = `https://res.cloudinary.com/dwvw6ursm/image/upload/v1684209424/app/cities/HD/${arrivalCity.id}.jpg`;

      const todayDateMoment = backendDateStringToMoment(todayDate);
      const selectedDateMoment = backendDateStringToMoment(selectedDate);
      const daysDiffBetweenSelecteDateAndTodayDate = selectedDateMoment.diff(
        todayDateMoment,
        "day"
      );
      let offsetDays =
        daysDiffBetweenSelecteDateAndTodayDate > 2
          ? daysDiffBetweenSelecteDateAndTodayDate - 2
          : 0;

      return (
        <>
          <Helmet>
            <title>
              Viagens de {departureCityName} para {arrivalCityName}
            </title>
          </Helmet>
          <div className={style.mainContainer}>
            <div
              className={style.cover}
              style={{
                backgroundImage: `url(${coverImageUrl})`
              }}
            >
              <div className={style.coverTextContainer}>
                <span className={style.coverTextDestination}>
                  Viagens para <strong>{arrivalCityName}</strong>
                </span>
                <span className={style.coverTextDeparture}>
                  ({departureCityName})
                </span>
              </div>
            </div>
            <div className={style.dateContainer}>
              <div
                className={classNames(style.date, {
                  [style.active]: backendDateStringToMoment(
                    selectedDate
                  ).isSame(backendDateStringToMoment(todayDate), "day")
                })}
                onClick={() => this.setSelectedDate(todayDate)}
              >
                Hoje
              </div>
              {[1, 2, 3, 4].map(index => {
                const dateMoment = todayDateMoment
                  .clone()
                  .add(index + offsetDays, "day");

                const isThisTheSelectedDate = dateMoment.isSame(
                  selectedDateMoment,
                  "day"
                );

                if (tripsRequestStatus.status === Status.LOADING) {
                  return (
                    <div
                      className={classNames(style.dateLoadingSkeletonContainer)}
                    >
                      <Skeleton />
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={classNames(style.date, {
                        [style.active]: isThisTheSelectedDate
                      })}
                      onClick={() =>
                        this.setSelectedDate(
                          momentToBackendDateString(dateMoment)
                        )
                      }
                    >
                      {dateMoment.isSame(todayDateMoment.clone().add(1, "days"))
                        ? "Amanhã"
                        : dateMoment.format("DD/MM")}
                    </div>
                  );
                }
              })}
              <div className={style.date} onClick={this.onSelectDateClick}>
                Escolher data
              </div>
            </div>
            <div>{this.renderTrips()}</div>
          </div>
        </>
      );
    } else if (citiesInfoRequestStatus.status === Status.ERROR) {
      return (
        <Result
          status="warning"
          title={"Erro ao carregar dados da rota"}
          subTitle={citiesInfoRequestStatus.message}
          extra={[
            <Button
              type="primary"
              key="tryAgain"
              onClick={this.fetchCitiesInfo}
            >
              Entendi
            </Button>
          ]}
        />
      );
    }
  }

  renderTrips = () => {
    const { tripsRequestStatus, trips } = this.state;

    if (tripsRequestStatus.status === Status.SUCCESS) {
      if (trips.length === 0) {
        return (
          <Empty image={null} description={"Não há viagens para essa data"} />
        );
      }

      return trips.map(trip => {
        return (
          <div key={trip.id} className={style.tripCard}>
            <div className={classNames(style.cityContainer, style.departure)}>
              <div className={style.tripTimeContainer}>
                <span className={style.tripBoardingText}>embarque</span>
                <span className={style.tripTime}>
                  {backendDateTimeStringToMoment(trip.departureTime).format(
                    "HH:mm"
                  )}
                </span>
              </div>
              <h3 className={style.city}>{trip.departureCity.name}</h3>
            </div>
            <div className={style.arrow}>
              <Icon type="arrow-right" />
            </div>
            <div className={classNames(style.cityContainer, style.arrival)}>
              <span className={style.tripBoardingText}>desembarque</span>
              <h3 className={style.city}>{trip.arrivalCity.name}</h3>
            </div>
            <div className={style.statusContainer}>
              <h3
                className={classNames(style.tripStatus, {
                  [style.scheduled]: trip.status === TripStatus.SCHEDULED,
                  [style.confirmed]: trip.status === TripStatus.CONFIRMED,
                  [style.cancelled]:
                    (trip.status !== TripStatus.CANCELLED &&
                      trip.remainingSeats === 0) ||
                    trip.status === TripStatus.CANCELLED
                })}
              >
                {(() => {
                  if (
                    trip.status !== TripStatus.CANCELLED &&
                    trip.remainingSeats === 0
                  ) {
                    return "LOTADA";
                  }

                  switch (trip.status) {
                    case TripStatus.CONFIRMED:
                      return "CONFIRMADA";
                    case TripStatus.CANCELLED:
                      return "CANCELADA";
                    case TripStatus.SCHEDULED:
                      return "AGENDADA";
                    default:
                      return trip.status;
                  }
                })()}
              </h3>
              <span className={style.remainingSeats}>
                {(() => {
                  if (
                    trip.status !== TripStatus.CANCELLED &&
                    trip.remainingSeats === 0
                  ) {
                    return "";
                  }

                  if (trip.remainingSeats === 0) {
                    return "LOTADA";
                  }
                  if (trip.remainingSeats === 1) {
                    return "Último assento disponível";
                  }
                  if (trip.remainingSeats <= 4) {
                    return `${trip.remainingSeats} assentos restantes`;
                  } else {
                    return null;
                  }
                })()}
              </span>
            </div>
            <div className={style.priceContainer}>
              <HighlightText color={PRICE_GREEN_COLOR}>
                <span>R$ {numberToPrice(trip.price)}</span>
              </HighlightText>
            </div>
            <div className={style.detailsButton}>
              <Button
                type="primary"
                onClick={() => this.onReservationClick(trip.id)}
                style={{ marginLeft: "5px" }}
              >
                Ver detalhes
              </Button>
            </div>
          </div>
        );
      });
    } else if (tripsRequestStatus.status === Status.LOADING) {
      return (
        <>
          {[0, 1, 2].map(value => (
            <div key={value}>
              <div className={style.tripsLoadingSkeletonTitleContainer}>
                <Skeleton />
              </div>
              <div className={style.tripsLoadingSkeletonParagraphContainer}>
                <Skeleton count={3} />
              </div>
            </div>
          ))}
        </>
      );
    } else if (tripsRequestStatus.status === Status.ERROR) {
      return (
        <Result
          status="warning"
          title={"Erro ao obter a lista de viagens"}
          subTitle={tripsRequestStatus.message}
          extra={[
            <Button type="primary" key="goBack" onClick={this.fetchTrips}>
              Entendi
            </Button>
          ]}
        />
      );
    }

    return null;
  };
}

const mapStateToProps = state => {
  return {
    selectedDate: state.tripCalendarReducer.selectedDate
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setSelectedDate
    },
    dispatch
  );
};

TripCalendar.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  roles: PropTypes.object.isRequired,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func.isRequired
};

export default lodash.flowRight(
  withRoles,
  remountOnUrlParamsChange,
  connect(mapStateToProps, mapDispatchToProps)
)(TripCalendar);
