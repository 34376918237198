import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Modal, Button } from "antd";
import { withStore } from "../../../hocs/withStore";
import { setRememberMe } from "../../../redux/actions/token";
import { getToken, setToken } from "../../../services/auth";
import "./styles.css";

class RememberMeModal extends Component {
  render() {
    const { visible, callback } = this.props;

    const handleClick = rememberMe => {
      const { dispatch } = this.props;

      if (rememberMe) {
        const tokenString = getToken();
        setToken(tokenString, rememberMe);
        dispatch(setRememberMe(rememberMe));
      }

      callback();
    };

    return (
      <Modal
        title={
          <span className="login-form-header">
            Sua conta foi criada com sucesso
          </span>
        }
        visible={visible}
        centered
        footer={[
          <Button key="no" onClick={() => handleClick(false)}>
            Não
          </Button>,
          <Button key="yes" type="primary" onClick={() => handleClick(true)}>
            Sim
          </Button>
        ]}
        className={"container"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <p>
            Deseja manter-se logado ao fechar o navegador? Isso vai reduzir o
            número de vezes que você precisará fazer login.
          </p>
          <p>
            Apenas clique em Sim se esse for um dispositivo pessoal para evitar
            que outras pessoas acessem sua conta.
          </p>
        </div>
      </Modal>
    );
  }
}

RememberMeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  storeState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default lodash.flow([withStore])(RememberMeModal);
