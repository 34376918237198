import * as NotificationsType from "../actionTypes/notifications";
import { NotificationTypes } from "constants/notificationTypes";
import lodash from "lodash";

const initialState = {
  defaultNotifications: [],
  chatNotifications: [],
  removedNotificationIds: []
};

const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case NotificationsType.UPDATE_NOTIFICATIONS_LIST: {
      const { removedNotificationIds } = state;

      const newDefaultNotifications = [];
      const newChatNotifications = [];

      payload.forEach(notification => {
        if (!removedNotificationIds.includes(notification.id)) {
          if (notification.type === NotificationTypes.CHAT) {
            newChatNotifications.push(notification);
          } else {
            newDefaultNotifications.push(notification);
          }
        }
      });

      return {
        ...state,
        defaultNotifications: newDefaultNotifications,
        chatNotifications: newChatNotifications
      };
    }
    case NotificationsType.REMOVE_NOTIFICATION: {
      const { defaultNotifications, chatNotifications } = state;
      const removedNotificationIds = [];
      let newNotificationsList = lodash.clone(
        payload.type === NotificationTypes.CHAT
          ? chatNotifications
          : defaultNotifications
      );
      newNotificationsList = lodash.remove(
        newNotificationsList,
        notification => notification.id !== payload.id
      );
      removedNotificationIds.push(payload.id);

      if (payload.type === NotificationTypes.CHAT) {
        return {
          ...state,
          chatNotifications: newNotificationsList,
          removedNotificationIds
        };
      } else {
        return {
          ...state,
          defaultNotifications: newNotificationsList,
          removedNotificationIds
        };
      }
    }
    default:
      return state;
  }
};

export default notifications;
