import * as NotificationsType from "../actionTypes/notifications";

export const removeNotification = value => ({
  type: NotificationsType.REMOVE_NOTIFICATION,
  payload: value
});

export const updateNotifications = notifications => ({
  type: NotificationsType.UPDATE_NOTIFICATIONS_LIST,
  payload: notifications
});
