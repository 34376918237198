import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import classNames from "classnames/bind";
import { Helmet } from "react-helmet";
import { Button, Input } from "antd";
import {
  sendChatMessage,
  markMessageAsRead,
  getMoreMessages,
  hasMessageBefore
} from "queries/queries";
import { SubscriptionsUrl } from "constants/urls";
import { CHAT_SYSTEM_MESSAGE_SENDER } from "constants/backend";
import { getParamsFromUrl } from "util/routeUtils";
import { backendDateTimeStringToMoment } from "util/formatUtils";
import { remountOnUrlParamsChange } from "hocs/remountOnUrlParamsChange";
import { connect } from "react-redux";
import "./styles.css";

const { TextArea } = Input;

const MESSAGE_CHAR_LIMIT = 1000;

class Chat extends Component {
  messageInput = React.createRef();
  chatEventSourceIsMounted = false;

  state = {
    messages: [],
    hasMoreMessages: false,
    isFetchingMoreMessages: false,
    newMessage: ""
  };

  constructor(props) {
    super(props);

    this.eventSource = null;
  }

  componentDidMount() {
    // this.messageInput.focus();
    //
    // this.setState({
    //   messages: []
    // });

    this.startEventSourceListener();
  }

  componentWillUnmount() {
    this.stopEventSourceListener();
  }

  startEventSourceListener = () => {
    const { chatCode } = getParamsFromUrl(this.props);
    this.eventSource = new EventSource(
      SubscriptionsUrl.CHAT.replace(":chatCode", chatCode)
    );
    this.chatEventSourceIsMounted = true;
    this.eventSource.onmessage = e => {
      if (this.chatEventSourceIsMounted) {
        const newMessage = JSON.parse(e.data);
        this.handleMessageReceived(newMessage);
      }
    };
  };

  stopEventSourceListener = () => {
    this.chatEventSourceIsMounted = false;
    if (this.eventSource) {
      this.eventSource.close();
    }
  };

  fetchMoreMessages = () => {
    const { messages } = this.state;
    const { chatCode } = getParamsFromUrl(this.props);

    const firstMessageId = messages[0].id;

    this.setState({
      isFetchingMoreMessages: true
    });

    getMoreMessages(chatCode, firstMessageId)
      .then(messages => {
        messages.reverse(); //reverse array since it is ordered by id desc
        messages.forEach(message => {
          this.handleMessageReceived(message);
        });
      })
      .catch(error => {
        //TODO check what to do if there is an error when trying to fetch older messages
      });

    //No need to set isFetchingMoreMessages to false.
    //It will be done on checkIfThereAreMoreMessagesToBeFetched() that will be executed after fetchMoreMessages().
  };

  checkIfThereAreMoreMessagesToBeFetched = messageId => {
    const { chatCode } = getParamsFromUrl(this.props);

    hasMessageBefore(chatCode, messageId)
      .then(hasMoreMessages => {
        this.setState({
          hasMoreMessages: hasMoreMessages
        });
      })
      .catch(error => {
        //TODO check what to do if there is an error when trying to check if there are more messages
      })
      .finally(() => {
        this.setState({
          isFetchingMoreMessages: false
        });
      });
  };

  sendMessage = () => {
    const { newMessage } = this.state;
    const { chatCode } = getParamsFromUrl(this.props);
    const trimmedMessage = newMessage.trim();

    if (trimmedMessage && trimmedMessage.length <= MESSAGE_CHAR_LIMIT) {
      this.setState({
        newMessage: ""
      });

      sendChatMessage(chatCode, trimmedMessage)
        .then(() => {})
        .catch(error => {
          //TODO give feedback to user when fail to send message
        })
        .finally(() => {});
    }

    this.messageInput.focus();
  };

  handleMessageReceived = newMessage => {
    const { userName } = this.props;
    const { messages } = this.state;

    const messageFound = messages.find(message => message.id === newMessage.id);

    //If the received message was found on array (is already being displayed on chat),
    // just update the message in the array with the new data
    if (messageFound) {
      Object.assign(messageFound, newMessage);
    } else {
      //If not, get the index of the position the message should be inserted, based on the time the message was sent
      let positionToInsert = messages.length;
      for (let i = 0; i < messages.length; i++) {
        if (
          backendDateTimeStringToMoment(messages[i].sentAt).isAfter(
            backendDateTimeStringToMoment(newMessage.sentAt)
          )
        ) {
          positionToInsert = i;
          break;
        }
      }

      //insert the message in the correct position
      messages.splice(positionToInsert, 0, newMessage);

      //if the position to insert the message is 0, we should query backend
      // to check if there are more messages to be fetched
      if (positionToInsert === 0) {
        this.checkIfThereAreMoreMessagesToBeFetched(newMessage.id);
      } else {
        //If new message arrives, scroll to bottom of messages container
        // setTimeout(() => {
        //   let messageBody = document.querySelector("#chatBox");
        //   messageBody.scrollTop =
        //     messageBody.scrollHeight - messageBody.clientHeight;
        // }, 200);
      }
    }

    this.setState({
      messages: messages
    });

    //If I am not the message sender, mark message as read
    const { id, username: messageSender, unread } = newMessage;
    if (unread && messageSender !== userName) {
      markMessageAsRead(id)
        .then(response => {})
        .catch(error => {
          //TODO check what to do if there is an error when trying to mark the message as read
        });
    }
  };

  handleMessageInputChange = e => {
    const eventValue = lodash.get(e, "target.value", "");
    const newValue =
      eventValue.trim().length > 0 ? eventValue.replace("\n", " ") : "";
    this.setState({ newMessage: newValue });
  };

  renderFetchMoreMessagesButton = () => {
    const { hasMoreMessages, isFetchingMoreMessages } = this.state;

    if (hasMoreMessages) {
      return (
        <div style={{ display: "flex" }}>
          <Button
            size={"small"}
            onClick={this.fetchMoreMessages}
            loading={isFetchingMoreMessages}
            style={{ margin: "0px auto" }}
          >
            Carregar mais
          </Button>
        </div>
      );
    }

    return <span className="system-message">Início da conversa</span>;
  };

  renderMessages = () => {
    const { userName } = this.props;
    const { messages } = this.state;

    let sentTimeAux = "";
    let dateSeparatorAux = "";
    let isShowDateHour = false;
    let isShowDateSeparator = false;

    return (
      <>
        {messages.map((message, index) => {
          if (message.username === CHAT_SYSTEM_MESSAGE_SENDER) {
            return (
              <span style={{ color: "red" }} className="system-message">
                {message.value}
              </span>
            );
          }

          const sentTime = backendDateTimeStringToMoment(message.sentAt).format(
            "HH:mm"
          );
          const dateSeparator = backendDateTimeStringToMoment(
            message.sentAt
          ).format("ddd, DD/MM/YYYY");

          const isUsername = message.username === userName;

          if (index === 0 || dateSeparatorAux !== dateSeparator) {
            dateSeparatorAux = dateSeparator;
            isShowDateSeparator = true;
          } else {
            isShowDateSeparator = false;
          }

          if (index === 0 || sentTimeAux !== sentTime) {
            sentTimeAux = sentTime;
            isShowDateHour = true;
          } else {
            isShowDateHour = false;
          }

          return (
            <>
              {isShowDateSeparator ? (
                <span className="system-message">{dateSeparator}</span>
              ) : null}

              {isShowDateHour ? (
                <span
                  className={classNames(
                    "message-time",
                    isUsername ? "message-time-sent" : "message-time-received"
                  )}
                >
                  {isUsername ? sentTime : message.name + ", " + sentTime}
                </span>
              ) : null}

              <div
                key={message.id}
                className={classNames(
                  "messages",
                  isUsername ? "sent" : "received"
                )}
              >
                <span className={"message-text"}>{message.value}</span>
              </div>
            </>
          );
        })}
      </>
    );
  };

  render() {
    const { newMessage } = this.state;
    return (
      <>
        <Helmet>
          <title>Chat | Vai de Van</title>
        </Helmet>
        <div className={"chat-container"}>
          <div id="chatBox" className={"chat-container-inner"}>
            {this.renderFetchMoreMessagesButton()}
            {this.renderMessages()}
          </div>
        </div>
        <div className={"input-container"}>
          {(() => {
            if (newMessage.length > MESSAGE_CHAR_LIMIT - 20)
              return (
                <span
                  className={classNames(
                    "remaining-characters",
                    newMessage.length > MESSAGE_CHAR_LIMIT
                      ? "beyond-limit"
                      : null
                  )}
                >
                  {MESSAGE_CHAR_LIMIT - newMessage.length} caracteres restantes
                </span>
              );
          })()}
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <TextArea
              ref={input => {
                this.messageInput = input;
              }}
              value={newMessage}
              autoSize={{ minRows: 1, maxRows: 4 }}
              className={"message-input"}
              style={{
                resize: "none",
                borderRadius: "4px"
              }}
              onChange={this.handleMessageInputChange}
              onKeyPress={event => {
                const keyCode = event.which || event.keyCode;
                // If the key pressed is Enter...
                if (keyCode === 13) {
                  event.preventDefault();
                  this.sendMessage();
                }
              }}
            />
            <span
              className={classNames(
                "send-button",
                newMessage.length === 0 ||
                  newMessage.length > MESSAGE_CHAR_LIMIT
                  ? "disabled"
                  : null
              )}
              onClick={this.sendMessage}
            >
              Enviar
            </span>
          </div>
        </div>
      </>
    );
  }
}

Chat.propTypes = {
  match: PropTypes.any.isRequired,
  userName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    userName: state.userInfoReducer.userName
  };
};

export default lodash.flowRight(
  connect(mapStateToProps),
  remountOnUrlParamsChange
)(Chat);
