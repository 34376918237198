import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import style from "./styles.module.css";

const AcceptedFaceToFacePaymentMethodsInfoModal = ({ visible, callback }) => {
  return (
    <Modal
      title={
        <span className={style.modalHeader}>
          Formas de pagamento aceitas no momento do embarque
        </span>
      }
      visible={visible}
      centered
      onCancel={() => {
        callback();
      }}
      footer={null}
      className="modal"
    >
      <div className={style.modalBody}>
        <div>
          {/*<h2 className={style.title}>Crianças</h2>*/}
          <span>
            <b>- Pix</b>
          </span>
          <br />
          <span>
            <b>- Dinheiro</b>
          </span>
          <br />
          <span>
            <b>- Cartão de débito</b>
          </span>
          <br />
          <span>
            <b>- Cartão de crédito</b> (note que há uma taxa ao utilizar essa
            forma de pagamento)
          </span>
        </div>
      </div>
    </Modal>
  );
};

AcceptedFaceToFacePaymentMethodsInfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired
};

export default AcceptedFaceToFacePaymentMethodsInfoModal;
