import React, { Component } from "react";
import PropTypes from "prop-types";
import StringMask from "string-mask";
import { Button, Result, Modal, message, Icon, Tooltip } from "antd";
import RequestStatus, { Status } from "model/RequestStatus";
import {
  getParamsFromPrevLocation,
  removeSubRoutesFromCurrentUrl
} from "util/routeUtils";
import { backendDateTimeStringToMoment, numberToPrice } from "util/formatUtils";
import { InternalUrl } from "constants/urls";
import HighlightText from "components/highlightText/HighlightText";
import SimpleCard from "components/cards/SimpleCard";
import Loading from "../../../../components/loading";
import { PaymentMethods } from "../../../../constants/paymentMethods";
import { cancelReservation } from "queries/queries";
import SubRoutes from "../subRoutes";
import "./styles.css";
import { PRIMARY_COLOR } from "../../../../constants/colorDefs";
import showModal from "../../../../util/modalHandler";
import AcceptedFaceToFacePaymentMethodsInfoModal from "components/modals/acceptedFaceToFacePaymentMethodsInfoModal/AcceptedFaceToFacePaymentMethodsInfoModal";

const RESERVATION_DATE_DATEFORMAT = "dddd, DD/MM/YYYY, [às] HH:mm";
const DEPARTURE_DATE_DATEFORMAT = "dddd, DD/MM/YYYY, [às] HH:mm";

class Confirmed extends Component {
  detailsRef = React.createRef();

  state = {
    cancellationRequestStatus: new RequestStatus(Status.NOT_STARTED)
  };

  constructor(props) {
    super(props);

    const { tripDetails, reservation } = getParamsFromPrevLocation(props);

    this.state = {
      ...this.state,
      tripDetails,
      reservation
    };
  }

  onCancelReservationClick = () => {
    const {
      tripDetails,
      reservation: { code: reservationCode }
    } = this.state;
    const { history } = this.props;

    Modal.confirm({
      title: "Cancelar reserva",
      content: "Deseja realmente cancelar essa reserva?",
      centered: true,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => {
        this.setState({
          cancellationRequestStatus: new RequestStatus(
            Status.LOADING,
            "Cancelando reserva"
          )
        });

        cancelReservation(reservationCode)
          .then(reservation => {
            message.success("Sua reserva foi cancelada!");
            const nextLocation = removeSubRoutesFromCurrentUrl(
              this.props,
              InternalUrl.RESERVATION
            ).concat(SubRoutes.CANCELLED);
            history.replace(nextLocation, {
              reservation,
              tripDetails
            });
          })
          .catch(error => {
            this.setState({
              cancellationRequestStatus: new RequestStatus(
                Status.ERROR,
                error.message
              )
            });
          });
      }
    });
  };

  openArrivalLocationOnMaps = () => {
    const {
      reservation: {
        boardingPoint: { locationLink }
      }
    } = this.state;

    window.open(locationLink, "_blank");
  };

  showFaceToFaceAcceptedPaymentMethodsModal = () => {
    showModal(AcceptedFaceToFacePaymentMethodsInfoModal);
  };

  renderResultSubTitle = () => {
    const {
      tripDetails: {
        departureTime,
        departure: { city: departureCity, state: departureState },
        arrival: { city: arrivalCity, state: arrivalState }
      },
      reservation: { boardingPoint }
    } = this.state;

    let finalDepartureCity = departureCity;
    let finalDepartureState = departureState;
    let departureTimeOffsetInMinutes = 0;
    if (boardingPoint) {
      finalDepartureCity = boardingPoint.place.city;
      finalDepartureState = boardingPoint.place.state;
      departureTimeOffsetInMinutes = boardingPoint.offsetInMinutes;
    }

    const departureDateString = backendDateTimeStringToMoment(departureTime)
      .add(departureTimeOffsetInMinutes, "minutes")
      .format(DEPARTURE_DATE_DATEFORMAT);

    return (
      <>
        <span>
          De {finalDepartureCity} - {finalDepartureState} a {arrivalCity} -{" "}
          {arrivalState}
        </span>
        <br />
        <span>{departureDateString}</span>
      </>
    );
  };

  buildResultButtons = () => {
    const {
      tripDetails: { id: tripId }
    } = this.state;
    const { history } = this.props;

    return [
      <Button
        key="trip"
        onClick={() => {
          history.push(InternalUrl.TRIP_DETAILS.replace(":tripId", tripId));
        }}
      >
        Ver viagem
      </Button>,
      <Button key="map" type="primary" onClick={this.openArrivalLocationOnMaps}>
        Ver no mapa
      </Button>
    ];
  };

  renderTripSection = () => {
    const { tripDetails, reservation } = this.state;

    const {
      departureTime,
      departure: { fullName: fullDeparture },
      arrival: { fullName: fullArrival }
    } = tripDetails;

    const { boardingPoint } = reservation;

    let departureAddress = fullDeparture;
    let displayName;
    let departureTimeOffsetInMinutes = 0;
    if (boardingPoint) {
      departureAddress = boardingPoint.place.fullName;
      displayName = boardingPoint.displayName; //eslint-disable-line prefer-destructuring
      departureTimeOffsetInMinutes = boardingPoint.offsetInMinutes;
    }

    const departureDateString = backendDateTimeStringToMoment(departureTime)
      .add(departureTimeOffsetInMinutes, "minutes")
      .format(DEPARTURE_DATE_DATEFORMAT);

    return (
      <>
        <h3>Viagem</h3>
        <p>
          <strong>Data:</strong> {departureDateString}.
        </p>
        <p>
          <strong>Local de embarque:</strong>{" "}
          {displayName
            ? `${displayName} em ${departureAddress}`
            : departureAddress}
          .
          <br />
          <span onClick={this.openArrivalLocationOnMaps}>
            <HighlightText> Clique aqui</HighlightText> ou no botão{" "}
            <HighlightText>Ver no mapa</HighlightText> logo acima para ver o
            local de embarque no Google Maps.
          </span>
        </p>
        <p>
          <strong>Local de desembarque:</strong> {fullArrival}.
        </p>
        <p>
          Por favor, esteja no local de embarque com{" "}
          <HighlightText>15 minutos</HighlightText> de antecedência.
        </p>
      </>
    );
  };

  renderPaymentSection = () => {
    const { reservation } = this.state;

    const { paymentMethod, actualPrice } = reservation;

    return (
      <>
        <h3>Pagamento</h3>
        <p>
          <strong>Total:</strong> R$ {numberToPrice(actualPrice)}.
        </p>
        <p>
          <strong>Forma de pagamento:</strong>{" "}
          {(() => {
            if (paymentMethod === PaymentMethods.CREDIT_CARD)
              return "Pago via cartão de crédito.";
            else if (paymentMethod === PaymentMethods.FACE_TO_FACE)
              return (
                <>
                  <span>
                    Pagamento deverá ser feito no momento do embarque.
                  </span>
                  <br />
                  <span
                    onClick={this.showFaceToFaceAcceptedPaymentMethodsModal}
                  >
                    <HighlightText>Clique aqui</HighlightText> para ver as
                    formas de pagamento aceitas no momento do embarque.
                  </span>
                </>
              );
            else if (paymentMethod === PaymentMethods.PIX)
              return "Pago via PIX.";
          })()}
        </p>
      </>
    );
  };

  renderReservationSection = () => {
    const {
      reservation: { code, createdAt }
    } = this.state;

    const reservationDate = backendDateTimeStringToMoment(createdAt).format(
      RESERVATION_DATE_DATEFORMAT
    );

    return (
      <>
        <h3>Reserva</h3>
        <p>
          <strong>Reserva feita em:</strong> {reservationDate}.
        </p>
        <p>
          <strong>Código da reserva:</strong> {code}.{" "}
          <Tooltip placement="topRight" title="Copiar código da reserva">
            <Icon
              type="copy"
              theme={"filled"}
              style={{
                color: PRIMARY_COLOR,
                fontSize: "15px",
                cursor: "pointer"
              }}
              onClick={() => {
                try {
                  navigator.clipboard.writeText(code);
                  message.success("O código da reserva foi copiado");
                } catch (e) {
                  message.error(
                    "Erro ao copiar o código da reserva. Por favor, tente copiar manualmente."
                  );
                }
              }}
            />
          </Tooltip>
        </p>
      </>
    );
  };

  renderPassengersSection = () => {
    const {
      reservation: { passengers }
    } = this.state;

    return (
      <>
        <h3>{passengers.length > 1 ? "Passageiros" : "Passageiro"}</h3>
        {passengers.map((passenger, index) => {
          const maskedCpf = StringMask.apply(passenger.cpf, "000.000.000-00");
          const maskedPhone = StringMask.apply(
            passenger.phone,
            "(00) 0 0000-0000"
          );
          return (
            <SimpleCard key={passenger.id}>
              <span>
                <strong>{passenger.name}</strong>
              </span>
              <br />
              <span>CPF: {maskedCpf}</span>
              <br />
              <span>Celular: {maskedPhone}</span>
            </SimpleCard>
          );
        })}
      </>
    );
  };

  render() {
    const { cancellationRequestStatus } = this.state;

    if (cancellationRequestStatus.status === Status.LOADING) {
      return <Loading loadingMessage={cancellationRequestStatus.message} />;
    }

    if (cancellationRequestStatus.status === Status.ERROR) {
      return (
        <Result
          status="warning"
          title="Não foi possível cancelar a reserva"
          subTitle={cancellationRequestStatus.message}
          extra={[
            <Button
              type="primary"
              key="tryAgain"
              onClick={() => {
                this.setState({
                  cancellationRequestStatus: new RequestStatus(
                    Status.NOT_STARTED
                  )
                });
              }}
            >
              Entendi
            </Button>
          ]}
        />
      );
    }

    return (
      <div>
        <Result
          status="success"
          title="Sua reserva está confirmada"
          subTitle={this.renderResultSubTitle()}
          extra={this.buildResultButtons()}
        />
        <div style={{ marginBottom: "8px" }}>
          {this.renderTripSection()}
          {this.renderPaymentSection()}
          {this.renderReservationSection()}
          {this.renderPassengersSection()}
          <Button type="danger" ghost onClick={this.onCancelReservationClick}>
            Cancelar reserva
          </Button>
        </div>
      </div>
    );
  }
}

Confirmed.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired
};

export default Confirmed;
