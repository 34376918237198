import React, { Component } from "react";
import PropTypes from "prop-types";
import CountdownText from "../countdownText";
import {
  backendDateTimeStringToMoment,
  currentTimeMoment,
  timeDiff
} from "../../util/formatUtils";
import { MINUTES_BEFORE_RESERVATION_DEADLINE_TO_SHOW_WARNING } from "../../constants/backend";
import { Affix } from "antd";

class ReservationCountdown extends Component {
  tickInterval;

  state = {
    shouldDisplayCountdown: false,
    timeIsOver: false
  };

  componentDidMount() {
    this.calculateTimeIsOver();
    this.tickInterval = setInterval(this.calculateTimeIsOver, 100);
  }

  componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  calculateTimeIsOver = () => {
    let { reservationAllowanceDeadline } = this.props;
    const timeDiffDuration = timeDiff(
      currentTimeMoment(),
      backendDateTimeStringToMoment(reservationAllowanceDeadline)
    );

    const shouldDisplayCountdown =
      timeDiffDuration.asMinutes() <=
      MINUTES_BEFORE_RESERVATION_DEADLINE_TO_SHOW_WARNING;

    const timeIsOver = timeDiffDuration.asMilliseconds() <= 0;

    this.setState({
      shouldDisplayCountdown,
      timeIsOver
    });
  };

  render() {
    const { reservationAllowanceDeadline } = this.props;
    const { shouldDisplayCountdown, timeIsOver } = this.state;

    if (shouldDisplayCountdown) {
      const text = timeIsOver ? (
        <>O prazo para reservas dessa viagem já se esgotou</>
      ) : (
        <>
          As reservas se encerram em{" "}
          <CountdownText dateTimeString={reservationAllowanceDeadline} />
        </>
      );

      return (
        <Affix offsetTop={64}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              width: "calc(100% + 20px)",
              margin: "-10px -10px 10px -10px",
              backgroundColor: "red",
              color: "white"
            }}
          >
            <span>{text}</span>
          </div>
        </Affix>
      );
    }

    return null;
  }
}

ReservationCountdown.defaultProps = {
  reservationAllowanceDeadline: null
};

ReservationCountdown.propTypes = {
  reservationAllowanceDeadline: PropTypes.string
};

export default ReservationCountdown;
