import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input, Modal, Form, Button, Icon } from "antd";
import "./styles.css";

const FormFields = Object.freeze({
  PASSWORD: "password"
});

class CurrentPasswordModal extends Component {
  handleSubmit = e => {
    const { callback, form } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const password = values[FormFields.PASSWORD];

        callback(password);
      }
    });
  };

  render() {
    const { visible, callback, form, title } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const continueButtonDisabled = !getFieldValue(FormFields.PASSWORD);

    return (
      <Modal
        title={<span className="login-form-header">{title}</span>}
        visible={visible}
        centered
        onCancel={() => {
          callback();
        }}
        footer={null}
        className={"container"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <Form onSubmit={this.handleSubmit} className={"login-form"}>
            <Form.Item>
              {getFieldDecorator(FormFields.PASSWORD, {
                initialValue: "",
                rules: [
                  { required: true, message: "Por favor, digite sua senha." }
                ]
              })(
                <Input.Password
                  size={"large"}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Senha"
                  autoFocus
                />
              )}
            </Form.Item>
            <div className={"buttons"}>
              <Button size={"large"} onClick={() => callback()}>
                Cancelar
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size={"large"}
                disabled={continueButtonDisabled}
              >
                Continuar
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

CurrentPasswordModal.defaultProps = {
  title: "Digite sua senha para completar essa operação"
};

CurrentPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default Form.create({ name: "login-modal" })(CurrentPasswordModal);
