import React from "react";
import PropTypes from "prop-types";
import { Spin, Icon } from "antd";
import styled from "styled-components";

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = ({ loadingMessage }) => (
  <SpinContainer>
    <StyledSpin
      size="large"
      tip={loadingMessage}
      indicator={<Icon type="loading" style={{ fontSize: 36 }} spin />}
    />
  </SpinContainer>
);

Loading.defaultProps = {
  loadingMessage: null
};

Loading.propTypes = {
  loadingMessage: PropTypes.string
};

export default Loading;
