const commonQuestions = [
  {
    question: "A Vai de Van é segura?",
    answer:
      "Sim. A Vai de Van se preocupa muito com a sua segurança. Por " +
      "isso, todas as empresas parceiras estão devidamente seguradas " +
      "e aderentes aos orgãos regulatórios. Além disso, todas as " +
      "empresas passam por um rígido processo de avaliação antes de " +
      "serem admitidas dentro da nossa plataforma. Você também pode " +
      "nos ajudar avaliando as empresas e quaisquer aspectos da sua " +
      "experiência com a Vai de Van. Dessa forma, podemos agir com o " +
      "intuito de melhorar cada vez mais a nossa relação, garantindo " +
      "sua segurança, aliada a seu conforto e economia."
  },
  {
    question: "Como a Vai de Van consegue oferecer preços mais baixos?",
    answer:
      "A Vai de Van opera em um sistema conhecido como " +
      "fretamento coletivo. O que fazemos é juntar pessoas interessadas " +
      "em uma determinada rota com alguma empresa que ofereça essa rota com o melhor " +
      "preço e qualidade para os passageiros. Dessa maneira, ao " +
      "comprar um bilhete pela Vai de Van, você está, na verdade, " +
      "pagando uma pequena fração do frete que a empresa cobra pelo " +
      "percurso. Isso é economicamente mais eficiente do que comprar " +
      "os tradicionais bilhetes rodoviários, uma vez que as empresas " +
      "tradicionais trabalham com lucros altíssimos que são " +
      "garantidos pela falta de concorrência."
  },
  {
    question: "Como posso pagar o meu bilhete?",
    answer:
      "Atualmente trabalhamos com pagamento via Pix, uma forma de pagamento segura e instantânea, " +
      "permitindo que sua reserva seja confirmada na hora. " +
      "Além disso, estamos trabalhando para adicionar outros meios de pagamento através " +
      "da plataforma, como boleto e cartão de crédito, para dar aos nossos usuários mais liberdade na escolha do meio de pagamento."
  },
  {
    question: "Posso cancelar uma reserva?",
    answer:
      "Você pode solicitar o cancelamento da sua reserva com pelo menos 3 horas de antecedência. " +
      "O simples não comparecimento ao local de embarque, contudo, " +
      "poderá acarretar em restrições na sua conta."
  },
  {
    question: "Como posso cancelar uma reserva?",
    answer:
      "Para cancelar uma reserva basta apenas clicar no botão 'Cancelar reserva' que está na tela da reserva. " +
      "Lembre-se que operamos em regime de fretamento coletivo e, por isso, o cancelamento de um passageiro pode trazer " +
      "impactos à operação e dificultar a viabilidade de ocorrência de uma viagem. Portanto, sempre que possível, " +
      "orientamos que o cancelamento seja solicitado o quanto antes."
  },
  {
    question: "Quem pode efetuar uma reserva?",
    answer:
      "Qualquer pessoa com mais de 18 anos e um cadastro ativo junto " +
      "à Vai de Van está apto a reservar."
  },
  {
    question: "Quantos assentos posso reservar?",
    answer:
      "Não há limite prévio do número de assentos que um mesmo " +
      "usuário pode reservar. O único limite é o de assentos " +
      "disponíveis no ato da compra."
  },
  {
    question: "Quanto posso levar de bagagem?",
    answer:
      "Cada passageiro tem direito ao despacho de uma mala, mais uma " +
      "pequena bagagem de mão. Para casos em que esse volume seja " +
      "insuficiente, recomenda-se uma consulta prévia de modo que " +
      "possamos avaliar cada situação com antecedência."
  }
];

export default commonQuestions;
