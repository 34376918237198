import * as EventsType from "../actionTypes/events";

const initialState = {
  isDesktop: true,
  scrollPosition: 0,
  lastRestoredScrollPosition: 0,
  randomNumberToTriggerRestore: 0
};

const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case EventsType.SET_IS_DESKTOP: {
      return {
        ...state,
        isDesktop: payload
      };
    }
    case EventsType.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: payload
      };
    }
    case EventsType.RESTORE_SCROLL_POSITION: {
      return {
        ...state,
        lastRestoredScrollPosition: payload,
        randomNumberToTriggerRestore: Math.ceil(Math.random() * 1000)
      };
    }
    default:
      return state;
  }
};

export default events;
