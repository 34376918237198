import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Input, Button, message, Checkbox, Divider } from "antd";
import { Form, Modal, Icon } from "antd";
import { login, oAuthLogin } from "../../queries/queries";
import "./styles.css";
import { setToken } from "../../services/auth";
import { InternalUrl } from "../../constants/urls";
import { getParamsFromPrevLocation } from "../../util/routeUtils";
import lodash from "lodash";
import { bindActionCreators } from "redux";
import { setRememberMe } from "../../redux/actions/token";
import { connect } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import EmailLink from "../../components/emailLink/EmailLink";

const logo = require("assets/img/logo.png");

const FormFields = Object.freeze({
  EMAIL_OR_CPF: "emailOrCpf",
  PASSWORD: "password",
  REMEMBER_ME: "rememberMe"
});

class Login extends Component {
  state = {
    loading: false,
    fromUnauthorizedLocation: false
  };

  componentDidMount() {
    const prevPageState = getParamsFromPrevLocation(this.props);
    const fromUnauthorizedLocation = lodash.get(
      prevPageState,
      "fromUnauthorizedLocation",
      false
    );
    this.setState({
      fromUnauthorizedLocation
    });
    if (fromUnauthorizedLocation) {
      message.warning("Faça login para continuar");
    }
  }

  onLoginSuccess = token => {
    const {
      history,
      form: { getFieldValue }
    } = this.props;

    const rememberMe = getFieldValue(FormFields.REMEMBER_ME);

    setRememberMe(rememberMe);
    setToken(token, rememberMe);

    this.setState({
      loading: false
    });

    //TODO use parameters to send user to the correct destination after login
    const prevLocationState = getParamsFromPrevLocation(this.props);
    const prevLocation = lodash.get(
      prevLocationState,
      "from.pathname",
      InternalUrl.HOME
    );
    history.replace(prevLocation);
  };

  handleSubmit = e => {
    const { form } = this.props;
    const { validateFields } = form;

    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const emailOrCpf = values[FormFields.EMAIL_OR_CPF];
        const password = values[FormFields.PASSWORD];
        this.setState({
          loading: true
        });

        login(emailOrCpf, password)
          .then(token => {
            this.onLoginSuccess(token);
          })
          .catch(error => {
            this.setState({
              loading: false
            });

            Modal.error({
              title: "Não foi possível realizar o login",
              content: error.message
            });
          });
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { history, form, oAuthLoginEnabled } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <Helmet>
          <title>Entrar | Vai de Van</title>
        </Helmet>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <img
            src={logo}
            alt="Logo"
            height={"192"}
            style={{
              height: "80px",
              margin: "30px auto"
            }}
          />
          <Form onSubmit={this.handleSubmit} className={"login-form"}>
            <Form.Item>
              {getFieldDecorator(FormFields.EMAIL_OR_CPF, {
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Por favor, digite seu email ou seu CPF."
                  }
                ]
              })(
                <Input
                  size={"large"}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email ou CPF"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.PASSWORD, {
                initialValue: "",
                rules: [
                  { required: true, message: "Por favor, digite sua senha." }
                ]
              })(
                <Input.Password
                  size={"large"}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Senha"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(FormFields.REMEMBER_ME, {
                valuePropName: "checked",
                initialValue: false
              })(<Checkbox>Lembre de mim</Checkbox>)}
              <Button
                type="primary"
                htmlType="submit"
                className={"login-form-button"}
                loading={loading}
                size={"large"}
                style={{ marginTop: "20px" }}
              >
                Entrar
              </Button>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  Ou{" "}
                  <span
                    className="text-link"
                    onClick={() => {
                      history.push(InternalUrl.SIGNUP);
                    }}
                  >
                    registre-se agora!
                  </span>
                </span>
                <span
                  className="text-link"
                  onClick={() => {
                    history.push(InternalUrl.FORGOT_PASSWORD);
                  }}
                >
                  Esqueceu sua senha?
                </span>
              </div>
            </Form.Item>
          </Form>
          {oAuthLoginEnabled && (
            <>
              <div className={"divider-container"}>
                <Divider>ou entre com</Divider>
              </div>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  oAuthLogin(credentialResponse.credential)
                    .then(token => {
                      this.onLoginSuccess(token);
                      // const rememberMe = getFieldValue(FormFields.REMEMBER_ME);
                      // setToken(token, rememberMe);
                    })
                    .catch(error => {
                      //TODO
                      message.error(
                        `Erro ao tentar fazer login: ${error.message}`
                      );
                      // debugger;
                    });
                }}
                onError={() => {
                  Modal.error({
                    title: "Não foi possível fazer o login social",
                    content: (
                      <p>
                        Por favor, tente novamente. Se o problema persistir,
                        envie um email para{" "}
                        <EmailLink subject="Erro ao efetuar login social" />{" "}
                        informando que há um erro ao tentar efetuar o login
                        social.
                      </p>
                    )
                  });
                }}
                width={300}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

Login.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  form: PropTypes.PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  setRememberMe: PropTypes.func.isRequired,
  oAuthLoginEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    oAuthLoginEnabled: state.configsReducer.oAuthLoginEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setRememberMe
    },
    dispatch
  );
};

export default lodash.flow([
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: "normal_login" })
])(Login);
