import React from "react";
import PropTypes from "prop-types";
import { PRIMARY_COLOR } from "../../constants/colorDefs";

const HighlightText = ({ children, color, clickable, ...rest }) => (
  <strong
    style={{ color, cursor: clickable ? "pointer" : "default" }}
    {...rest}
  >
    {children}
  </strong>
);

HighlightText.defaultProps = {
  children: "",
  color: PRIMARY_COLOR,
  clickable: false
};

HighlightText.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  clickable: PropTypes.bool
};

export default HighlightText;
