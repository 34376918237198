import React from "react";
import PropTypes from "prop-types";
import { mdiMinusCircle, mdiPlusCircle } from "@mdi/js";
import classNames from "classnames/bind";
import Icon from "@mdi/react";

import "./styles.less";

const SeatStepButton = ({ iconPath, disabled, onClick }) => {
  return (
    <Icon
      path={iconPath}
      className={classNames("seat-change-button", { disabled })}
      onClick={onClick}
    />
  );
};

export const IncreaseSeatButton = ({ disabled, onClick }) => {
  return (
    <SeatStepButton
      iconPath={mdiPlusCircle}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export const DecreaseSeatButton = ({ disabled, onClick }) => {
  return (
    <SeatStepButton
      iconPath={mdiMinusCircle}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

SeatStepButton.propTypes = {
  iconPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

IncreaseSeatButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

DecreaseSeatButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
