import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const CLOSE_MODAL_DELAY = 300; //TODO get timeout from antd vars if possible

function showModal(modalComponent, props = {}, callback = () => {}) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  ReactDOM.render(
    <AntdModalWrapper
      modalComponent={modalComponent}
      props={props}
      callback={value => {
        closeModalAfterDelay(div, CLOSE_MODAL_DELAY);
        callback(value);
      }}
    />,
    div
  );

  return div; //modalRef
}

/**
 * Close and destroy a Modal
 * @param modalRef the modal reference returned by showModal() function
 */
function closeModal(modalRef) {
  closeModalAfterDelay(modalRef, 0);
}

/**
 * Close and destroy a Modal, without closing animation. Use it to force a modal to be closed.
 * @param modalRef the modal reference returned by showModal() function
 * @param delay time in ms the modal will take to be destroyed
 */
function closeModalAfterDelay(modalRef, delay) {
  if (typeof modalRef === "object") {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(modalRef);
    }, delay);
  }
}

class AntdModalWrapper extends Component {
  state = {
    visible: true
  };

  setVisible = newVisible => {
    this.setState({
      visible: newVisible
    });
  };

  render() {
    const { visible } = this.state;
    const { modalComponent: ModalComponent, callback, props } = this.props;

    return (
      <ModalComponent
        {...props}
        visible={visible}
        callback={value => {
          this.setVisible(false);
          callback(value);
        }}
      />
    );
  }
}

AntdModalWrapper.propTypes = {
  modalComponent: PropTypes.func.isRequired,
  props: PropTypes.any.isRequired,
  callback: PropTypes.func.isRequired
};

export default showModal;
export { closeModal };
