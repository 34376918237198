import Cookies from "universal-cookie";

const cookies = new Cookies();

const COOKIE_OPTIONS = { path: "/", secure: true }; //check options on https://www.npmjs.com/package/universal-cookie#setname-value-options

export const storeCookie = (key, value, options = COOKIE_OPTIONS) => {
  cookies.set(key, value, { ...COOKIE_OPTIONS, ...options });
};

export const getCookie = key => cookies.get(key);

export const removeCookie = key => cookies.remove(key, COOKIE_OPTIONS);
