import React, { Component } from "react";
import PropTypes from "prop-types";
import StringMask from "string-mask";
import { Button, Icon, message, Result, Tooltip } from "antd";
import RequestStatus, { Status } from "model/RequestStatus";
import { getParamsFromPrevLocation } from "util/routeUtils";
import { backendDateTimeStringToMoment, numberToPrice } from "util/formatUtils";
import { InternalUrl } from "constants/urls";
import { TripStatus } from "constants/TripStatus";
import HighlightText from "components/highlightText/HighlightText";
import SimpleCard from "components/cards/SimpleCard";
import { PaymentMethods } from "../../../../constants/paymentMethods";
import "./styles.css";
import { PRIMARY_COLOR } from "../../../../constants/colorDefs";

const RESERVATION_DATE_DATEFORMAT = "dddd, DD/MM/YYYY, [às] HH:mm";
const DEPARTURE_DATE_DATEFORMAT = "dddd, DD/MM/YYYY, [às] HH:mm";

class Cancelled extends Component {
  detailsRef = React.createRef();

  state = {
    cancellationRequestStatus: new RequestStatus(Status.NOT_STARTED)
  };

  constructor(props) {
    super(props);

    const { tripDetails, reservation } = getParamsFromPrevLocation(props);

    this.state = {
      ...this.state,
      tripDetails,
      reservation
    };
  }

  renderResultSubTitle = () => {
    const {
      tripDetails: {
        departureTime,
        departure: { city: departureCity, state: departureState },
        arrival: { city: arrivalCity, state: arrivalState }
      },
      reservation: { boardingPoint }
    } = this.state;

    let finalDepartureCity = departureCity;
    let finalDepartureState = departureState;
    let departureTimeOffsetInMinutes = 0;
    if (boardingPoint) {
      finalDepartureCity = boardingPoint.place.city;
      finalDepartureState = boardingPoint.place.state;
      departureTimeOffsetInMinutes = boardingPoint.offsetInMinutes;
    }

    const departureDateString = backendDateTimeStringToMoment(departureTime)
      .add(departureTimeOffsetInMinutes, "minutes")
      .format(DEPARTURE_DATE_DATEFORMAT);

    return (
      <>
        <span>
          De {finalDepartureCity} - {finalDepartureState} a {arrivalCity} -{" "}
          {arrivalState}
        </span>
        <br />
        <span>{departureDateString}</span>
      </>
    );
  };

  renderTripSection() {
    const {
      reservation: { boardingPoint },
      tripDetails: {
        departureTime,
        departure: { fullName: fullDeparture },
        arrival: { fullName: fullArrival }
      }
    } = this.state;

    let departureAddress = fullDeparture;
    let displayName;
    let departureTimeOffsetInMinutes = 0;
    if (boardingPoint) {
      departureAddress = boardingPoint.place.fullName;
      displayName = boardingPoint.displayName; //eslint-disable-line prefer-destructuring
      departureTimeOffsetInMinutes = boardingPoint.offsetInMinutes;
    }

    const departureDateString = backendDateTimeStringToMoment(departureTime)
      .add(departureTimeOffsetInMinutes, "minutes")
      .format(DEPARTURE_DATE_DATEFORMAT);

    return (
      <>
        <h3>Viagem</h3>
        <p>
          <strong>Data:</strong> {departureDateString}.
        </p>
        <p>
          <strong>Local de embarque:</strong>{" "}
          {displayName
            ? `${displayName} em ${departureAddress}`
            : departureAddress}
          .
        </p>
        <p>
          <strong>Local de desembarque:</strong> {fullArrival}.
        </p>
      </>
    );
  }

  renderPaymentSection() {
    const {
      reservation: { actualPrice, paymentMethod, paymentReversed }
    } = this.state;
    const { history } = this.props;

    return (
      <>
        <h3>Pagamento</h3>
        <p>
          <strong>Total:</strong> R$ {numberToPrice(actualPrice)}.
        </p>
        <p>
          <strong>Forma de pagamento:</strong>{" "}
          {actualPrice ? (
            <>
              {(() => {
                if (paymentMethod === PaymentMethods.CREDIT_CARD)
                  return (
                    <>
                      Cartão de crédito. Seu dinheiro será estornado no cartão
                      de acordo com as nossas politicas de cancelamento
                      descritas nos{" "}
                      <span
                        className="text-link"
                        onClick={() => {
                          history.push(InternalUrl.TERMS_AND_CONDITIONS);
                        }}
                      >
                        <HighlightText>Termos de uso</HighlightText>{" "}
                      </span>
                      da Vai de Van.
                    </>
                  );
                else if (paymentMethod === PaymentMethods.FACE_TO_FACE)
                  return "Pagamento presencial. Nenhum pagamento foi efetuado à Vai de Van.";
                else if (paymentMethod === PaymentMethods.PIX)
                  return (
                    <>
                      Pix.{" "}
                      {paymentReversed ? (
                        <>Seu pagamento já foi estornado.</>
                      ) : (
                        <>
                          Seu pagamento será estornado de acordo com as nossas
                          politicas de cancelamento descritas nos{" "}
                          <span
                            className="text-link"
                            onClick={() => {
                              history.push(InternalUrl.TERMS_AND_CONDITIONS);
                            }}
                          >
                            <HighlightText>Termos de uso</HighlightText>{" "}
                          </span>
                          da Vai de Van.
                        </>
                      )}
                    </>
                  );
              })()}
            </>
          ) : (
            <p>Nenhum pagamento foi efetuado.</p>
          )}
        </p>
      </>
    );
  }

  renderReservationSection() {
    const {
      reservation: { code, createdAt }
    } = this.state;

    const reservationDate = backendDateTimeStringToMoment(createdAt).format(
      RESERVATION_DATE_DATEFORMAT
    );

    return (
      <>
        <p>
          <strong>Reserva feita em:</strong> {reservationDate}.
        </p>
        <p>
          <strong>Código da reserva:</strong> {code}.{" "}
          <Tooltip placement="topRight" title="Copiar código da reserva">
            <Icon
              type="copy"
              theme={"filled"}
              style={{
                color: PRIMARY_COLOR,
                fontSize: "15px",
                cursor: "pointer"
              }}
              onClick={() => {
                try {
                  navigator.clipboard.writeText(code);
                  message.success("O código da reserva foi copiado");
                } catch (e) {
                  message.error(
                    "Erro ao copiar o código da reserva. Por favor, tente copiar manualmente."
                  );
                }
              }}
            />
          </Tooltip>
        </p>
      </>
    );
  }

  renderPassengerSection() {
    const {
      reservation: { passengers, numberOfSeats }
    } = this.state;

    return (
      <>
        <h3>{numberOfSeats > 1 ? "Passageiros" : "Passageiro"}</h3>
        {passengers.length === 0 &&
          (numberOfSeats > 1 ? (
            <p>Nenhum passageiro foi informado.</p>
          ) : (
            <p>O passageiro não foi informado.</p>
          ))}
        {passengers.map((passenger, index) => {
          const maskedCpf = StringMask.apply(passenger.cpf, "000.000.000-00");
          const maskedPhone = StringMask.apply(
            passenger.phone,
            "(00) 0 0000-0000"
          );
          return (
            <SimpleCard key={passenger.id}>
              <span>
                <strong>{passenger.name}</strong>
              </span>
              <br />
              <span>CPF: {maskedCpf}</span>
              <br />
              <span>Celular: {maskedPhone}</span>
            </SimpleCard>
          );
        })}
      </>
    );
  }

  render() {
    const {
      tripDetails: { id: tripId, status: tripStatus }
    } = this.state;
    const { history } = this.props;

    const isTripCancelled = tripStatus === TripStatus.CANCELLED;

    return (
      <div>
        <Result
          status="error"
          title={
            isTripCancelled ? "Essa viagem foi cancelada" : "Reserva cancelada"
          }
          subTitle={this.renderResultSubTitle()}
          extra={[
            <Button
              key="trip"
              type="primary"
              onClick={() => {
                history.push(
                  InternalUrl.TRIP_DETAILS.replace(":tripId", tripId)
                );
              }}
            >
              Ver viagem
            </Button>
          ]}
        />
        <div>
          {this.renderTripSection()}
          {this.renderPaymentSection()}
          {this.renderReservationSection()}
          {this.renderPassengerSection()}
        </div>
      </div>
    );
  }
}

Cancelled.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired
};

export default Cancelled;
