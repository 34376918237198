import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import {
  Alert,
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Result,
  Row,
  Tooltip,
  Typography
} from "antd";
import Card from "react-credit-cards";
import RequestStatus, { Status } from "model/RequestStatus";
import {
  getParamsFromPrevLocation,
  goToLocationForceRefresh
} from "util/routeUtils";
import { backendDateTimeStringToMoment, numberToPrice } from "util/formatUtils";
import {
  payReservationNewCreditCard,
  payReservationSavedCreditCard,
  payReservationF2fPayment,
  cancelReservation
} from "queries/queries";
import { InternalUrl } from "constants/urls";
import { Color } from "constants/colors";
import MaskedInput from "components/maskedInput";
import { validate as validateCpf } from "cpf-check";
import * as CreditCardUtils from "util/creditCardUtils";
import "./styles.css";
import Loading from "components/loading";
import { CreditCardBrands } from "constants/creditCardBrands";
import SimpleCard from "components/cards/SimpleCard";
import { ReservationStatus } from "constants/reservationStatus";
import { validateExpiryDate } from "util/creditCardUtils";
import { PRIMARY_COLOR } from "../../../../constants/colorDefs";
import { PaymentMethods } from "../../../../constants/paymentMethods";
import {
  getPixPaymentDetails,
  listPaymentMethodsforReservation
} from "../../../../queries/queries";
import { DiscountType } from "../../../../constants/discountType";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  resetNotificationIntervalToDefault,
  setNotificationInterval
} from "../../../../redux/actions/configs";
import {
  clearCardInfo,
  setCardNumber,
  setCpf,
  setCvc,
  setExpirationDate,
  setHolderName
} from "../../../../redux/actions/reservationPayment";
import { PrimaryColor } from "../../../../constants/colors";
import { encryptCard } from "util/creditCardUtils";
import StringMask from "string-mask";
import HighlightText from "../../../../components/highlightText/HighlightText";
import classNames from "classnames/bind";

const { INPUT_ICON_COLOR } = Color;
const { Title } = Typography;

const PIX_NOTIFICATION_INTERVAL = 10000;

const CurrentAction = Object.freeze({
  PAY_RESERVATION: "PAY_RESERVATION",
  CANCEL_RESERVATION: "CANCEL_RESERVATION",
  FETCH_INTIAL_DATA: "FETCH_INTIAL_DATA"
});

const PaymentFormFields = Object.freeze({
  CARD_NUMBER: "cardNumber",
  CVC: "cvc",
  EXPIRATION_MONTH: "expirationMonth",
  EXPIRATION_YEAR: "expirationYear",
  EXPIRATION_DATE: "expirationDate",
  HOLDER_NAME: "holderName",
  HOLDER_CPF: "holderCpf"
});

const CreditCardFocusedInput = Object.freeze({
  NUMBER: "number",
  CVC: "cvc",
  EXPIRY_DATE: "expiry",
  NAME: "name"
});

const QueryPaymentType = Object.freeze({
  NEW_CREDIT_CARD: "NEW_CREDIT_CARD",
  SAVED_CREDIT_CARD: "SAVED_CREDIT_CARD",
  F2F: "F2F"
});

const SelectedPaymentMethod = Object.freeze({
  NOT_SELECTED: "NOT_SELECTED",
  CREDIT_CARD: "CREDIT_CARD",
  F2F: "F2F",
  PIX: "PIX"
});

const FORM_FIELDS_AMOUNT = 5;

class Payment extends Component {
  formSnapshot = undefined;
  acceptedCards = Object.entries(CreditCardBrands).map(
    ([, value]) => value.cardLibAcceptedBrand
  );

  cardNumberRef = React.createRef();
  cvcRef = React.createRef();
  expirationDateRef = React.createRef();
  holderNameRef = React.createRef();
  holderCpfRef = React.createRef();

  state = {
    requestStatus: new RequestStatus(Status.NOT_STARTED),
    pixRequestStatus: new RequestStatus(Status.NOT_STARTED),
    currentAction: CurrentAction.FETCH_INTIAL_DATA,
    selectedPaymentMethod: SelectedPaymentMethod.NOT_SELECTED,
    maxF2fSeatsForPayment: undefined,
    tripDetails: undefined,
    reservation: undefined,
    storedCreditCards: undefined,
    paymentMethods: undefined,
    showCreditCardForm: true,
    selectedCreditCardId: undefined,
    pixData: undefined,
    isCheckingPixPaymentFast: false,
    focusedInput: undefined,
    currentFormFieldIndex: 0,
    cvcVisible: false
  };

  constructor(props) {
    super(props);

    const { tripDetails, reservation } = getParamsFromPrevLocation(props);

    this.state = {
      ...this.state,
      tripDetails,
      reservation
    };
  }

  componentDidMount() {
    const {
      reservation: { status }
    } = this.state;

    if (status === ReservationStatus.PENDING_PAYMENT_CONFIRMATION) {
      this.setState({
        requestStatus: new RequestStatus(Status.SUCCESS),
        currentAction: CurrentAction.FETCH_INTIAL_DATA
      });
    } else {
      this.fetchPaymentMethodsforReservation();
    }
  }

  componentWillUnmount() {
    const { resetNotificationIntervalToDefault, clearCardInfo } = this.props;
    resetNotificationIntervalToDefault();

    clearCardInfo();
    this.clearForm();
    this.takeFormSnapshot();
  }

  fetchPaymentMethodsforReservation = () => {
    const {
      reservation: { code }
    } = this.state;
    this.setState({
      requestStatus: new RequestStatus(
        Status.LOADING,
        "Carregando métodos de pagamento"
      ),
      currentAction: CurrentAction.FETCH_INTIAL_DATA
    });

    listPaymentMethodsforReservation(code)
      .then(response => {
        let selectedPaymentMethod = SelectedPaymentMethod.NOT_SELECTED;
        if (response.length === 1) {
          if (response[0].paymentMethod === PaymentMethods.FACE_TO_FACE) {
            selectedPaymentMethod = SelectedPaymentMethod.F2F;
          } else if (response[0].paymentMethod === PaymentMethods.PIX) {
            selectedPaymentMethod = SelectedPaymentMethod.PIX;
            this.fetchPixPaymentDetails();
          } else if (response[0].paymentMethod === PaymentMethods.CREDIT_CARD) {
            selectedPaymentMethod = SelectedPaymentMethod.CREDIT_CARD;
          }
        }

        this.setState({
          requestStatus: new RequestStatus(Status.SUCCESS),
          storedCreditCards: [],
          showCreditCardForm: true,
          paymentMethods: response,
          selectedPaymentMethod
        });
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message)
        });
      });
  };

  fetchPixPaymentDetails = () => {
    const {
      pixRequestStatus,
      reservation: { code }
    } = this.state;

    const { setNotificationInterval } = this.props;

    if (pixRequestStatus.status !== Status.SUCCESS) {
      this.setState({
        pixRequestStatus: new RequestStatus(Status.LOADING)
      });

      getPixPaymentDetails(code)
        .then(response => {
          this.setState({
            pixRequestStatus: new RequestStatus(Status.SUCCESS),
            pixData: response,
            isCheckingPixPaymentFast: true
          });

          setNotificationInterval(PIX_NOTIFICATION_INTERVAL);
          this.setTimeoutForDefaultNotificationIntervalRestore();
        })
        .catch(error => {
          this.setState({
            pixRequestStatus: new RequestStatus(Status.ERROR, error.message)
          });
        });
    }
  };

  setTimeoutForDefaultNotificationIntervalRestore = () => {
    const { resetNotificationIntervalToDefault } = this.props;

    setTimeout(() => {
      resetNotificationIntervalToDefault();
      this.setState({
        isCheckingPixPaymentFast: false
      });
    }, 60000);
  };

  handleReservationPayment = (paymentType, reservationCode, paymentData) => {
    const {
      location: { pathname },
      clearCardInfo
    } = this.props;

    this.setState({
      requestStatus: new RequestStatus(Status.LOADING, "Efetuando reserva"),
      currentAction: CurrentAction.PAY_RESERVATION
    });

    let paymentPromise;
    switch (paymentType) {
      case QueryPaymentType.NEW_CREDIT_CARD:
        paymentPromise = payReservationNewCreditCard(
          reservationCode,
          paymentData
        );
        break;
      case QueryPaymentType.SAVED_CREDIT_CARD:
        paymentPromise = payReservationSavedCreditCard(
          reservationCode,
          paymentData
        );
        break;
      case QueryPaymentType.F2F:
        paymentPromise = payReservationF2fPayment(reservationCode);
        break;
      default:
        break;
    }

    paymentPromise
      .then(reservation => {
        if (reservation.paymentMethod === PaymentMethods.FACE_TO_FACE) {
          goToLocationForceRefresh(pathname, true);
        } else {
          this.setState({
            requestStatus: new RequestStatus(Status.SUCCESS),
            currentAction: CurrentAction.PAY_RESERVATION,
            reservation
          });
          clearCardInfo();
          this.clearForm();
          this.takeFormSnapshot();
        }
      })
      .catch(error => {
        this.setState({
          requestStatus: new RequestStatus(Status.ERROR, error.message),
          currentAction: CurrentAction.PAY_RESERVATION
        });
      });
  };

  takeFormSnapshot = () => {
    const {
      form: { getFieldsValue }
    } = this.props;
    this.formSnapshot = getFieldsValue();
  };

  restoreFormSnapshot = () => {
    const {
      form: { setFieldsValue, validateFields }
    } = this.props;
    const snapshot = this.formSnapshot;
    if (snapshot) {
      setFieldsValue(snapshot, validateFields);
    }
  };

  clearForm = () => {
    const { form } = this.props;

    form.resetFields();
  };

  handlePaymentFormSubmit = e => {
    const {
      reservation: { code: reservationCode }
    } = this.state;
    const { cardNumber, cvc, expirationDate, holderName, cpf } = this.props;
    const expirationMonth = expirationDate.substring(0, 2);
    const expirationYear = "20".concat(expirationDate.substring(2, 4));

    e.preventDefault();

    const { encryptedCard } = encryptCard(
      holderName,
      cardNumber,
      expirationMonth,
      expirationYear
    );

    const creditCard = {
      cvv: cvc,
      holderName,
      holderDocumentNumber: cpf,
      encrypted: encryptedCard
    };

    this.takeFormSnapshot();

    this.handleReservationPayment(
      QueryPaymentType.NEW_CREDIT_CARD,
      reservationCode,
      creditCard
    );
  };

  handleCreditCardInputFocus = ({ target: { name } }) => {
    this.setState({
      focusedInput: name
    });
  };

  onCancelReservationClick = () => {
    const {
      reservation: { code: reservationCode }
    } = this.state;
    const {
      location: { pathname }
    } = this.props;

    Modal.confirm({
      title: "Cancelar reserva",
      content: "Deseja realmente cancelar essa reserva?",
      centered: true,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => {
        this.setState({
          requestStatus: new RequestStatus(
            Status.LOADING,
            "Cancelando reserva"
          ),
          currentAction: CurrentAction.CANCEL_RESERVATION
        });

        cancelReservation(reservationCode)
          .then(reservation => {
            message.success("Sua reserva foi cancelada!");
            goToLocationForceRefresh(pathname, true);
          })
          .catch(error => {
            this.setState({
              requestStatus: new RequestStatus(Status.ERROR, error.message),
              currentAction: CurrentAction.CANCEL_RESERVATION
            });
          });
      }
    });
  };

  toggleSelectCreditCard = creditCardId => {
    const { selectedCreditCardId } = this.state;
    const isCardAlreadySelected = creditCardId === selectedCreditCardId;

    this.setState({
      selectedCreditCardId: isCardAlreadySelected ? undefined : creditCardId
    });
  };

  // toggleNewCreditCardForm = () => {
  //   const { showCreditCardForm } = this.state;
  //   const newValue = !showCreditCardForm;
  //   if (newValue) {
  //     this.setState(
  //       {
  //         showCreditCardForm: newValue
  //       },
  //       () => {
  //         this.restoreFormSnapshot();
  //       }
  //     );
  //   } else {
  //     this.takeFormSnapshot();
  //     this.setState({
  //       showCreditCardForm: newValue
  //     });
  //   }
  // };

  getSelectedPaymentMethodDiscount = () => {
    const { paymentMethods = [] } = this.state;

    return paymentMethods.find(
      pm => pm.paymentMethod === this.state.selectedPaymentMethod
    );
  };

  renderStoredCards = () => {
    const { storedCreditCards, selectedCreditCardId } = this.state;

    return (
      <div>
        {storedCreditCards.length > 0 && (
          <div style={{ marginBottom: "10px" }}>
            <h2>Escolha um cartão para pagar</h2>
            {storedCreditCards.map(creditCard => {
              const isCreditCardSelected =
                selectedCreditCardId === creditCard.id;
              return (
                <SimpleCard
                  key={creditCard.id}
                  clickable
                  selected={isCreditCardSelected}
                >
                  <div
                    className="credit-card-item"
                    onClick={() => {
                      this.toggleSelectCreditCard(creditCard.id);
                    }}
                  >
                    <h3 style={{ marginBottom: "0" }}>
                      Termina em <strong>{creditCard.last4}</strong>
                    </h3>
                    <img
                      alt={`Logotipo ${
                        CreditCardBrands[creditCard.brand].brand
                      }`}
                      src={CreditCardBrands[creditCard.brand].img}
                      className={"credit-card-logo"}
                    />
                  </div>
                </SimpleCard>
              );
            })}
          </div>
        )}
        {storedCreditCards.length > 0 && (
          <h3>
            Ou{" "}
            <span className="text-link" onClick={this.toggleNewCreditCardForm}>
              adicione um novo cartão{" "}
            </span>
          </h3>
        )}
      </div>
    );
  };

  renderAddNewCreditCard = () => {
    const { focusedInput, cvcVisible } = this.state;
    const {
      cardNumber,
      cvc,
      expirationDate,
      holderName,
      isDesktop
    } = this.props;
    const { acceptedCards } = this;

    return (
      <div>
        <Row gutter={0}>
          <Col xs={24} md={12}>
            <div className={"credit-card-container"}>
              <Card
                number={cardNumber}
                cvc={cvcVisible ? cvc : cvc.replace(/./g, "*")}
                expiry={expirationDate}
                name={holderName}
                focused={focusedInput}
                locale={{ valid: "Valido até" }}
                placeholders={{ name: "Nome no cartão" }}
                acceptedCards={acceptedCards}
              />
            </div>
          </Col>
          {isDesktop
            ? this.renderDesktopFormFields()
            : this.renderMobileFormFields()}
        </Row>
      </div>
    );
  };

  onMobileCurrentFieldChange = () => {
    const { currentFormFieldIndex } = this.state;

    switch (currentFormFieldIndex) {
      case 0:
        this.cardNumberRef.current.maskedInputRef.input.focus();
        break;
      case 1:
        this.cvcRef.current.input.focus();
        break;
      case 2:
        this.expirationDateRef.current.maskedInputRef.input.focus();
        break;
      case 3:
        this.holderNameRef.current.input.focus();
        break;
      case 4:
        this.holderCpfRef.current.maskedInputRef.input.focus();
        break;
      default:
        break;
    }
  };

  flipCard = () => {
    const { focusedInput } = this.state;

    if (focusedInput === CreditCardFocusedInput.CVC) {
      this.setState({
        focusedInput: CreditCardFocusedInput.NAME
      });
    } else {
      this.setState({
        focusedInput: CreditCardFocusedInput.CVC
      });
    }
  };

  renderMobileFormFields = () => {
    const { currentFormFieldIndex } = this.state;
    const {
      form: { getFieldsValue, getFieldsError }
    } = this.props;

    let isRightArrowButtonDisabled = false;
    if (
      (Object.entries(getFieldsValue())[currentFormFieldIndex] && //there is not a field rendered
        (!Object.entries(getFieldsValue())[currentFormFieldIndex][1] || //there is not a value for the field currently being rendered
          getFieldsError()[
            Object.entries(getFieldsValue())[currentFormFieldIndex][0]
          ])) || //there is an error for the field currently being rendered
      currentFormFieldIndex === FORM_FIELDS_AMOUNT //this is the reservation summary page
    ) {
      isRightArrowButtonDisabled = true;
    }

    const leftArrowIconColor =
      currentFormFieldIndex > 0 ? PrimaryColor.primary : Color.INPUT_ICON_COLOR;
    let rightArrowIconColor = PrimaryColor.primary;
    if (isRightArrowButtonDisabled) {
      rightArrowIconColor = Color.INPUT_ICON_COLOR;
    }

    return (
      <Col xs={24}>
        <div className={"mobile-credit-card-fields-container"}>
          <Icon
            type="arrow-left"
            className="mobile-arrow"
            style={{
              color: leftArrowIconColor
            }}
            onClick={() => {
              if (currentFormFieldIndex > 0) {
                this.setState(
                  {
                    currentFormFieldIndex: currentFormFieldIndex - 1
                  },
                  () => {
                    this.onMobileCurrentFieldChange();
                  }
                );
              }
            }}
          />
          <div
            className={classNames(
              "mobile-field-display-contents",
              currentFormFieldIndex === 0 ? null : "hidden-field"
            )}
          >
            {this.renderCardNumberFormField()}
          </div>
          <div
            className={classNames(
              "mobile-field-display-contents",
              currentFormFieldIndex === 1 ? null : "hidden-field"
            )}
          >
            {this.renderCvcFormField()}
          </div>
          <div
            className={classNames(
              "mobile-field-display-contents",
              currentFormFieldIndex === 2 ? null : "hidden-field"
            )}
          >
            {this.renderExpirationDateFormField()}
          </div>
          <div
            className={classNames(
              "mobile-field-display-contents",
              currentFormFieldIndex === 3 ? null : "hidden-field"
            )}
          >
            {this.renderHolderNameFormField()}
          </div>
          <div
            className={classNames(
              "mobile-field-display-contents",
              currentFormFieldIndex === 4 ? null : "hidden-field"
            )}
          >
            {this.renderHolderCpfFormField()}
          </div>
          <div
            className={classNames(
              "mobile-field-display-contents",
              currentFormFieldIndex === 5 ? null : "hidden-field"
            )}
          >
            {this.renderReservationSummary()}
          </div>
          <Icon
            type="arrow-right"
            className="mobile-arrow"
            style={{
              color: rightArrowIconColor
            }}
            onClick={() => {
              if (!isRightArrowButtonDisabled) {
                this.setState(
                  {
                    currentFormFieldIndex: currentFormFieldIndex + 1
                  },
                  () => {
                    this.onMobileCurrentFieldChange();
                  }
                );
              }
            }}
          />
        </div>
      </Col>
    );
  };

  renderDesktopFormFields = () => {
    return (
      <Col xs={24} md={12}>
        <h2>Digite os dados do seu cartão de crédito</h2>
        <Row gutter={10}>
          <Col xs={24} sm={16}>
            {this.renderCardNumberFormField()}
          </Col>
          <Col xs={24} sm={8}>
            {this.renderCvcFormField()}
          </Col>
        </Row>
        {this.renderExpirationDateFormField()}
        <Row gutter={10}>
          <Col xs={24} sm={12}>
            {this.renderHolderNameFormField()}
          </Col>
          <Col xs={24} sm={12}>
            {this.renderHolderCpfFormField()}
          </Col>
        </Row>
      </Col>
    );
  };

  renderReservationSummary() {
    const {
      tripDetails: { departureTime },
      reservation: {
        boardingPoint: {
          displayName,
          place: { fullName }
        },
        passengers
      },
      focusedInput
    } = this.state;
    const { cpf } = this.props;

    return (
      <div className={"reservation-summary"}>
        <div className={"cpf-flip-card"}>
          <h3>
            CPF:{" "}
            {cpf ? StringMask.apply(cpf, "000.000.000-00") : "Não informado"}
          </h3>
          <span onClick={this.flipCard}>
            <HighlightText>
              Mostrar{" "}
              {focusedInput === CreditCardFocusedInput.CVC ? "frente" : "verso"}
            </HighlightText>
          </span>
        </div>
        <h2>
          <b>Resumo da reserva</b>
        </h2>
        <div>
          <h2>
            <b>Data e Hora</b>
          </h2>
          <h3>{backendDateTimeStringToMoment(departureTime).calendar()}</h3>
        </div>
        <div>
          <h2>
            <b>Local do embarque</b>
          </h2>
          <h3>
            <b>{displayName}</b>
          </h3>
          <h4>{fullName}</h4>
        </div>
        <div>
          <h2>
            <b>{passengers.length > 1 ? "Passageiros" : "Passageiro"}</b>
          </h2>
          {passengers.map(({ id, name, cpf, phone }) => {
            return (
              <div key={id} className={"passenger-item"}>
                <h3>
                  <b>{name}</b>
                </h3>
                <h4>CPF: {StringMask.apply(cpf, "000.000.000-00")}</h4>
                <h4>Celular: {StringMask.apply(phone, "(00) 0 0000-0000")}</h4>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderHolderCpfFormField(disableFormField = false) {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { cpf, setCpf } = this.props;
    return (
      <Form.Item>
        {getFieldDecorator(PaymentFormFields.HOLDER_CPF, {
          initialValue: cpf,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value || value.length === 0) {
                  callback("Por favor, digite o CPF do titular do cartão.");
                } else if (!validateCpf(value)) {
                  callback("Digite um CPF válido.");
                }

                setCpf(value);

                callback();
              }
            }
          ]
        })(
          <MaskedInput
            ref={this.holderCpfRef}
            mask="111.111.111-11"
            placeholder="CPF do titular do cartão"
            size="large"
            prefix={<Icon type="idcard" style={{ color: INPUT_ICON_COLOR }} />}
            inputMode="numeric"
            disabled={disableFormField}
          />
        )}
      </Form.Item>
    );
  }

  renderHolderNameFormField(disableFormField = false) {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { holderName, setHolderName } = this.props;
    return (
      <Form.Item>
        {getFieldDecorator(PaymentFormFields.HOLDER_NAME, {
          initialValue: holderName,
          rules: [
            {
              required: true,
              message: "Por favor, digite o nome do titular do cartão."
            },
            {
              validator: (rule, value, callback) => {
                setHolderName(value);

                callback();
              }
            }
          ]
        })(
          <Input
            ref={this.holderNameRef}
            type={"text"}
            size="large"
            prefix={<Icon type="user" style={{ color: INPUT_ICON_COLOR }} />}
            placeholder="Nome do titular do cartão"
            name={CreditCardFocusedInput.NAME}
            onFocus={this.handleCreditCardInputFocus}
            disabled={disableFormField}
          />
        )}
      </Form.Item>
    );
  }

  renderExpirationDateFormField(disableFormField = false) {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { expirationDate, setExpirationDate } = this.props;
    return (
      <Form.Item>
        {getFieldDecorator(PaymentFormFields.EXPIRATION_DATE, {
          // initialValue: { month: "", year: "" },
          initialValue: expirationDate,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback("Por favor, digite o mês e o ano de expiração.");
                } else {
                  const month = value.substring(0, 2);
                  const year = value.substring(2, 4);

                  if (month.length < 2 || year.length < 2) {
                    callback(
                      "Por favor, digite o mês e o ano de expiração usando dois dígitos em cada."
                    );
                  } else if (!validateExpiryDate(month, year)) {
                    callback("A data de expiração digitada não é válida.");
                  }
                }

                setExpirationDate(value);

                callback();
              }
            }
          ]
        })(
          <MaskedInput
            ref={this.expirationDateRef}
            mask="11/11"
            placeholder="Data de expiração"
            size="large"
            prefix={
              <Icon type="calendar" style={{ color: INPUT_ICON_COLOR }} />
            }
            inputMode="numeric"
            name={CreditCardFocusedInput.EXPIRY_DATE}
            onFocus={this.handleCreditCardInputFocus}
            disabled={disableFormField}
          />
        )}
      </Form.Item>
    );
  }

  renderCvcFormField(disableFormField = false) {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props;
    const { cvc, setCvc } = this.props;
    const { cvcVisible } = this.state;
    return (
      <Form.Item>
        {getFieldDecorator(PaymentFormFields.CVC, {
          initialValue: cvc,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value || value.length === 0) {
                  callback(
                    "Por favor, digite o CVC que se encontra no verso do cartão."
                  );
                } else if (value.length < 3) {
                  callback("Um CVC válido tem 3 ou 4 dígitos.");
                } else {
                  const cardNumber = getFieldValue(
                    PaymentFormFields.CARD_NUMBER
                  );

                  if (
                    cardNumber &&
                    !CreditCardUtils.validateCvc(cardNumber, value)
                  ) {
                    callback(
                      "CVC inválido. Verifique se o CVC e o número do cartão estão corretos."
                    );
                  }
                }

                setCvc(value);

                callback();
              }
            }
          ]
        })(
          <Input
            ref={this.cvcRef}
            size={"large"}
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            suffix={
              <Icon
                type={cvcVisible ? "eye" : "eye-invisible"}
                style={{ color: "rgba(0,0,0,.25)" }}
                onClick={() => {
                  this.setState(() => ({
                    cvcVisible: !cvcVisible
                  }));
                }}
              />
            }
            placeholder="CVC"
            inputMode="numeric"
            type={cvcVisible ? "numeric" : "password"}
            name={CreditCardFocusedInput.CVC}
            onFocus={this.handleCreditCardInputFocus}
            onChange={event => {
              event.target.value = event.target.value
                .replaceAll(/\D/g, "")
                .substring(0, 4);
            }}
            disabled={disableFormField}
          />
        )}
      </Form.Item>
    );
  }

  renderCardNumberFormField(disableFormField = false) {
    const {
      form: { getFieldDecorator, validateFields }
    } = this.props;
    const { cardNumber, setCardNumber } = this.props;
    return (
      <Form.Item>
        {getFieldDecorator(PaymentFormFields.CARD_NUMBER, {
          initialValue: cardNumber,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value || value.length === 0) {
                  callback(
                    "Por favor, digite o número do seu cartão de crédito."
                  );
                } else if (!CreditCardUtils.validateNumber(value)) {
                  callback(
                    "O número digitado é inválido ou a bandeira desse cartão não é suportada. Por favor, verifique o número ou use outro cartão de crédito."
                  );
                }

                setCardNumber(value);

                validateFields([PaymentFormFields.CVC]);
                callback();
              }
            }
          ]
        })(
          <MaskedInput
            ref={this.cardNumberRef}
            mask="1111 1111 1111 1111 111"
            placeholder="Número do cartão"
            size="large"
            prefix={
              <Icon type="credit-card" style={{ color: INPUT_ICON_COLOR }} />
            }
            inputMode="numeric"
            name={CreditCardFocusedInput.NUMBER}
            onFocus={this.handleCreditCardInputFocus}
            disabled={disableFormField}
            autoFocus
          />
        )}
      </Form.Item>
    );
  }

  renderPixPayment = () => {
    const { pixRequestStatus, pixData, isCheckingPixPaymentFast } = this.state;

    return (
      <div>
        {this.renderChooseAnotherPaymentMethodButton()}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px"
          }}
        >
          {(() => {
            if (pixRequestStatus.status === Status.LOADING) {
              return <Loading />;
            } else if (pixRequestStatus.status === Status.SUCCESS) {
              const { image, text } = pixData;
              return (
                <>
                  <h2>PIX gerado com sucesso</h2>
                  <SimpleCard>
                    <img
                      src={image}
                      alt={"QR Code do PIX"}
                      style={{
                        maxHeight: "320px",
                        height: "auto",
                        width: "auto",
                        maxWidth: "320px"
                      }}
                    />
                  </SimpleCard>
                  <SimpleCard style={{ background: "#f5f5f5" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginBottom: "0", wordBreak: "break-all" }}>
                        {text}
                      </p>

                      <div style={{ margin: "10px" }}>
                        <Tooltip placement="topRight" title="Copiar chave PIX">
                          <Icon
                            type="copy"
                            theme={"filled"}
                            style={{
                              color: PRIMARY_COLOR,
                              fontSize: "20px",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              try {
                                navigator.clipboard.writeText(text);
                                message.success("A chave PIX foi copiada");
                              } catch (e) {
                                message.error(
                                  "Erro ao copiar a chave PIX. Tente copiar manualmente ou escaneie o QR code."
                                );
                              }
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </SimpleCard>
                  {isCheckingPixPaymentFast ? (
                    <div style={{ marginTop: "20px" }}>
                      <Loading loadingMessage="Aguardando pagamento via PIX" />
                    </div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          const { setNotificationInterval } = this.props;
                          setNotificationInterval(PIX_NOTIFICATION_INTERVAL);
                          this.setState({
                            isCheckingPixPaymentFast: true
                          });
                          this.setTimeoutForDefaultNotificationIntervalRestore();
                        }}
                      >
                        Tentar novamente
                      </Button>
                    </div>
                  )}
                </>
              );
            } else if (pixRequestStatus.status === Status.ERROR) {
              return (
                <Result
                  status="warning"
                  title={
                    "Erro ao carregar as informações para o pagamento com PIX"
                  }
                  subTitle={pixRequestStatus.message}
                  extra={[
                    <Button
                      type="primary"
                      key="tryAgain"
                      onClick={this.fetchPixPaymentDetails}
                    >
                      Entendi
                    </Button>
                  ]}
                />
              );
            }
          })()}
        </div>
      </div>
    );
  };

  renderF2fPayment = () => {
    const { history } = this.props;
    const {
      reservation: { numberOfSeats },
      maxF2fSeatsForPayment,
      paymentMethods
    } = this.state;

    if (
      paymentMethods.find(
        pm => pm.paymentMethod === PaymentMethods.FACE_TO_FACE
      )
    ) {
      if (numberOfSeats > maxF2fSeatsForPayment) {
        return (
          <>
            <p className={"text-content"}>
              Você reservou um número de assentos maior do que o permitido para
              pagamento presencial.
            </p>
            <p className={"text-content"}>
              Por favor, escolha outro meio de pagamento para essa reserva.
            </p>
          </>
        );
      } else {
        return (
          <>
            <h2>Você já faz parte do programa de pagamento presencial.</h2>
            <p className={"text-content"}>
              Clique no botão Finalizar logo abaixo para confirmar o pagamento
              presencial para a sua reserva.
            </p>
          </>
        );
      }
    } else {
      return (
        <>
          <p className={"text-content"}>
            Você não faz parte do programa de pagamento presencial.
          </p>
          <p className={"text-content"}>
            Você pode pedir para participar do programa no menu Pagamento no seu
            Perfil de Usuário. Para acessá-lo, basta{" "}
            <span
              className={"text-link"}
              onClick={() => {
                history.push(InternalUrl.PAYMENT);
              }}
            >
              Clicar aqui
            </span>
            .
          </p>
        </>
      );
    }
  };

  renderPaymentTypeCard = (
    text,
    iconSrc,
    originalPrice,
    discount,
    discountType,
    finalValue,
    onClick
  ) => {
    let discountText;
    let discountAdditionalInfo;
    switch (discountType) {
      case DiscountType.FIXED_PER_SEAT:
        discountText = `R$${numberToPrice(discount)}`;
        discountAdditionalInfo = `(por assento)`;
        break;
      case DiscountType.FIXED_PER_RESERVATION:
        discountText = `R$${numberToPrice(discount)}`;
        discountAdditionalInfo = ``;
        break;
      case DiscountType.PERCENTAGE:
        discountText = `${discount}%`;
        discountAdditionalInfo = ``;
        break;
      default:
        break;
    }

    return (
      <div onClick={onClick}>
        <SimpleCard clickable>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 5px 5px 0",
              minHeight: "115px"
            }}
          >
            <img
              src={iconSrc}
              alt={""}
              style={{ height: "50px", width: "50px", marginRight: "10px" }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <h3 style={{ marginBottom: 0, color: PRIMARY_COLOR }}>
                <strong>{text}</strong>
              </h3>
              {discountType && (
                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "16px"
                    }}
                  >
                    <h3 style={{ marginBottom: 0, color: "green" }}>
                      Desconto {discountText}
                    </h3>
                    <h3
                      style={{
                        marginBottom: 0,
                        textDecoration: "line-through"
                      }}
                    >
                      R$ {numberToPrice(originalPrice)}
                    </h3>
                  </div>
                  {discountAdditionalInfo && (
                    <span style={{ fontSize: "12px" }}>
                      <i>{discountAdditionalInfo}</i>
                    </span>
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px"
                }}
              >
                <h3 style={{ marginBottom: 0 }}>
                  <strong>Total a pagar</strong>
                </h3>
                <h3 style={{ marginBottom: 0, color: "green" }}>
                  <strong>R${numberToPrice(finalValue)}</strong>
                </h3>
              </div>
            </div>
          </div>
        </SimpleCard>
      </div>
    );
  };

  resetPaymentSelection = () => {
    // this.clearForm();
    this.setState({
      selectedPaymentMethod: SelectedPaymentMethod.NOT_SELECTED,
      selectedCreditCardId: undefined
    });
  };

  renderChooseAnotherPaymentMethodButton = () => {
    const { paymentMethods } = this.state;

    if (paymentMethods.length === 1) {
      return null;
    }

    return (
      <h2
        className={"text-link"}
        style={{ display: "block" }}
        onClick={() => {
          this.resetPaymentSelection();
        }}
      >
        <Icon type="arrow-left" style={{ marginRight: "10px" }} />
        Voltar
      </h2>
    );
  };

  render() {
    const {
      requestStatus,
      currentAction,
      reservation: { code: reservationCode, status, price },
      showCreditCardForm,
      selectedCreditCardId
    } = this.state;
    const { history } = this.props;

    const { handleReservationPayment } = this;

    const isAnyCreditCardSelected = !!selectedCreditCardId;
    let totalPrice = numberToPrice(price);

    const currentPaymentMethodDiscount = this.getSelectedPaymentMethodDiscount();
    if (currentPaymentMethodDiscount) {
      totalPrice = numberToPrice(currentPaymentMethodDiscount.price);
    }

    if (requestStatus.status === Status.NOT_STARTED) {
      return null;
    } else if (requestStatus.status === Status.LOADING) {
      return <Loading loadingMessage={requestStatus.message} />;
    } else if (requestStatus.status === Status.SUCCESS) {
      if (currentAction === CurrentAction.FETCH_INTIAL_DATA) {
        if (status === ReservationStatus.PENDING_PAYMENT_CONFIRMATION) {
          return (
            <div>
              <Result
                title="Seu pagamento está em análise"
                subTitle="Vamos te avisar quando seu pagamento for aprovado"
                extra={
                  <Button
                    type="primary"
                    key="myReservations"
                    onClick={() => {
                      history.push(InternalUrl.MY_TRIPS_UPCOMING);
                    }}
                  >
                    Ver minhas reservas
                  </Button>
                }
              />
            </div>
          );
        } else {
          const {
            selectedPaymentMethod,
            reservation: { numberOfSeats },
            maxF2fSeatsForPayment,
            paymentMethods
          } = this.state;

          const faceToFaceEnabledForReservation = paymentMethods.find(
            p => p.paymentMethod === PaymentMethods.FACE_TO_FACE
          );

          return (
            <>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  {status === ReservationStatus.PAYMENT_FAILED && (
                    <Alert
                      message="O pagamento falhou"
                      description="Não foi possível confirmar o seu pagamento. Por favor, tente pagar novamente ou use outro cartão de crédito ou outra forma de pagamento."
                      type="error"
                      closable
                      style={{ marginBottom: "10px" }}
                    />
                  )}
                  <Form
                    id="payment-form"
                    onSubmit={this.handlePaymentFormSubmit}
                  >
                    {(() => {
                      if (
                        selectedPaymentMethod ===
                        SelectedPaymentMethod.NOT_SELECTED
                      ) {
                        const ccPayment = paymentMethods.find(
                          pm => pm.paymentMethod === PaymentMethods.CREDIT_CARD
                        );
                        const pixPayment = paymentMethods.find(
                          pm => pm.paymentMethod === PaymentMethods.PIX
                        );
                        const f2fPayment = paymentMethods.find(
                          pm => pm.paymentMethod === PaymentMethods.FACE_TO_FACE
                        );

                        return (
                          <div style={{ marginBottom: "10px" }}>
                            <h2>Escolha o meio de pagamento</h2>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around"
                              }}
                            >
                              {!!ccPayment &&
                                this.renderPaymentTypeCard(
                                  "Cartão de Crédito",
                                  require("assets/icons/payment/cc.svg"),
                                  ccPayment?.originalPrice,
                                  ccPayment?.discountDto?.amount,
                                  ccPayment?.discountDto?.type,
                                  ccPayment?.price,
                                  () => {
                                    this.setState({
                                      selectedPaymentMethod:
                                        SelectedPaymentMethod.CREDIT_CARD
                                    });
                                  }
                                )}
                              {!!pixPayment &&
                                this.renderPaymentTypeCard(
                                  "PIX",
                                  require("assets/icons/payment/pix.svg"),
                                  pixPayment?.originalPrice,
                                  pixPayment?.discountDto?.amount,
                                  pixPayment?.discountDto?.type,
                                  pixPayment?.price,
                                  () => {
                                    this.setState({
                                      selectedPaymentMethod:
                                        SelectedPaymentMethod.PIX
                                    });
                                    this.fetchPixPaymentDetails();
                                  }
                                )}
                              {!!f2fPayment &&
                                this.renderPaymentTypeCard(
                                  "Presencial",
                                  require("assets/icons/payment/f2f.svg"),
                                  f2fPayment?.originalPrice,
                                  f2fPayment?.discountDto?.amount,
                                  f2fPayment?.discountDto?.type,
                                  f2fPayment?.price,
                                  () => {
                                    this.setState({
                                      selectedPaymentMethod:
                                        SelectedPaymentMethod.F2F
                                    });
                                  }
                                )}
                            </div>
                          </div>
                        );
                      } else if (
                        selectedPaymentMethod ===
                        SelectedPaymentMethod.CREDIT_CARD
                      ) {
                        return (
                          <>
                            {this.renderChooseAnotherPaymentMethodButton()}
                            {(() => {
                              return showCreditCardForm
                                ? this.renderAddNewCreditCard()
                                : this.renderStoredCards();
                            })()}
                          </>
                        );
                      } else if (
                        selectedPaymentMethod === SelectedPaymentMethod.PIX
                      ) {
                        return this.renderPixPayment();
                      } else {
                        return (
                          <div>
                            {this.renderChooseAnotherPaymentMethodButton()}
                            {this.renderF2fPayment()}
                          </div>
                        );
                      }
                    })()}
                  </Form>
                </div>
              </div>
              <div
                style={{
                  borderTop: `1px solid ${Color.ANTD_LIGHT_GREY_BORDER}`
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFFFFF" //TODO find a way to use the variable defined on body tag on the main App.css
                  }}
                >
                  {selectedPaymentMethod !==
                    SelectedPaymentMethod.NOT_SELECTED && (
                    <Title
                      level={4}
                      style={{ margin: "10px 0 0 0", textAlign: "right" }}
                    >
                      Total:{" "}
                      <span style={{ color: PRIMARY_COLOR }}>
                        R$ {totalPrice}
                      </span>
                    </Title>
                  )}
                </div>
                <div
                  className="steps-action"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "8px"
                  }}
                >
                  <Button
                    type="danger"
                    ghost
                    onClick={this.onCancelReservationClick}
                  >
                    Cancelar reserva
                  </Button>
                  {status !==
                    ReservationStatus.PENDING_PAYMENT_CONFIRMATION && (
                    <>
                      {selectedPaymentMethod ===
                        SelectedPaymentMethod.CREDIT_CARD &&
                        showCreditCardForm && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            form="payment-form"
                          >
                            Finalizar
                          </Button>
                        )}
                      {!(
                        selectedPaymentMethod ===
                          SelectedPaymentMethod.CREDIT_CARD &&
                        showCreditCardForm
                      ) && (
                        <Button
                          type="primary"
                          disabled={
                            selectedPaymentMethod ===
                              SelectedPaymentMethod.NOT_SELECTED ||
                            (selectedPaymentMethod ===
                              SelectedPaymentMethod.CREDIT_CARD &&
                              !isAnyCreditCardSelected) ||
                            (selectedPaymentMethod ===
                              SelectedPaymentMethod.F2F &&
                              (!faceToFaceEnabledForReservation ||
                                numberOfSeats > maxF2fSeatsForPayment)) ||
                            selectedPaymentMethod === SelectedPaymentMethod.PIX
                          }
                          onClick={() => {
                            if (
                              selectedPaymentMethod ===
                              SelectedPaymentMethod.CREDIT_CARD
                            ) {
                              Modal.confirm({
                                title:
                                  "Confirmar pagamento com o cartão selecionado?",
                                centered: true,
                                okText: "Sim",
                                cancelText: "Não",
                                onOk: () => {
                                  handleReservationPayment(
                                    QueryPaymentType.SAVED_CREDIT_CARD,
                                    reservationCode,
                                    selectedCreditCardId
                                  );
                                }
                              });
                            } else if (
                              selectedPaymentMethod ===
                              SelectedPaymentMethod.F2F
                            ) {
                              handleReservationPayment(
                                QueryPaymentType.F2F,
                                reservationCode,
                                selectedCreditCardId
                              );
                            }
                          }}
                        >
                          Finalizar
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          );
        }
      } else if (currentAction === CurrentAction.PAY_RESERVATION) {
        if (status === ReservationStatus.PENDING_PAYMENT_CONFIRMATION) {
          return (
            <div>
              <Result
                title="Seu pagamento está em análise"
                subTitle="Vamos te avisar quando seu pagamento for aprovado"
                extra={
                  <Button
                    type="primary"
                    key="myReservations"
                    onClick={() => {
                      history.push(InternalUrl.MY_TRIPS_UPCOMING);
                    }}
                  >
                    Ver minhas reservas
                  </Button>
                }
              />
            </div>
          );
        }
      }
    } else if (requestStatus.status === Status.ERROR) {
      if (currentAction === CurrentAction.FETCH_INTIAL_DATA) {
        return (
          <Result
            status="warning"
            title={
              "Não foi possível carregar as informações necessárias para o pagamento"
            }
            subTitle={requestStatus.message}
            extra={[
              <Button
                type="primary"
                key="tryAgain"
                onClick={this.fetchPaymentMethodsforReservation}
              >
                Entendi
              </Button>
            ]}
          />
        );
      } else if (currentAction === CurrentAction.PAY_RESERVATION) {
        return (
          <Result
            status="warning"
            title={"Não foi possível efetuar o pagamento"}
            subTitle={requestStatus.message}
            extra={[
              <Button
                type="primary"
                key="tryAgain"
                onClick={() => {
                  this.setState(
                    {
                      requestStatus: new RequestStatus(Status.SUCCESS),
                      currentAction: CurrentAction.FETCH_INTIAL_DATA
                    },
                    () => {
                      this.restoreFormSnapshot();
                    }
                  );
                }}
              >
                Entendi
              </Button>
            ]}
          />
        );
      } else if (currentAction === CurrentAction.CANCEL_RESERVATION) {
        return (
          <Result
            status="warning"
            title={"Não foi possível cancelar a reserva"}
            subTitle={requestStatus.message}
            extra={[
              <Button
                type="primary"
                key="tryAgain"
                onClick={() => {
                  this.setState({
                    requestStatus: new RequestStatus(Status.SUCCESS),
                    currentAction: CurrentAction.FETCH_INTIAL_DATA
                  });
                }}
              >
                Entendi
              </Button>
            ]}
          />
        );
      }
    }

    return null;
  }
}

Payment.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  cardNumber: PropTypes.string.isRequired,
  cvc: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  holderName: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  resetNotificationIntervalToDefault: PropTypes.func.isRequired,
  setNotificationInterval: PropTypes.func.isRequired,
  clearCardInfo: PropTypes.func.isRequired,
  setCardNumber: PropTypes.func.isRequired,
  setCvc: PropTypes.func.isRequired,
  setExpirationDate: PropTypes.func.isRequired,
  setHolderName: PropTypes.func.isRequired,
  setCpf: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  configsReducer,
  reservationPaymentReducer,
  eventsReducer
}) => ({
  notificationInterval: configsReducer.notificationInterval,
  cardNumber: reservationPaymentReducer.creditCardForm.cardNumber,
  cvc: reservationPaymentReducer.creditCardForm.cvc,
  expirationDate: reservationPaymentReducer.creditCardForm.expirationDate,
  holderName: reservationPaymentReducer.creditCardForm.holderName,
  cpf: reservationPaymentReducer.creditCardForm.cpf,
  cep: reservationPaymentReducer.creditCardForm.cep,
  houseNumber: reservationPaymentReducer.creditCardForm.houseNumber,
  isDesktop: eventsReducer.isDesktop
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetNotificationIntervalToDefault: resetNotificationIntervalToDefault,
      setNotificationInterval: setNotificationInterval,
      clearCardInfo: clearCardInfo,
      setCardNumber: setCardNumber,
      setCvc: setCvc,
      setExpirationDate: setExpirationDate,
      setHolderName: setHolderName,
      setCpf: setCpf
    },
    dispatch
  );
};

export default lodash.flow(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: "payment" })
)(Payment);
